import { ChakraProvider } from '@chakra-ui/react'
import { Analytics } from '@vercel/analytics/react'
import qs from 'query-string'
import { useEffect } from 'react'
import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { Toaster } from 'sonner'
import { BrandKitStyles, buttonVariants, Spinner } from 'tailwind-ui'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import Offline from '@/common/offline'
import { AlertProvider } from '@/components/alerts'
import { OnlineStatusProvider } from '@/connect-context/online-status'
import SegmentProvider from '@/connect-context/tracking/segment'
import theme from '@/connect-theme'
import OauthContext from '@/pages/oauth/context'
import FirebaseProvider from '@/utils/firebase/provider'
import { isInStandaloneMode } from '@/utils/helpers/pwa'
import Drawer from '@/common/drawer'
import DrawerProvider from '@/common/drawer/drawer-context'
import { useColorMode } from '@/hooks/use-color-mode'
import ReloadPrompt from '@/sw/reload-prompt'

const queryStringifyOptions = {
  searchStringToObject: qs.parse,
  objectToSearchString: qs.stringify,
}

function RootLayout() {
  useColorMode()

  const { pathname } = useLocation()
  const push = useNavigate()
  useEffect(() => {
    if (!isInStandaloneMode()) return
    if (pathname !== '/') return
    const lastItem = localStorage.getItem('last-path')
    if (lastItem) {
      push(pathname)
    }

    localStorage.setItem('last-path', pathname)
  }, [pathname, push])

  //remove service worker for now
  return (
    <OauthContext>
      {'serviceWorker' in navigator && !pathname.includes('oauth') ? (
        <ReloadPrompt />
      ) : null}
      <Analytics />
      <SegmentProvider>
        <FirebaseProvider>
          <ChakraProvider theme={theme}>
            <OnlineStatusProvider>
              <DrawerProvider>
                <Drawer />
                <Toaster
                  theme="system"
                  closeButton
                  position="top-right"
                  visibleToasts={3}
                  icons={{ loading: <Spinner size="sm" /> }}
                  toastOptions={{
                    unstyled: true,
                    className: '',
                    classNames: {
                      success: 'fill-green-500',
                      cancelButton: 'bg-black ',
                      actionButton: buttonVariants({
                        variant: 'primary',
                        brandKit: true,
                        size: 'sm',
                        className: 'ml-auto rounded-lg',
                      }),
                      description: 'text-xs',
                      closeButton:
                        'self-start border border-black/10 dark:border-white/10 !text-black !bg-white/80 dark:!text-white dark:!bg-black/80 z-[9999px] backdrop-blur-lg ',
                      title: 'text-sm line-clamp-2',
                      toast:
                        'z-[9999px] duration-250 shadow-lg right-0 items-start insert-0 bg-black/90 dark:bg-white/90 backdrop-blur-lg dark:hover:bg-white hover:bg-black  dark:text-black/90 text-white/90  py-2 pl-4 pr-2 flex items-center gap-x-2 !min-w-80 rounded-xl',
                    },
                  }}
                />

                <BrandKitStyles
                  ignoreBody
                  brand={{
                    backgroundImage: '',
                    interfaceColor: '#ff3a9b',
                    roundInputs: true,
                    background: 'white',
                    heading_font_family: 'Montserrat',
                    body_font_family: 'Montserrat',
                  }}
                />
                <AlertProvider>
                  <QueryParamProvider
                    adapter={ReactRouter6Adapter}
                    options={queryStringifyOptions}
                  >
                    <ScrollRestoration
                      getKey={(location, matches) => {
                        const match = matches.find(
                          (m) => (m.handle as any)?.scrollMode
                        )
                        if ((match?.handle as any)?.scrollMode === 'pathname') {
                          return location.pathname
                        }

                        return location.key
                      }}
                    />

                    <Outlet />
                  </QueryParamProvider>

                  <Offline />
                </AlertProvider>
              </DrawerProvider>
            </OnlineStatusProvider>
          </ChakraProvider>
        </FirebaseProvider>
      </SegmentProvider>
    </OauthContext>
  )
}

export default RootLayout
