import {
  createSelector as cs,
  OutputSelector,
  Selector,
} from '@reduxjs/toolkit'
import { RootState } from '@/state/reducers'

export const createAppSelector = <RS extends RootState, R1, T>(
  selector: Selector<RS, R1>,
  combiner: (res: R1) => T
  //@ts-ignore
  //@ts-nocheck
): OutputSelector<RS, T, (res: R1) => T> => cs(selector, combiner)
