import { useEffect, useCallback, useState } from 'react'
import { whenAvailable } from '@/utils/tracking'

// Type declaration for the Zendesk Window object
declare global {
  interface Window {
    zE?: (command: string, action: string, ...args: any[]) => void
    zESettings?: Record<string, any>
  }
}

export const zE = typeof window !== 'undefined' ? window.zE : undefined

export function zEOpen(): void {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'open')
  } else {
    console.warn('Zendesk Widget not initialized')
  }
}

export function zEShow(): void {
  if (typeof window !== 'undefined' && window.zE) {
    window.zE('messenger', 'show')
  } else {
    console.warn('Zendesk Widget not initialized')
  }
}

export function zEHide(): void {
  whenAvailable('zE', () => {
    if (typeof window !== 'undefined' && window.zE) {
      window.zE('messenger', 'hide')
    }
  })
}

export function zEOpenWithTag(tags: string[]): void {
  whenAvailable('zE', () => {
    if (window.zE) {
      window.zE('messenger', 'open')
      window.zE('messenger:set', 'conversationTags', tags)
    }
  })
}
// Add mobile detection utility
const isMobile = (): boolean => {
  if (typeof window === 'undefined') return false
  return window.innerWidth <= 768 // You can adjust this breakpoint as needed
}
export function useHideZendesk(condition = true): void {
  useEffect(() => {
    whenAvailable('zE', () => {
      if (typeof window !== 'undefined' && window.zE && condition) {
        window.zE('messenger', 'hide')
      }
    })

    return () => {
      if (
        typeof window !== 'undefined' &&
        window.zE &&
        condition &&
        !isMobile()
      ) {
        window.zE('messenger', 'show')
      }
    }
  }, [condition])
}

export function useToggleZendesk(
  initialState = true
): readonly [boolean, (value?: boolean) => void] {
  const [isVisible, setIsVisible] = useState(initialState)

  const toggleZendesk = useCallback(
    (value = !isVisible) => {
      if (typeof window !== 'undefined' && window.zE) {
        if (value) {
          zEShow()
        } else {
          zEHide()
        }
        setIsVisible(value)
      }
    },
    [isVisible]
  )

  useEffect(() => {
    whenAvailable('zE', () => {
      toggleZendesk(initialState)
    })
  }, [initialState, toggleZendesk])

  return [isVisible, toggleZendesk] as const
}
