import { useEffect, useState } from 'react'

function useDebounce<T>(value: T, delay = 400): T {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    if (!debouncedValue) {
      setDebouncedValue(value)
      return
    }
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay, debouncedValue])

  return debouncedValue
}

export default useDebounce
