export function Background({
  color,
  image,
}: {
  color: string
  image: React.ReactElement
}) {
  if (!image?.props?.src) return null

  return (
    <>
      {image && image}

      <div
        className="absolute w-full h-full z-0 top-1"
        style={{
          background: `linear-gradient(rgba(0,0,0,0), ${color})`,
        }}
      />
    </>
  )
}

Background.displayName = 'Background'
