// DrawerContext.tsx
import { createContext, useContext, useState, ReactNode } from 'react'

interface DrawerContextType {
  isOpen: boolean
  content: ReactNode | null
  openDrawer: ({ drawerContent }: { drawerContent: ReactNode }) => void
  closeDrawer: () => void
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined)

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (!context) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}

type DrawerProviderProps = {
  children: ReactNode
}

export default function DrawerProvider({ children }: DrawerProviderProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [content, setContent] = useState<ReactNode | null>(null)

  const openDrawer = ({ drawerContent }: { drawerContent: ReactNode }) => {
    setContent(drawerContent)
    setIsOpen(true)
  }

  const closeDrawer = () => {
    setIsOpen(false)
    setContent(null)
  }

  return (
    <DrawerContext.Provider
      value={{ isOpen, content, openDrawer, closeDrawer }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
