import { RootState } from '../reducers'
import { createAppSelector } from '@/state/helpers/createSelector'

export const getSelectedLocation = createAppSelector(
  ({ global }) => global.selectedLocation,
  (selectedLocation) => selectedLocation
)

export const getAnalyticsPayload = createAppSelector(
  ({ global }) => global.analyticsPayload,
  (analyticsPayload) => analyticsPayload
)

export const getListStyle = (state: RootState) => {
  return state.global.listStyle
}

export const getQuerySelector = createAppSelector(
  ({ global }) => global.query,
  (query) => query
)
