import { forwardRef, Ref } from 'react'

interface SVGProps extends React.SVGProps<SVGSVGElement> {}
const UtensilsIcon = forwardRef<SVGSVGElement, SVGProps>(
  (props, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      ref={ref}
      {...props}
    >
      <path d="M71.9 26.7c1.5-13.2-8-25-21.2-26.5s-25 8-26.5 21.2l-16 144L8 166.7V168v16c0 48.6 39.4 88 88 88h8V488c0 13.3 10.7 24 24 24s24-10.7 24-24V272h8c48.6 0 88-39.4 88-88V168v-1.3l-.1-1.3-16-144C230.4 8.2 218.5-1.3 205.3 .1s-22.7 13.3-21.2 26.5L200 169.3V184c0 22.1-17.9 40-40 40H128 96c-22.1 0-40-17.9-40-40V169.3L71.9 26.7zM152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V168c0 13.3 10.7 24 24 24s24-10.7 24-24V24zM336 176c0-57.7 21.7-88.6 41.4-105.7c7.7-6.6 15.5-11.6 22.6-15.2V248v56H352c-8.8 0-16-7.2-16-16V176zm64 176V488c0 13.3 10.7 24 24 24s24-10.7 24-24V352 304 248 32c0-17.7-14.3-32-32-32C400 0 288 32 288 176V288c0 35.3 28.7 64 64 64h48z" />
    </svg>
  )
)

export default UtensilsIcon
