import { useCallback, useEffect, useRef, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import type { RootState } from '@/state/reducers'
import {
  useAppDispatch,
  useAppSelector as useSelector,
} from '@/state/helpers/useApp'
import * as actions from '@/state/global/global.actions'
import type { DefaultAnalyticsRequest } from '@/connect-types/analytics/request.type'
import usePrevious from '@/utils/usePrevious'
import { useGlobalQuery } from '@/state/global/global.hooks'
import useSelectedLocation from '../state/entities/locations/hooks/useSelectedLocation'

const useAnalyticsQuery = (initQuery?: Partial<DefaultAnalyticsRequest>) => {
  const dispatch = useAppDispatch()
  const initLoad = useRef(false)
  const query = useSelector(({ global }: RootState) => global.analyticsPayload)
  const prevQuery = usePrevious(query)
  const selectedLocation = useSelectedLocation()
  const { pathname } = useLocation()
  const [newQ] = useGlobalQuery()
  const prevNewQuery = usePrevious(newQ)

  const setQuery = useCallback(
    (newQuery: Partial<DefaultAnalyticsRequest>) => {
      if (Object.is({ ...newQuery, ...query }, query)) return
      //if (isEqual({ ...newQuery, ...query }, query)) return
      let sd = newQuery.start || 0
      let ed = newQuery.end || 0
      console.log({ sd, ed }, 'time')
      if (sd.toString().length === 13) {
        sd = Number((sd / 1000).toFixed(0))
      }
      if (ed.toString().length === 13) {
        ed = Number((ed / 1000).toFixed(0))
      }
      dispatch(
        actions.setAnalyticsPayload({
          ...query,
          ...newQuery,
          start: sd,
          end: ed,
        })
      )
    },
    [dispatch, query]
  )

  useEffect(() => {
    if (
      prevNewQuery.startDate === newQ.startDate &&
      prevNewQuery.endDate === newQ.endDate
    )
      return

    setQuery({ start: newQ.startDate, end: newQ.endDate })
  }, [
    newQ.startDate,
    newQ.endDate,
    setQuery,
    prevNewQuery.startDate,
    prevNewQuery.endDate,
  ])

  useEffect(() => {
    if (!initQuery) return

    if (initLoad.current) return

    initLoad.current = true
    setQuery({ ...query, ...initQuery })
  }, [initQuery, setQuery, query])

  useEffect(() => {
    if (!selectedLocation) return
    if (!pathname.includes('/venue')) return
    //setSerial(selectedLocation.serial)
  }, [selectedLocation, pathname])

  const hasDateChanged = useMemo(() => {
    if (Object.is(prevQuery, query)) return false

    if (
      prevQuery &&
      prevQuery.start === query.start &&
      prevQuery.end === query.end
    )
      return false

    return true
  }, [prevQuery, query])

  return useMemo(() => {
    return {
      query,
      hasChanged: !Object.is(prevQuery, query),
      hasDateChanged,
      setQuery,
    }
  }, [hasDateChanged, prevQuery, query, setQuery])
}

export default useAnalyticsQuery
