import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'
import React from 'react'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isLoading?: boolean
}

const buttonVariants = cva(
  `inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors 
     focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring 
     disabled:pointer-events-none disabled:opacity-50 whitespace-nowrap`,
  {
    variants: {
      variant: {
        primary: 'bg-stampede hover:bg-stampede-200 text-white',
        secondary: 'bg-white hover:bg-gray-100',
        destructive: 'bg-red-500 text-white hover:bg-red-700',
        ghost: 'bg-gray-50 hover:bg-gray-100 text-gray-700',
        link: 'bg-transparent hover:bg-transparent text-stampede',
        outline:
          'bg-transparent hover:bg-gray-100 text-gray-700 border border-gray-300',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
)

const NewButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      />
    )
  }
)

NewButton.displayName = 'NewButton'
export { NewButton }
