import type { FieldErrors, UseFormSetError } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'
import { isZodError } from '@/utils/axiosHelper'

export const handleErrors = (
  error: unknown,
  setError: UseFormSetError<object>
) => {
  if (!error) return
  if (!('data' in error)) return
  if (!('errors' in error.data)) return
  for (const [key, value] of Object.entries(error.data.errors)) {
    setError(key, { message: value })
  }
}

export function hookFormErrorToast(data: FieldErrors) {
  toast.error(formatHookFormErrors(data))
}

function formatHookFormErrors(errors: FieldErrors) {
  return Object.entries(errors)
    .reduce((acc: string[], [key, value]) => {
      return [
        ...acc,
        `${key}: ${value && 'message' in value ? value.message : 'Field invalid'}`,
      ]
    }, [])
    .join('; ')
}

export function extractZodError(error) {
  if (!isZodError(error)) return
  return new z.ZodError(error.response?.data.message)
}
