import { combineReducers } from '@reduxjs/toolkit'

import billing from './billing/billing.reducer'
import integration from './integration/integration.reducer'
import member from './member/member.reducer'
import settings from './settings/settings.reducer'
import analytics from './analytics/analytics.reducer'
import organisations from './organisations/organisations.reducer'
import loaded from './loaded/loaded.reducer'
import ui from './ui/ui.reducer'
import organisation_user from './organisation-user/organisation-user.reducer'
import reports from './reports/reports.reducer'
import personalisation from './personalisation/personalisation.reducer'

export default combineReducers({
  personalisation,
  billing,
  integration,
  reports,
  member,
  settings,
  analytics,
  organisations,
  loaded,
  ui,
  organisation_user,
})
