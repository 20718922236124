import useGlobal from '@/hooks/useGlobal'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import morpheusApi from '@/state/morpheus/morpheus.slice'

export const useSelectedVenue = (serial = '') => {
  const { orgId } = useGlobal()
  const data = morpheusApi.useGetLocationQuery(
    { serial, orgId },
    { skip: !serial }
  )

  return data
}

export const useSelectedOrganisationVenue = (serial: string) => {
  const selectedOrganisation = useSelectedOrganisation()

  return (selectedOrganisation?.locations ?? []).find(
    (item) => item.serial === serial
  )
}
