import { Reducer } from '@reduxjs/toolkit'
import message from '@/common/message/message'
import { subDays } from 'date-fns'
import { produce } from 'immer'

import { DefaultAnalyticsRequest } from '@/connect-types/analytics/request.type'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import { OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import { ActionType, getType } from 'typesafe-actions'
import * as actions from './global.actions'
import { DefaultQueryType, initialDefaultQuery } from './global.types'

export type GlobalAction = ActionType<typeof actions>

/**
 * NOTE: interface MUST be named 'StateType' (corresponding to StateType
 * from typesafe-actions)
 */
export interface StateType {
  readonly infoBanner: {
    readonly isOpen: boolean
  }
  menu: {
    isOpen: boolean
  }
  selectedLocation: OrganisationLocationType | null
  analyticsPayload: DefaultAnalyticsRequest
  filterOpen: boolean
  chartStyle: string
  isCompound: boolean
  locationModalVisible: boolean
  addLocationModalVisible: boolean
  isDemoMode: boolean
  orgId: string
  selectedPartnerOrg: string
  token: string
  organisationModalVisible: boolean
  mimicBanner: boolean
  selectedReviewPageId: string
  listStyle: 'list' | 'grid'
  query: DefaultQueryType

  pagination: {
    search: string
  }
}

/**
 * Reducer's initial state
 */
const initialState: StateType = {
  query: initialDefaultQuery,
  listStyle: 'grid',
  infoBanner: {
    isOpen: false,
  },
  menu: {
    isOpen: true,
  },
  selectedLocation: null,
  selectedReviewPageId: null,
  analyticsPayload: {
    serial: '',
    end: new Date().getTime(),
    start: subDays(new Date(), 7).getTime(),
  },
  filterOpen: false,
  chartStyle: 'line',
  isCompound: false,
  locationModalVisible: false,
  addLocationModalVisible: false,
  isDemoMode: false,
  orgId: null,
  selectedPartnerOrg: null,
  token: null,
  organisationModalVisible: false,
  mimicBanner: true,

  pagination: {
    search: '',
  },
}

const globalReducer: Reducer<StateType, GlobalAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setInitialSearch): {
        draft.pagination.search = action.payload.term
        break
      }
      case getType(actions.setQuery): {
        draft.query = { ...draft.query, ...action.payload.query }
        break
      }

      case getType(actions.setMimicBanner): {
        draft.mimicBanner = action.payload.mimicBanner
        break
      }

      case getType(actions.clearAll): {
        draft.orgId = null
        draft.selectedLocation = null
        draft.selectedPartnerOrg = null
        break
      }

      case getType(actions.setOrgId): {
        if (draft.orgId !== action.payload.orgId) {
          draft.orgId = action.payload.orgId
          draft.selectedLocation = null
        }

        break
      }

      /**
       * GLOBAL_SET_DEMO_MODE
       *
       * Set the demo mode
       */
      case getType(actions.setDemoMode): {
        draft.isDemoMode = action.payload.isDemoMode
        break
      }

      /**
       * GLOBAL_OPEN_INFO_BANNER
       *
       * Opens the info banner
       */
      case getType(actions.openInfoBanner): {
        draft.infoBanner.isOpen = true
        break
      }
      /**
       * GLOBAL_CLOSE_INFO_BANNER
       *
       * Closes the info banner
       */
      case getType(actions.closeInfoBanner): {
        draft.infoBanner.isOpen = false
        break
      }

      /**
       * GLOBAL_OPEN_MENU
       *
       * Opens the menu
       */
      case getType(actions.openMenu): {
        draft.menu.isOpen = true
        break
      }
      /**
       * GLOBAL_CLOSE_MENU
       *
       * Closes the menu
       */
      case getType(actions.closeMenu): {
        draft.menu.isOpen = false
        break
      }

      case getType(actions.locationModalSwitch): {
        draft.locationModalVisible = action.payload.visible
        break
      }

      case getType(actions.organisationModal): {
        draft.organisationModalVisible = action.payload.visible
        break
      }

      case getType(actions.addLocationModalSwitch): {
        draft.addLocationModalVisible = action.payload.visible
        break
      }

      /**
       * GLOBAL_SET_SELECTED_LOCATION
       *
       * Sets the selected location
       */
      case getType(actions.setSelectedLocation): {
        if (
          draft.selectedLocation &&
          action.payload.location &&
          draft.selectedLocation.serial === action.payload.location.serial
        ) {
          break
        }

        draft.selectedLocation = action.payload.location

        break
      }

      case getType(actions.clearSelectedLocation): {
        draft.selectedLocation = null
        break
      }
      case getType(actions.setAnalyticsPayload): {
        draft.analyticsPayload = action.payload.request
        break
      }
      case getType(actions.toggleFilter): {
        draft.filterOpen = !draft.filterOpen
        break
      }
      case getType(actions.setChartStyle): {
        draft.chartStyle = action.payload.style
        break
      }
      case getType(actions.setChartCompound): {
        draft.isCompound = action.payload.isCompound
        break
      }
      case getType(actions.updateLocations): {
        if (draft.selectedLocation) {
          if (
            draft.selectedLocation.serial === action.payload.location.serial
          ) {
            draft.selectedLocation.alias = action.payload.location.alias
            draft.selectedLocation.type = action.payload.location.type
          }
        }

        break
      }
      case getType(actions.setSelectedPartnerOrg): {
        draft.selectedPartnerOrg = action.payload.selectedPartnerOrg
        break
      }
      case getType(actions.setNotificationToken): {
        draft.token = action.payload.token
        break
      }
      case getType(actions.setSelectedReviewPageId): {
        draft.selectedReviewPageId = action.payload.id
        break
      }
      case getType(actions.setListStyle): {
        draft.listStyle = action.payload
        break
      }

      case getType(actions.unlinkVenueFromOrg.request): {
        break
      }
      case getType(actions.unlinkVenueFromOrg.success): {
        message.success('Venue deleted')
        break
      }
      case getType(actions.unlinkVenueFromOrg.failure): {
        ServiceErrorHandler(action.payload)
        break
      }
    }
  })

export default globalReducer
