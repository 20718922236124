/**
 * Source: https://blog.bitsrc.io/complex-app-logic-with-redux-and-redux-saga-write-an-authentication-monitor-2f5672303d7d
 */
// import { delay } from 'redux-saga'
import message from '@/common/message/message'
import { race, take, takeEvery } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import APIMessages from '../data/APIMessages.json'
// import { RootState } from './reducers'
// FIXME: to handle for all actions, need to import them all here and update
// the `type Action` to include all action types
import * as authActions from './auth/auth.actions'
import * as analyticsActions from './entities/analytics/analytics.actions'
import * as billingActions from './entities/billing/billing.actions'
import * as configActions from './entities/config/config.actions'

import * as integrationActions from './entities/integration/integration.actions'

import * as memberActions from './entities/member/member.actions'
import * as settingsActions from './entities/settings/settings.actions'

import * as globalActions from './global/global.actions'

export type Action = ActionType<
  typeof authActions &
    typeof globalActions &
    typeof billingActions &
    typeof integrationActions &
    typeof memberActions &
    typeof settingsActions &
    typeof analyticsActions &
    typeof configActions
>

const ignoreActionTypes: string[] = [
  'USER_AUTH_WITH_CODE_REQUEST',
  'USER_TOKEN_REFRESH_REQUEST_FAILURE',
  'FETCH_USER_ROLE_REQUEST',
  'FETCH_USER_ROLE_REQUEST_SUCCESS',
  'FETCH_OAUTH_TEST_SUCCESS',
  'FETCH_OAUTH_TEST_REQUEST',
]

function monitorableAction(action: Action) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every((fragment) => !action.type.includes(fragment))
  )
}

function* monitor(monitoredAction: Action) {
  /* tslint:disable no-console */
  console.log('started monitoring', monitoredAction)

  const { fail } = yield race({
    success: take((action: Action) => action.type.includes('SUCCESS')),
    fail: take((action: Action) => action.type.includes('FAILURE')),
  })

  if (fail && fail.payload && fail.payload.error) {
    if (fail) {
      const findIndex = APIMessages.findIndex(
        (messageToDisplay) =>
          fail.payload.error.message === messageToDisplay.key
      )

      if (findIndex !== -1) {
        if (APIMessages[findIndex].severity === 'Warning') {
          message.warning(APIMessages[findIndex].message)
        } else if (APIMessages[findIndex].severity === 'Error') {
          message.error(APIMessages[findIndex].message)
        } else if (APIMessages[findIndex].severity === 'Info') {
          message.info(APIMessages[findIndex].message)
        }
      }
    }
  }

  console.log('monitoring', monitoredAction.type, 'finished')
}

export function* watchMonitor() {
  yield takeEvery(monitorableAction, monitor)
}

export default [watchMonitor]

// TODO: NEXT STEPS
// - Should I have a `monitor` prop of the store and add `isRefreshing` boolean in there? (as per: https://blog.bitsrc.io/complex-app-logic-with-redux-and-redux-saga-write-an-authentication-monitor-2f5672303d7d)
// - Should I have a `state/monitor/` folder and have reducer to set up `monitor` state? (Or better named thing)
// - How should I handle request retries to avoid infinitely looping the monitor*() function?
