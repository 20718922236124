export enum LegacyPlanTypes {
  Free = 'free',
  SelfServe = 'self-serve',
  Starter = 'starter',
  Growth = 'growth',
  Enterprise = 'enterprise',
}

export const addonDetail: {
  [key in AddonFeaturesType]: {
    code: AddonFeaturesType
    name: string
    quantity: number
    venues: string[]
    price: number
    description: string
  }
} = {
  contacts: {
    code: 'contacts',
    name: 'Contacts',
    quantity: 0,
    venues: [],
    price: 800,
    description: '',
  },
  bookings: {
    code: 'bookings',
    name: 'Bookings',
    quantity: 0,
    venues: [],
    price: 9900,
    description: '',
  },
  gift_cards: {
    code: 'gift_cards',
    name: 'Gift Cards',
    quantity: 0,
    venues: [],
    price: 0,
    description: '',
  },
  gift_cards_plus: {
    code: 'gift_cards_plus',
    name: 'Gift Cards+',
    quantity: 0,
    venues: [],
    price: 1000,
    description: '',
  },
  loyalty: {
    code: 'loyalty',
    name: 'Loyalty',
    quantity: 0,
    venues: [],
    price: 1000,
    description: '',
  },
  marketing: {
    code: 'marketing',
    name: 'Marketing',
    quantity: 0,
    venues: [],
    price: 8900,
    description: '',
  },
  marketing_plus: {
    code: 'marketing_plus',
    name: 'Marketing+',
    quantity: 0,
    venues: [],
    price: 12900,
    description: '',
  },
  reviews: {
    code: 'reviews',
    name: 'Reviews',
    quantity: 0,
    venues: [],
    price: 12900,
    description: '',
  },
  wifi: {
    code: 'wifi',
    name: 'Wi-Fi',
    quantity: 0,
    venues: [],
    price: 2900,
    description: '',
  },
  wifi_plus: {
    code: 'wifi_plus',
    name: 'Wi-Fi+',
    quantity: 0,
    venues: [],
    price: 5900,
    description: '',
  },
}

export interface BillingType {
  subscription: {
    organisation_id: string
    provider_type: 'morpheus' | 'stripe'
    provider_id: null
    currency: 'GBP' | 'USD' | 'EUR'
    addons: {
      [key in AddonFeaturesType]: {
        code: AddonFeaturesType
        name: string
        quantity: number
        venues: string[]
      }
    }
    updated_at: '2024-02-13T10:07:09.694Z'
    expires_at: '2024-02-13T10:07:09.694Z' | null
    is_trial: boolean
    is_annual: boolean
  }
  features: {
    bookings: boolean
    contacts: boolean
    gift_cards: boolean
    gift_cards_plus: boolean
    loyalty: boolean
    marketing: boolean
    marketing_plus: boolean
    reviews: boolean
    wifi: boolean
    wifi_plus: boolean
  }
  total_contacts: number
  active: boolean
  legacy_billing_translation: boolean
  legacy_plan: LegacyPlanTypes
}

export type AddonFeaturesType = keyof BillingType['features']

export type PurchaseSmsCreditsResponse = {
  invoice: {
    amount_due: number
    amount_paid: number
    currency: string
    hosted_invoice_url: string
  }
  message: string
}
