import { BackendDate } from '@/connect-types/date/date.type'
import { Quote } from '@/connect-types/partner/quote.type'
export type CurrencyTypes = 'GBP' | 'EUR' | 'USD'

export enum PlanTypes {
  Free = 'free',
  SelfServe = 'self-serve',
  Starter = 'starter',
  Growth = 'growth',
  Enterprise = 'enterprise',
}

export const addonIds = [
  'webforms',
  'digitalmenus',
  'marketing',
  'dataimport',
  'mobileapp',
  'websitefootprint',
  'analytics',
  'wifi',
  'easysignin',
  'customdatacapture',
  'emailsupport',
  'loyalty',
  'giftcards',
  'stories',
  'reviews',
  'phonesupport',
  'zapier',
  'marketingautomation',
  'dedicatedcsm',
  'dedicatedmarketingsupport',
  'dedicatedsupport',
  'supportslas',
  'customintegrations',
  'setupfee',
  'inbox',
] as const

export type AddonTypes = (typeof addonIds)[number]

const defaultIncluded = addonIds.reduce(
  (ac, a) => ({
    ...ac,
    [a]: false,
  }),
  {}
) as { [k in AddonTypes]: boolean }

export const addons: { [k in AddonTypes]: boolean } = {
  ...defaultIncluded,
  analytics: true,
  customdatacapture: true,
  dataimport: true,
  emailsupport: true,
  giftcards: false,
  marketing: true,
  marketingautomation: false,
  mobileapp: true,
  phonesupport: false,
  reviews: false,
  stories: false,
  webforms: true,
  websitefootprint: true,
  wifi: true,
  zapier: false,
}

export interface FrameOrganisationSubscriptionRequest {
  plan: PlanTypes
  annual: boolean
  contacts: number
  venues: number
  selectedPlan?: any
  addons: AddonTypes[]
  currency: CurrencyTypes
}
export interface OrganisationSubscription {
  subscription_id?: string
  addons: typeof addons
  plan: PlanTypes
  createdAt?: BackendDate
  contacts: number
  venues: number
  currency: CurrencyTypes
  status?: string
  annual: boolean
  numberFormatter?: Intl.NumberFormat
  can_edit: boolean
  used_contacts: number
  used_venues: number
  is_legacy: boolean
  using_stripe: boolean
  parent_org_name: string
  /**
   * Added in product
   */
  needs_contact_upgrade?: number
  needs_venue_upgrade?: number
}

export interface OrganisationQuoteRequestSubscription
  extends FrameOrganisationSubscriptionRequest {
  description: string
  id?: string
}

export interface OrganisationQuoteResponse {
  subscription: OrganisationSubscription
  quote: Quote
}
