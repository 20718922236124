import flatten from 'lodash/flatten'

const getCssVariable = (name: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

const backgroundColors = [
  '#ffbe0b',
  '#1c77c3',
  '#d91e36',
  '#7fb7be',
  '#ff3399',
  '#8cd867',
]

const GetIndexInAlphabet = (char: string) =>
  char.toUpperCase().charCodeAt(0) - 65

export const getColorFromString = (value: string) => {
  if (!value) return backgroundColors[0]
  const onlyString = value.replace(/[0-9]/g, '')
  const first = onlyString.charAt(0)
  const alphabetIndex = GetIndexInAlphabet(first)
  const fillArray = flatten(Array(26).fill(backgroundColors)).slice(0, 26)
  return fillArray[alphabetIndex]
}

export default getCssVariable
