import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useEffect, useState } from 'react'
import backendApi from '@/state/backend/backend.slice'
import type { RootNode, Segment } from '@/state/entities/segment/segment.types'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import useGlobal from './useGlobal'

export const useSegmentMetadata = () => {
  const { orgId } = useGlobal()
  const { data: metadata = null } = morpheusApi.useGetSegmentMetadataQuery({
    orgId,
  })

  return metadata
}

export const useSegments = () => {
  const { orgId } = useGlobal()

  const { data = [], ...rest } = morpheusApi.useGetSegmentsQuery({ orgId })

  return { data, ...rest }
}

export const useSingleSegment = (id: string) => {
  const { orgId } = useGlobal()
  const { data: segments, ...rest } = useSegments()
  const data = morpheusApi.useGetSegmentQuery(
    {
      orgId,
      id,
    },
    {
      skip: !id || id === 'new',
    }
  )

  if (id === 'all' || !id) {
    return {
      data: segments.find((item) => item.id === 'all'),
      ...rest,
    }
  }

  return data
}
