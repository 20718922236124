'use client'
import { ReactNode, forwardRef, useState } from 'react'
import { Popover, PopoverContent, PopoverTrigger } from '.'
import useDebounce from '../helpers/useDebounce'
import { PopoverArrow } from '@radix-ui/react-popover'

interface HoverPopoverProps {
  children: ReactNode
  content: ReactNode
  className?: string
}

const HoverPopover = forwardRef<HTMLDivElement, HoverPopoverProps>(
  ({ children, content, className }, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    const debouncedIsOpen = useDebounce(isOpen, 100)

    const handleMouseEnter = () => {
      setIsOpen(true)
    }

    const handleMouseLeave = () => {
      setIsOpen(false)
    }

    return (
      <Popover open={debouncedIsOpen}>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <PopoverTrigger asChild onClick={(e) => e.preventDefault()}>
            {children}
          </PopoverTrigger>
          <PopoverContent
            ref={ref}
            className={className}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            sideOffset={2}
          >
            {content}
          </PopoverContent>
        </div>
      </Popover>
    )
  }
)

HoverPopover.displayName = 'HoverPopover'

export { HoverPopover }
