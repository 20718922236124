import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format, roundToNearestMinutes } from 'date-fns'
import { FunctionComponent } from 'react'
import { InboxThreadEventEventableNoteType } from '@/state/entities/inbox/inbox.types'
import { ChatAvatar, ThreadPopover } from './common'
import { SIconButton } from 'tailwind-ui'

const ThreadNoteBox: FunctionComponent<{
  event: InboxThreadEventEventableNoteType
}> = ({ event }) => {
  return (
    <div className="flex items-end justify-end ml-24 group">
      <div>
        <div
          className="relative py-1 px-4 bg-yellow-400 dark:bg-yellow-500 rounded-2xl"
          id="content"
        >
          <ThreadPopover event={event}>
            <SIconButton
              icon={InformationCircleIcon}
              size="sm"
              className="absolute opacity-0 left-0 top-0 group-hover:opacity-100 transition-all duration-250 group-hover:-left-14"
            />
          </ThreadPopover>
          <div
            className="text-yellow-900 dark:text-yellow-50"
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        </div>
        <div className="text-right text-sm text-gray-600" id="time">
          {format(
            roundToNearestMinutes(event.created_at, {
              nearestTo: 10,
            }),
            TimeFormats.FourHourApple
          )}
        </div>
      </div>
      <div className="ml-2 w-8 h-8 min-w-8">
        <ChatAvatar
          id="avatar"
          email={event.user_email}
          name={event.user_name}
        />
      </div>
    </div>
  )
}

export default ThreadNoteBox
