import { cn } from 'tailwind-ui'

function Skeleton({
  className,
  isLoading = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { isLoading?: boolean }) {
  return (
    <div
      className={cn(
        ' rounded-2xl',
        { 'animate-pulse bg-black/10 dark:bg-white/10': isLoading },
        className
      )}
      {...props}
    />
  )
}

export { Skeleton }
