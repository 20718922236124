import { useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import morpheusApi from '@/state/morpheus/morpheus.slice'

const useSelectedOrganisation = () => {
  const { org_id } = useParams()
  const push = useNavigate()
  const selectedOrganisation = morpheusApi.useGetOrganisationQuery(org_id, {
    refetchOnFocus: false,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    skip: !org_id,
  })

  useEffect(() => {
    if (!selectedOrganisation.isError) return
    push('/')
  }, [selectedOrganisation.isError])

  return selectedOrganisation.data
}

export const useSelectedOrganisationWithChildren = () => {
  const { org_id } = useParams()
  const { data = [] } = morpheusApi.useGetOrganisationChildrenQuery(org_id, {
    skip: !org_id,
  })
  const org = useSelectedOrganisation()

  return useMemo(() => {
    if (!org) {
      return null
    }
    return { ...org, children: data }
  }, [org, data])
}

export default useSelectedOrganisation
