import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/16/solid'
import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useCallback, useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Icn, SButton, SNavLink, cx } from 'tailwind-ui'
import { useLocalStorage } from 'usehooks-ts'

interface AlertType {
  to?: string
  isDismissible?: boolean
  itemKey: string
  title: string
  onClick?: () => void
  onDismiss?: () => void
  type: 'success' | 'info' | 'warning' | 'error'
  isDismissed?: boolean
  dismissOnClick?: boolean
  buttonText?: string
  updated_at: number
}

const AlertToIcon = (alert: 'success' | 'info' | 'warning' | 'error') => {
  switch (alert) {
    case 'success':
      return CheckCircleIcon
    case 'info':
      return InformationCircleIcon
    case 'warning':
      return ExclamationCircleIcon
    case 'error':
      return XCircleIcon
  }
}

export const Alert: FunctionComponent<AlertType> = ({
  to = '',
  isDismissible = false,
  title = '',
  onClick,
  onDismiss,
  type = 'info',
  itemKey,
  isDismissed = false,
  dismissOnClick = false,
  buttonText,
}) => {
  const [, setAlerts] = useAlertContext()
  const disMiss = useCallback(() => {
    if (onDismiss) onDismiss()
    setAlerts({ itemKey, isDismissed: true })
  }, [itemKey, setAlerts])

  if (isDismissed) {
    return <></>
  }

  return (
    <div
      className={cx(
        'pointer-events-auto border w-full text-white space-y-4 rounded-xl  p-4 ',
        {
          'bg-blue-600 border-blue-700': type === 'info',
          'bg-red-600 border-red-700 text-white': type === 'error',
          'bg-yellow-600 border-yellow-700': type === 'warning',
          'bg-green-600 border-green-700': type === 'success',
        }
      )}
    >
      <div
        className={cx('w-14 h-14 flex items-center justify-center rounded-lg', {
          'bg-blue-700 ': type === 'info',
          'bg-red-700  ': type === 'error',
          'bg-yellow-700 ': type === 'warning',
          'bg-green-700 ': type === 'success',
        })}
      >
        <Icn icon={AlertToIcon(type)} className="w-8 h-8 " />
      </div>
      <p className=" leading-6 ">{title}</p>
      <div className="mt-4 flex items-center gap-x-2">
        {to ? (
          <SNavLink
            to={to}
            isDarkBg
            type="button"
            size="lg"
            className="rounded-full"
          >
            {buttonText || 'Fix Now'}
          </SNavLink>
        ) : (
          <SButton
            isDarkBg
            type="button"
            size="lg"
            className="rounded-full"
            onClick={() => {
              console.log('clicked')
              onClick()
              if (dismissOnClick) disMiss()
            }}
          >
            {buttonText || 'Fix Now'}
          </SButton>
        )}

        {isDismissible ? (
          <SButton
            size="lg"
            variant="ghost_default"
            className="rounded-full  "
            classNames={{
              span: 'text-gray-50 ',
            }}
            onClick={disMiss}
          >
            Ignore
          </SButton>
        ) : null}
      </div>
    </div>
  )
}

type AlertContextType = [AlertType[], (value: Partial<AlertType>) => void]

const AlertContext = createContext<AlertContextType>([[], () => {}])

const deserializeFunction = (fnString?: string | (() => void)) => {
  if (!fnString) return undefined
  if (typeof fnString === 'function') return fnString

  // Create a safe function from string representation
  try {
    // Only allow specific safe patterns
    if (
      fnString.includes('window') ||
      fnString.includes('eval') ||
      fnString.includes('document')
    ) {
      console.error('Unsafe function detected')
      return undefined
    }
    return new Function(`return ${fnString}`)()
  } catch (err) {
    console.error('Error deserializing function:', err)
    return undefined
  }
}

export function AlertProvider({ children }: PropsWithChildren) {
  const { org_id } = useParams<{ org_id: string }>()
  const [alerts, setAlerts] = useLocalStorage<AlertType[]>(
    `stmpd-notifications-${org_id}`,
    [],
    {
      deserializer: (value) => {
        const parsed = JSON.parse(value) as AlertType[]
        return parsed.map((i) => ({
          ...i,
          onClick: deserializeFunction(i.onClick),
          onDismiss: deserializeFunction(i.onDismiss),
        }))
      },
    }
  )

  const { pathname } = useLocation()
  const updateMap = useCallback(
    (value: Partial<AlertType>) => {
      setAlerts((oldS) => {
        const s = [...oldS]
        const index = s.findIndex((item) => item.itemKey === value.itemKey)

        if (index >= 0) {
          s[index] = { ...s[index], ...value }
          return s.map((i) => ({
            ...i,
            onClick: i.onClick?.toString(),
            onDismiss: i.onDismiss?.toString(),
          }))
        }

        return [
          ...s,
          {
            ...value,
            onDismiss: value.onDismiss?.toString(),
            onClick: value.onClick?.toString(),
            updated_at: new Date().getTime(),
            isDismissed: false,
          } as AlertType,
        ]
      })
    },
    [setAlerts]
  )

  useEffect(() => {
    if (pathname !== '/') return
    setAlerts([])
  }, [pathname, setAlerts])

  return (
    <AlertContext.Provider value={[alerts, updateMap]}>
      {children}
    </AlertContext.Provider>
  )
}

export const useAlertContext = () => {
  return useContext(AlertContext)
}
