import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'

export type SvgIcon = Omit<
  React.ForwardRefExoticComponent<
    React.PropsWithoutRef<React.SVGProps<SVGSVGElement>> & {
      title?: string
      titleId?: string
    } & React.RefAttributes<SVGSVGElement>
  >,
  'defaultProps' | 'propTypes'
>

// @todo styles
export const icnVariants = cva(``, {
  variants: {
    size: {
      sm: 'h-4 w-4',
      xs: 'h-3 w-3',
      base: 'h-5 w-5',
      lg: 'h-6 w-6',
      xl: 'h-8 w-8',
    },
  },
  defaultVariants: {
    size: 'base',
  },
})

export interface IcnPropTypes
  extends React.SVGProps<SVGSVGElement>,
    VariantProps<typeof icnVariants> {
  icon: SvgIcon
}

export function Icn({ icon: Icn, size, className, ...rest }: IcnPropTypes) {
  return <Icn className={cn(icnVariants({ size, className }))} {...rest} />
}
