import { FacebookAccount } from '@/connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from '@/connect-types/reviews/facebookPages.type'
import { get, put as putRequest } from '@/utils/api'
import { call, put, takeLatest } from 'redux-saga/effects'
import { ActionType, getType } from 'typesafe-actions'
import * as loadedActions from '../loaded/loaded.actions'
import * as actions from './settings.actions'

/*
 * Get Facebook Accounts Associated with Member
 * =========================
 */
function* locationsFacebookAccountsRequest(
  action: ActionType<typeof actions.locationsFacebookAccountsRequest>
) {
  try {
    const { orgId } = action.payload

    const response: {
      status: number
      message: FacebookAccount[]
    } = yield call(async () => get(`/facebook/accounts/${orgId}`))
    yield put(loadedActions.setLoaded('facebook_accounts', true))
    yield put(actions.locationsFacebookAccountsRequestSuccess(response.message))
  } catch (error) {
    yield put(actions.locationsFacebookAccountsRequestFailure(error.response))
  }
}

export function* watchLocationsFacebookAccountsRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAccountsRequest),
    locationsFacebookAccountsRequest
  )
}

/*
 * Get Facebook Pages Associated with Member
 * =========================
 */
function* locationsFacebookAccountsPagesRequest(
  action: ActionType<typeof actions.locationsFacebookAccountsPagesRequest>
) {
  try {
    const { orgId, accountId } = action.payload

    const response: {
      status: number
      message: FacebookPage[]
    } = yield call(async () =>
      get(`/facebook/accounts/${orgId}/${accountId}/pages`)
    )

    yield put(
      actions.locationsFacebookAccountsPagesRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(
      actions.locationsFacebookAccountsPagesRequestFailure(error.response)
    )
  }
}

export function* watchLocationsFacebookAccountsPagesRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAccountsPagesRequest),
    locationsFacebookAccountsPagesRequest
  )
}

/*
 * Authorize Facebook
 * =========================
 */
function* locationsFacebookAuthorizeAccountRequest(
  action: ActionType<typeof actions.locationsFacebookAuthorizeAccountRequest>
) {
  try {
    const { orgId, appName, redirectUrl } = action.payload

    const response: {
      status: number
      message: string
    } = yield call(async () =>
      get(
        `/facebook/authorize/${orgId}?redirect_url=${redirectUrl}&app_name=${appName}`
      )
    )

    yield put(
      actions.locationsFacebookAuthorizeAccountRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(
      actions.locationsFacebookAuthorizeAccountRequestFailure(error.response)
    )
  }
}

export function* watchLocationsFacebookAuthorizeAccountRequest() {
  yield takeLatest(
    getType(actions.locationsFacebookAuthorizeAccountRequest),
    locationsFacebookAuthorizeAccountRequest
  )
}

/*
 * Update or Create Facebook Page Reference
 * =========================
 */
function* updateLocationsFacebookPageRequest(
  action: ActionType<typeof actions.updateLocationsFacebookPageRequest>
) {
  try {
    const { orgId, pageId, token, review_id } = action.payload

    const response: {
      status: number
      message: FacebookPage
    } = yield call(async () =>
      putRequest(`/facebook/accounts/${orgId}/${token}/pages/${pageId}`, {
        review_id,
      })
    )

    yield put(
      actions.updateLocationsFacebookPageRequestSuccess(response.message)
    )
  } catch (error) {
    yield put(actions.updateLocationsFacebookPageRequestFailure(error.response))
  }
}

export function* watchUpdateLocationsFacebookPageRequest() {
  yield takeLatest(
    getType(actions.updateLocationsFacebookPageRequest),
    updateLocationsFacebookPageRequest
  )
}

export default [
  watchLocationsFacebookAuthorizeAccountRequest,
  watchLocationsFacebookAccountsPagesRequest,
  watchUpdateLocationsFacebookPageRequest,
]
