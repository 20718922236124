'use client'
import React, { forwardRef } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'tailwind-ui'
import { ErrorLabel } from '../labels/error'

export interface SelectProps
  extends Omit<
    React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    'size'
  >,
  VariantProps<typeof selectVariants> {
  error?: string
  isTouched?: boolean
}

const selectVariants = cva(
  [
    'form-select px-3 pr-8 border-black/10 dark:border-white/10 text-ellipsis border-2 w-full bg-neutral-50/90 dark:bg-neutral-950/90',
  ],
  {
    variants: {
      size: {
        sm: 'h-9 text-sm',
        base: 'h-10',
        lg: 'h-11',
        xl: 'h-13 py-3',
      },
      brandKit: {
        true: 'rounded-button focus:border-brand focus:ring-brand',
        false: 'rounded-md focus:border-stampede-500 focus:ring-stampede',
      },
    },
    defaultVariants: {
      brandKit: false,
      size: 'base',
    },
  }
)

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  ({ error, isTouched, brandKit, className, size, ...rest }, ref) => {
    const isError = error && isTouched
    const isSuccess = !error && isTouched
    return (
      <div className="relative flex-1">
        <select
          className={cn(
            selectVariants({ brandKit, size, className }),
            isError && 'focus:border-red-500 focus:ring-red-500 border-red-500',
            isSuccess
              ? brandKit
                ? 'focus:ring-green-500 bg-green-50 border-2 border-green-500 text-green-900 focus:border-green-500'
                : 'focus:ring-stampede-500 border-2 border-stampede-500 focus:border-stampede-500'
              : null
          )}
          {...rest}
          ref={ref}
        />

        {isError ? <ErrorLabel error={error} /> : null}
      </div>
    )
  }
)

Select.displayName = 'Select'
