import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/16/solid'
import { ScrollArea } from '@/common/scroll-area'
import {
  addMinutes,
  endOfDay,
  format,
  startOfDay,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'
import { endOfYear, startOfMonth, startOfQuarter, startOfYear } from 'date-fns'
import Litepicker from 'litepicker'
import 'litepicker/dist/plugins/keyboardnav'
import 'litepicker/dist/plugins/ranges'
import { ILPConfiguration } from 'litepicker/dist/types/interfaces'
import { FunctionComponent, PropsWithChildren, useEffect, useRef } from 'react'
import { renderToString } from 'react-dom/server'
import './picker.css'
import { Icn, SButton } from 'tailwind-ui'

const now = new Date()
const endOfNow = endOfDay(now)
const startOfNow = startOfDay(now)
const Next = renderToString(<Icn icon={ArrowRightIcon} />)
const Prev = renderToString(<Icn icon={ArrowLeftIcon} />)

const filterPassedTime = (time: Date) => {
  const currentDate = new Date()
  const selectedDate = new Date(time)

  return addMinutes(currentDate, 30).getTime() > selectedDate.getTime()
}
interface PassedProps {
  dates: Date[]
  onChange: (dates: Date[]) => void
  config?: Partial<ILPConfiguration>
  allowTime?: boolean
  disablePastTimes?: boolean
  isDateDisabled?: (date: Date[]) => boolean
  minDate?: Date
}

const DateLitePicker: FunctionComponent<PropsWithChildren<PassedProps>> = ({
  onChange,
  dates,
  config,
  allowTime,
  disablePastTimes,
  isDateDisabled,
  minDate = null,
}) => {
  const pickerRef = useRef<HTMLDivElement>()
  const pickerClass = useRef<Litepicker>()
  const singleMode = useRef(config?.singleMode ?? false).current
  useEffect(() => {
    if (!pickerClass.current) return
    pickerClass.current.setStartDate(dates[0])
    pickerClass.current.setEndDate(dates[1])
  }, [dates])

  useEffect(() => {
    if (!pickerRef.current || pickerClass.current) return
    pickerClass.current = new Litepicker({
      singleMode: false,
      element: pickerRef.current,
      inlineMode: true,
      numberOfColumns: 2,
      numberOfMonths: 2,
      zIndex: 9,
      startDate: dates[0],
      endDate: dates[1],
      minDate: minDate ?? subYears(new Date(), 4),
      maxDate: now,
      plugins: ['ranges', 'keyboardnav'],
      lockDaysFilter: (date1, date2) => {
        if (!isDateDisabled) {
          return false
        }

        return isDateDisabled(
          [date1, date2]
            .filter((item) => typeof item !== 'boolean')
            .filter((item) => !!item)
            .map((item) => item.toJSDate())
        )
      },
      ranges: {
        customRanges: {
          Today: [startOfNow, endOfNow],
          'Last 7 days': [subDays(startOfNow, 7), endOfNow],
          'Last 14 days': [subDays(startOfNow, 14), endOfNow],
          'Last 30 days': [subDays(startOfNow, 30), endOfNow],
          'Last 3 months': [subMonths(startOfNow, 3), endOfNow],
          'Last 6 months': [subMonths(startOfNow, 6), endOfNow],
          'Last 12 months': [subMonths(startOfNow, 12), endOfNow],
          'Month to date': [startOfMonth(now), endOfNow],
          'Quarter to date': [startOfQuarter(now), endOfNow],
          'Year to date': [startOfYear(now), endOfNow],
          'Last year': [
            subYears(startOfYear(now), 1),
            subYears(endOfYear(endOfNow), 1),
          ],
          'All time': [subYears(now, 5), endOfNow],
        },
      },
      buttonText: {
        apply: 'Apply',
        cancel: 'Cancel',
        reset: '',
        previousMonth: Prev,
        nextMonth: Next,
      },
      ...config,
    })

    pickerClass.current.on('selected', (...date) => {
      if (date.length === 1) {
        onChange(date.map((item) => item.dateInstance as Date))
        return
      }
      onChange(
        date.map((item, index) => {
          if (index === 0) {
            return item.dateInstance as Date
          } else {
            return endOfDay(item.dateInstance)
          }
        })
      )
    })
  }, [config, dates, isDateDisabled, onChange])

  return (
    <div className="flex items-start">
      <div className="flex flex-1 justify-center" ref={pickerRef} />
      {singleMode && allowTime && (
        <ScrollArea className="h-[356px]  border-l">
          <p className="font-bold text-sm text-center pb-6 pt-9">Time</p>

          <div className="flex flex-col">
            {Array(48)
              .fill(startOfDay(dates[0]))
              .map((item: Date, index) => (
                <SButton
                  size="sm"
                  className="!rounded-none px-4"
                  variant={
                    dates[0].getTime() ===
                    addMinutes(item, index * 30).getTime()
                      ? 'primary'
                      : 'ghost_default'
                  }
                  isActive={
                    dates[0].getTime() ===
                    addMinutes(item, index * 30).getTime()
                  }
                  disabled={
                    disablePastTimes &&
                    filterPassedTime(addMinutes(item, index * 30))
                  }
                  key={index}
                  onClick={() => onChange([addMinutes(item, index * 30)])}
                >
                  {format(addMinutes(item, index * 30), 'h:mm aaa')}
                </SButton>
              ))}
          </div>
        </ScrollArea>
      )}
    </div>
  )
}

export default DateLitePicker
