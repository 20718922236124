import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import { MarketingAnalytics } from '@/connect-types/analytics/marketingAnalytics.type'
import { CustomerAnalyticsResponse } from '@/connect-types/analytics/customerAnalytics.type'
import { RegistrationAnalyticsResponseType } from '@/connect-types/analytics/registrationAnalytics.type'
import { ImpressionAnalyticsResponseType } from '@/connect-types/analytics/impressionAnalytics.type'
import { ConnectionAnalyticsResponseType } from '@/connect-types/analytics/connectionAnalytics.type'
import {
  DeviceAnalyticsResponseType,
  DeviceBrowserAnalyticsResponseType,
  DeviceOsAnalyticsResponseType,
} from '@/connect-types/analytics/deviceAnalytics.type'

import {
  OptInResponse,
  OptInMarketingResponse,
} from '@/connect-types/analytics/optInAnalytics.type'
import { PaymentAnalyticsResponseType } from '@/connect-types/analytics/paymentAnalytics.type'
import { MarketingDeliverableAnalyticsResponseType } from '@/connect-types/analytics/marketingDeliverableAnalytics.type'
import { MultisiteAnalytics } from '@/connect-types/analytics/multisiteAnalytics.type'

import * as actions from './analytics.actions'

export type AnalyticsAction = ActionType<typeof actions>

export interface StateType {
  readonly isLoading: boolean
  exportLoading: boolean
  campaign: MarketingAnalytics
  customers: CustomerAnalyticsResponse
  registrations: RegistrationAnalyticsResponseType
  connections: ConnectionAnalyticsResponseType
  payments: PaymentAnalyticsResponseType
  impressions: ImpressionAnalyticsResponseType
  devices: DeviceAnalyticsResponseType
  browsers: DeviceBrowserAnalyticsResponseType
  os: DeviceOsAnalyticsResponseType
  optIn: OptInResponse
  optInMarketing: OptInMarketingResponse

  marketingDeliverables: MarketingDeliverableAnalyticsResponseType
  multisite: MultisiteAnalytics
  [key: string]: any
}

const initialState: StateType = {
  isLoading: false,
  exportLoading: false,
  campaign: {
    totals: {
      sms: 0,
      email: 0,
      sent: 0,
      smsSent: 0,
      emailSent: 0,
      smsDelivered: 0,
      emailDelivered: 0,
      delivered: 0,
      bounce: 0,
      deferred: 0,
      click: 0,
      credits: 0,
      conversion: 0,
      uniqueReturns: 0,
      smsReturns: 0,
      emailReturns: 0,
      charts: null,
      opens: 0,
      roi: 0,
      spendPerHead: 0,
    },
    campaigns: null,
    credits: 0,
  },
  customers: null,
  registrations: null,
  payments: null,
  connections: null,
  impressions: null,
  devices: null,
  browsers: null,
  os: null,
  optIn: null,
  optInMarketing: null,
  story: null,
  storyPage: null,
  marketingDeliverables: null,
  multisite: null,
}

/* tslint:disable cyclomatic-complexity */
const analyticsReducer: Reducer<StateType, AnalyticsAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.getMarketingAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getMarketingAnalyticsSuccess): {
        draft.campaign = action.payload.overview
        draft.isLoading = false
        break
      }
      case getType(actions.getMarketingAnalyticsFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.getCustomerAnalytics): {
        if (action.payload.request.exportData) {
          draft.exportLoading = true
        } else {
          draft.isLoading = true
        }

        break
      }
      case getType(actions.getCustomerAnalyticsSuccess): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false

          if (typeof action.payload.overview === 'object') {
            draft.customers = action.payload.overview
          }
        }

        break
      }
      case getType(actions.getCustomerAnalyticsFailure): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.customers = null
          draft.isLoading = false
        }

        break
      }
      case getType(actions.getRegistrationAnalytics): {
        if (action.payload.request.exportData) {
          draft.exportLoading = true
        } else {
          draft.isLoading = true
        }

        break
      }
      case getType(actions.getRegistrationAnalyticsSuccess): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false

          if (typeof action.payload.overview === 'object') {
            draft.registrations = action.payload.overview
          }
        }

        break
      }
      case getType(actions.getRegistrationAnalyticsFailure): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false
          draft.registrations = null
        }

        break
      }
      case getType(actions.getConnectionAnalytics): {
        if (action.payload.request.exportData) {
          draft.exportLoading = true
        } else {
          draft.isLoading = true
        }

        break
      }
      case getType(actions.getConnectionAnalyticsSuccess): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false

          if (typeof action.payload.overview === 'object') {
            draft.connections = action.payload.overview
          }
        }

        break
      }
      case getType(actions.getConnectionAnalyticsFailure): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false
          draft.connections = null
        }

        break
      }
      case getType(actions.getDeviceAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getDeviceAnalyticsSuccess): {
        draft.isLoading = false

        if (typeof action.payload.overview === 'object') {
          draft.devices = action.payload.overview
        }

        break
      }
      case getType(actions.getDeviceAnalyticsFailure): {
        draft.isLoading = false
        draft.devices = null
        break
      }
      case getType(actions.getOsDeviceAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getOsDeviceAnalyticsSuccess): {
        draft.isLoading = false
        draft.os = action.payload.overview
        break
      }
      case getType(actions.getOsDeviceAnalyticsFailure): {
        draft.isLoading = false
        draft.os = null
        break
      }
      case getType(actions.getBrowserDeviceAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getBrowserDeviceAnalyticsSuccess): {
        draft.isLoading = false
        draft.browsers = action.payload.overview
        break
      }
      case getType(actions.getBrowserDeviceAnalyticsFailure): {
        draft.isLoading = false
        draft.browsers = null
        break
      }
      case getType(actions.getImpressionAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getImpressionAnalyticsSuccess): {
        draft.isLoading = false
        draft.impressions = action.payload.overview

        break
      }
      case getType(actions.getImpressionAnalyticsFailure): {
        draft.isLoading = false
        break
      }
      case getType(actions.getOptinAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getOptinAnalyticsSuccess): {
        draft.isLoading = false
        draft.optIn = action.payload.overview

        break
      }
      case getType(actions.getOptinAnalyticsFailure): {
        draft.isLoading = false
        draft.optIn = null
        break
      }
      case getType(actions.getOptinMarketingAnalytics): {
        draft.isLoading = true
        break
      }
      case getType(actions.getOptinMarketingAnalyticsSuccess): {
        draft.isLoading = false
        draft.optInMarketing = action.payload.overview

        break
      }
      case getType(actions.getOptinMarketingAnalyticsFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.getPaymentAnalytics): {
        if (action.payload.request.exportData) {
          draft.exportLoading = true
        } else {
          draft.isLoading = true
        }

        break
      }

      case getType(actions.getPaymentAnalyticsSuccess): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false
          draft.payments = action.payload.overview
        }

        break
      }

      case getType(actions.getPaymentAnalyticsFailure): {
        if (draft.exportLoading) {
          draft.exportLoading = false
        } else {
          draft.isLoading = false
        }

        break
      }

      case getType(actions.getMarketingDeliverableAnalytics): {
        draft.isLoading = true
        break
      }

      case getType(actions.getMarketingDeliverableAnalyticsSuccess): {
        draft.isLoading = false
        draft.marketingDeliverables = action.payload.overview
        break
      }

      case getType(actions.getMarketingDeliverableAnalyticsFailure): {
        draft.isLoading = false
        break
      }
      case getType(actions.getMultisiteAnalytics): {
        draft.isLoading = true
        break
      }

      case getType(actions.getMultisiteAnalyticsSuccess): {
        draft.isLoading = false
        draft.multisite = action.payload.overview
        break
      }

      case getType(actions.getMultisiteAnalyticsFailure): {
        draft.isLoading = false
        draft.multisite = null
        break
      }
    }
  })

export default analyticsReducer
/* tslint:enable cyclomatic-complexity */
