import { retry, createApi } from '@reduxjs/toolkit/query/react'
import { toast } from 'sonner'
import { type z } from 'zod'
import config from '@/config'
import type { ServicePaginatedResponseType } from '@/connect-types/backend/service'
import { axiosBaseQuery } from '../helpers'
import {
  type SiteSchema,
  type ContentBlockSchema,
  type GallerySchema,
  type ImageSchema,
  type SiteWithContentBlocksSchema,
  type CreateSiteSchema,
  type UpdateContentBlock,
  type CreateContentBlock,
  type ContentBlock,
  type CreateMenuItemSchema,
  type CreateMenuSchema,
  type MenuItemSchema,
  type MenuItemWithRelationsSchema,
  type MenuSchema,
  type MenuWithRelationsSchema,
  type UpdateMenuItemSchema,
  type UpdateMenuSchema,
  type ContentBlockWithRelations,
  type ThemeSchema,
  type SiteWithRelations,
  type CreateImageSchema,
} from './sites.model'

// ... existing code ...

const sites = createApi({
  reducerPath: 'sites',
  baseQuery: retry(
    axiosBaseQuery({ baseUrl: config.url.sites, service: 'sites' }),
    {
      maxRetries: 1,
    }
  ),
  tagTypes: [
    'Sites',
    'ContentBlocks',
    'Galleries',
    'Images',
    'Menus',
    'MenuItems',
    'Theme',
  ],
  endpoints: (build) => ({
    // ... Previous endpoints for sites and content blocks ...

    getSite: build.query<SiteWithRelations, { orgId: string; serial: string }>({
      query: ({ orgId, serial }) => ({
        url: `/api/${orgId}/${serial}`,
        method: 'GET',
      }),
      providesTags: (result, error, { serial }) => [
        { type: 'Sites', id: serial },
      ],
    }),

    createSite: build.mutation<
      z.infer<typeof SiteSchema>,
      { orgId: string; serial: string; data: z.input<typeof CreateSiteSchema> }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Sites', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof SiteSchema>) => {
        toast.success('Site created successfully')
        return response
      },
    }),

    updateSite: build.mutation<
      z.infer<typeof SiteSchema>,
      {
        orgId: string
        serial: string
        data: z.input<typeof SiteSchema>
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { serial }) => [
        { type: 'Sites', id: serial },
      ],
      transformResponse: (response: z.infer<typeof SiteSchema>) => {
        toast.success('Site updated successfully')
        return response
      },
    }),

    deleteSite: build.mutation<void, { orgId: string; serial: string }>({
      query: ({ orgId, serial }) => ({
        url: `/api/${orgId}/${serial}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { serial }) => [
        { type: 'Sites', id: serial },
        { type: 'Sites', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Site deleted successfully')
      },
    }),

    getContentBlocks: build.query<
      ContentBlock[],
      { orgId: string; serial: string }
    >({
      query: ({ orgId, serial }) => ({
        url: `/api/${orgId}/${serial}/content-block`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ContentBlocks' as const,
                id,
              })),
              { type: 'ContentBlocks', id: 'LIST' },
            ]
          : [{ type: 'ContentBlocks', id: 'LIST' }],
    }),

    createContentBlock: build.mutation<
      CreateContentBlock,
      {
        orgId: string
        serial: string
        data: CreateContentBlock
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}/content-block`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'ContentBlocks', id: 'LIST' }],
      transformResponse: (response: CreateContentBlock) => {
        toast.success('Content block created successfully')
        return response
      },
    }),

    updateContentBlocks: build.mutation<
      UpdateContentBlock[],
      {
        orgId: string
        serial: string
        data: UpdateContentBlock[]
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}/content-block`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error) => [
        { type: 'ContentBlocks', id: 'LIST' },
      ],
      transformResponse: (response: UpdateContentBlock[]) => {
        toast.success('Content block order updated successfully')
        return response
      },
    }),

    updateContentBlock: build.mutation<
      UpdateContentBlock,
      {
        orgId: string
        serial: string
        blockId: string
        data: UpdateContentBlock
      }
    >({
      query: ({ orgId, serial, blockId, data }) => ({
        url: `/api/${orgId}/${serial}/content-block/${blockId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { blockId }) => [
        { type: 'ContentBlocks', id: blockId },
      ],
      transformResponse: (response: z.infer<typeof ContentBlockSchema>) => {
        toast.success('Content block updated successfully')
        return response
      },
    }),

    deleteContentBlock: build.mutation<
      void,
      { orgId: string; serial: string; blockId: string }
    >({
      query: ({ orgId, serial, blockId }) => ({
        url: `/api/${orgId}/${serial}/content-block/${blockId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { blockId }) => [
        { type: 'ContentBlocks', id: blockId },
        { type: 'ContentBlocks', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Content block deleted successfully')
      },
    }),
    // New endpoints for galleries
    getGalleries: build.query<
      z.infer<typeof GallerySchema>[],
      { orgId: string; serial: string }
    >({
      query: ({ orgId, serial }) => ({
        url: `/api/${orgId}/${serial}/gallery`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Galleries' as const,
                id,
              })),
              { type: 'Galleries', id: 'LIST' },
            ]
          : [{ type: 'Galleries', id: 'LIST' }],
    }),

    getGallery: build.query<
      z.infer<typeof GallerySchema>,
      { orgId: string; serial: string; galleryId: string }
    >({
      query: ({ orgId, serial, galleryId }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { galleryId }) => [
        { type: 'Galleries', id: galleryId },
      ],
    }),

    createGallery: build.mutation<
      z.infer<typeof GallerySchema>,
      {
        orgId: string
        serial: string
        data: z.input<typeof GallerySchema>
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}/gallery`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Galleries', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof GallerySchema>) => {
        toast.success('Gallery created successfully')
        return response
      },
    }),

    updateGallery: build.mutation<
      z.infer<typeof GallerySchema>,
      {
        orgId: string
        serial: string
        galleryId: string
        data: z.input<typeof GallerySchema>
      }
    >({
      query: ({ orgId, serial, galleryId, data }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { galleryId }) => [
        { type: 'Galleries', id: galleryId },
      ],
      transformResponse: (response: z.infer<typeof GallerySchema>) => {
        toast.success('Gallery updated successfully')
        return response
      },
    }),

    deleteGallery: build.mutation<
      void,
      { orgId: string; serial: string; galleryId: string }
    >({
      query: ({ orgId, serial, galleryId }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { galleryId }) => [
        { type: 'Galleries', id: galleryId },
        { type: 'Galleries', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Gallery deleted successfully')
      },
    }),

    // New endpoints for images within galleries
    getImages: build.query<
      z.infer<typeof ImageSchema>[],
      { orgId: string; serial: string; galleryId: string }
    >({
      query: ({ orgId, serial, galleryId }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}/image`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Images' as const, id })),
              { type: 'Images', id: 'LIST' },
            ]
          : [{ type: 'Images', id: 'LIST' }],
    }),

    getImage: build.query<
      z.infer<typeof ImageSchema>,
      {
        orgId: string
        serial: string
        galleryId: string
        imageId: string
      }
    >({
      query: ({ orgId, serial, galleryId, imageId }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}/image/${imageId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { imageId }) => [
        { type: 'Images', id: imageId },
      ],
    }),

    uploadImage: build.mutation<
      z.infer<typeof ImageSchema>,
      {
        orgId: string
        serial: string
        galleryId: string
        data: { image_url: string }
      }
    >({
      query: ({ orgId, serial, galleryId, data }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}/image`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof ImageSchema>) => {
        toast.success('Image uploaded successfully')
        return response
      },
    }),

    deleteImage: build.mutation<
      void,
      {
        orgId: string
        serial: string
        galleryId: string
        imageId: string
      }
    >({
      query: ({ orgId, serial, galleryId, imageId }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}/image/${imageId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { imageId }) => [
        { type: 'Images', id: imageId },
        { type: 'Images', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Image deleted successfully')
      },
    }),

    getContentBlock: build.query<
      ContentBlockWithRelations,
      { orgId: string; serial: string; blockId: string }
    >({
      query: ({ orgId, serial, blockId }) => ({
        url: `/api/${orgId}/${serial}/content-block/${blockId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { blockId }) => [
        { type: 'ContentBlocks', id: blockId },
      ],
    }),

    // New endpoints for menus
    getMenus: build.query<
      z.infer<typeof MenuSchema>[],
      { orgId: string; serial: string }
    >({
      query: ({ orgId, serial }) => ({
        url: `/api/${orgId}/${serial}/menu`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Menus' as const,
                id,
              })),
              { type: 'Menus', id: 'LIST' },
            ]
          : [{ type: 'Menus', id: 'LIST' }],
    }),

    getMenu: build.query<
      z.infer<typeof MenuWithRelationsSchema>,
      { orgId: string; serial: string; menuId: string }
    >({
      query: ({ orgId, serial, menuId }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { menuId }) => [
        { type: 'Menus', id: menuId },
      ],
    }),

    createMenu: build.mutation<
      z.infer<typeof MenuSchema>,
      {
        orgId: string
        serial: string
        data: z.input<typeof CreateMenuSchema>
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}/menu`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Menus', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof MenuSchema>) => {
        toast.success('Menu created successfully')
        return response
      },
    }),

    updateMenu: build.mutation<
      z.infer<typeof MenuSchema>,
      {
        orgId: string
        serial: string
        menuId: string
        data: z.input<typeof UpdateMenuSchema>
      }
    >({
      query: ({ orgId, serial, menuId, data }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { menuId }) => [
        { type: 'Menus', id: menuId },
      ],
      transformResponse: (response: z.infer<typeof MenuSchema>) => {
        toast.success('Menu updated successfully')
        return response
      },
    }),

    deleteMenu: build.mutation<
      void,
      { orgId: string; serial: string; menuId: string }
    >({
      query: ({ orgId, serial, menuId }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { menuId }) => [
        { type: 'Menus', id: menuId },
        { type: 'Menus', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Menu deleted successfully')
      },
    }),

    // New endpoints for menu items
    getMenuItems: build.query<
      z.infer<typeof MenuItemSchema>[],
      { orgId: string; serial: string; menuId: string }
    >({
      query: ({ orgId, serial, menuId }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'MenuItems' as const,
                id,
              })),
              { type: 'MenuItems', id: 'LIST' },
            ]
          : [{ type: 'MenuItems', id: 'LIST' }],
    }),

    getMenuItem: build.query<
      z.infer<typeof MenuItemWithRelationsSchema>,
      {
        orgId: string
        serial: string
        menuId: string
        itemId: string
      }
    >({
      query: ({ orgId, serial, menuId, itemId }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item/${itemId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { itemId }) => [
        { type: 'MenuItems', id: itemId },
      ],
    }),

    createMenuItem: build.mutation<
      z.infer<typeof MenuItemSchema>,
      {
        orgId: string
        serial: string
        menuId: string
        data: z.input<typeof CreateMenuItemSchema>
      }
    >({
      query: ({ orgId, serial, menuId, data }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'MenuItems', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof MenuItemSchema>) => {
        toast.success('Menu item created successfully')
        return response
      },
    }),

    updateMenuItem: build.mutation<
      z.infer<typeof MenuItemSchema>,
      {
        orgId: string
        serial: string
        menuId: string
        itemId: string
        data: z.input<typeof UpdateMenuItemSchema>
      }
    >({
      query: ({ orgId, serial, menuId, itemId, data }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item/${itemId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { itemId }) => [
        { type: 'MenuItems', id: itemId },
      ],
      transformResponse: (response: z.infer<typeof MenuItemSchema>) => {
        toast.success('Menu item updated successfully')
        return response
      },
    }),

    deleteMenuItem: build.mutation<
      void,
      {
        orgId: string
        serial: string
        menuId: string
        itemId: string
      }
    >({
      query: ({ orgId, serial, menuId, itemId }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item/${itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { itemId }) => [
        { type: 'MenuItems', id: itemId },
        { type: 'MenuItems', id: 'LIST' },
      ],
      transformResponse: () => {
        toast.success('Menu item deleted successfully')
      },
    }),

    updateMenuItems: build.mutation<
      z.infer<typeof MenuItemSchema>[],
      {
        orgId: string
        serial: string
        menuId: string
        data: z.input<typeof UpdateMenuItemSchema>[]
      }
    >({
      query: ({ orgId, serial, menuId, data }) => ({
        url: `/api/${orgId}/${serial}/menu/${menuId}/item`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [{ type: 'MenuItems', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof MenuItemSchema>[]) => {
        toast.success('Menu items updated successfully')
        return response
      },
    }),

    // New endpoint for updating multiple images
    updateImages: build.mutation<
      z.infer<typeof ImageSchema>[],
      {
        orgId: string
        serial: string
        galleryId: string
        data: z.input<typeof ImageSchema>[]
      }
    >({
      query: ({ orgId, serial, galleryId, data }) => ({
        url: `/api/${orgId}/${serial}/gallery/${galleryId}/image`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof ImageSchema>[]) => {
        toast.success('Images updated successfully')
        return response
      },
    }),

    // New endpoint for creating an image in a gallery
    createSingleImage: build.mutation<
      z.infer<typeof CreateImageSchema>,
      {
        orgId: string
        serial: string
        data: z.input<typeof CreateImageSchema>
      }
    >({
      query: ({ orgId, serial, data }) => ({
        url: `/api/${orgId}/${serial}/image`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Images', id: 'LIST' }],
      transformResponse: (response: z.infer<typeof CreateImageSchema>) => {
        toast.success('Image created successfully')
        return response
      },
    }),

    // New endpoint for fetching ThemeSchema
    getThemes: build.query<z.infer<typeof ThemeSchema>, void>({
      query: () => ({
        url: '/api/theme',
        method: 'GET',
      }),
      providesTags: ['Theme'],
    }),

    // ... existing endpoints ...
  }),
})

export default sites
