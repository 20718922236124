import React from 'react'
import { cn } from 'tailwind-ui'

const BUTTON_BASE_CLASS_OVERRIDE = 'rounded-none'

const classNameFromIdx = (idx: number, length: number) => {
  if (idx === 0) {
    return 'rounded-l-md border-r-0'
  }
  if (length - 1 === idx) {
    return 'rounded-r-md border-l-0'
  }
  return ''
}

const renderChildren = (children: React.ReactNode) => {
  const length = React.Children.count(children)

  return React.Children.map(children, (child, idx) => {
    if (!child || typeof child !== 'object' || !('props' in child)) return

    return React.cloneElement(child, {
      type: 'button',
      className: cn(
        child.props.className ?? '',
        BUTTON_BASE_CLASS_OVERRIDE,
        classNameFromIdx(idx, length)
      ),
    })
  })
}

export function ButtonGroup({
  children,
  ...rest
}: { children: React.ReactNode } & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>) {
  return (
    <span
      className={cn(
        'border-black/5 dark:border-white/5 bg-black/25 dark:bg-white/25  border ',
        'isolate inline-flex rounded-full overflow-hidden  [&>*:not(:last-child):not(:first-child)]:-ml-[1px] [&>*]:shadow-none dark:[&>*:not(:last-child)]:border-r-0',
        rest.className
      )}
    >
      {renderChildren(children)}
    </span>
  )
}
