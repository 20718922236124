import morpheusApi from '@/state/morpheus/morpheus.slice'
import type { InteractionDataSourceType } from '@/state/morpheus/types/interaction'
import useGlobal from './useGlobal'

const sourcesApi = morpheusApi.injectEndpoints({
  endpoints: (build) => ({
    getDataSources: build.query<InteractionDataSourceType[], {}>({
      query: () => ({
        method: 'GET',
        url: `/sources/data`,
      }),
    }),
  }),
})

const useDataSources = () => {
  const { orgId } = useGlobal()
  const { data = [] } = sourcesApi.useGetDataSourcesQuery(orgId)

  return data
}

export default useDataSources
