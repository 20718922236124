import { forwardRef } from 'react'
import { checkboxVariants, cn } from '../..'
import type { VariantProps } from 'class-variance-authority'

interface PassedProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size'
    >,
    VariantProps<typeof checkboxVariants> {
  brandKit?: boolean
}

export const SimpleCheck = forwardRef<HTMLInputElement, PassedProps>(
  ({ brandKit, size, className, ...rest }, ref) => {
    return (
      <input
        className={cn(checkboxVariants({ brandKit, size, className }))}
        id={rest.id ?? rest.name}
        type="checkbox"
        {...rest}
        ref={ref}
      />
    )
  }
)

SimpleCheck.displayName = 'SimpleCheck'
