'use client'
import React, { forwardRef } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { ErrorLabel } from '../labels/error'
import { cva, type VariantProps } from 'class-variance-authority'
import {
  ClassValue,
  cn,
  Icn,
  SvgIcon,
  FadeInOut,
  inputVariants,
} from 'tailwind-ui'
import { currency_symbols } from 'types'

const CURRENCIES = ['GBP', 'EUR', 'USD'] as const

interface InputProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size'
    >,
    VariantProps<typeof inputVariants> {
  error?: string | boolean
  isTouched?: boolean
  currency?: (typeof CURRENCIES)[number]
  rightIcon?: SvgIcon

  label?: string
  subClasses?: {
    rightClass?: ClassValue
    leftClass?: ClassValue
  }
}

const GREEN_SUCCESS =
  'focus:ring-green-500 bg-green-50 ring-green-500 border-green-500 text-green-900 placeholder-green-700 focus:border-green-500'
const PRIMARY_SUCCESS =
  'focus:ring-stampede-500 border-stampede-500 focus:border-stampede-500'
const ERROR_CLASS = 'border-red-500'
// focus:border-red-500 focus:ring-red-500

export const PennyInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      isTouched,
      className,
      subClasses,
      brandKit,
      size,
      errorLabel,
      rightIcon,
      label,
      currency = 'GBP',
      ...rest
    },
    ref
  ) => {
    const isError = Boolean(error && isTouched)
    const isSuccess = Boolean(!error && isTouched)

    return (
      <div className="w-full">
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span className="text-gray-500 sm:text-sm">
              {currency_symbols[currency]}
            </span>
          </div>
          <input
            type="number"
            className={cn(
              inputVariants({ brandKit, size, className }),
              isError && ERROR_CLASS,
              isSuccess ? (brandKit ? GREEN_SUCCESS : PRIMARY_SUCCESS) : null,

              rightIcon && 'pr-9',
              className,
              'pl-7'
            )}
            {...rest}
            ref={ref}
          />
          {rightIcon ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <Icn
                size={size === 'xl' ? 'lg' : size}
                className={cn('text-gray-400', subClasses?.rightClass)}
                aria-hidden="true"
                icon={rightIcon}
              />
            </div>
          ) : null}
          <FadeInOut
            show={isSuccess && !rightIcon}
            className={cn(
              'h-5 w-5 rounded-full bg-stampede-500 flex items-center justify-center absolute  right-2 top-1/2 -translate-y-1/2',
              brandKit && 'bg-green-500'
            )}
          >
            <CheckIcon className="h-4 w-4  text-white" />
          </FadeInOut>
          <FadeInOut
            className="h-5 w-5 rounded-full bg-red-500 flex items-center justify-center absolute right-2 top-1/2 -translate-y-1/2"
            show={isError && !rightIcon}
          >
            <span className="h-4 w-4 text-white text-center leading-none">
              !
            </span>
          </FadeInOut>

          {errorLabel && isError && <ErrorLabel error={error} />}
        </div>
        {!errorLabel && isError && (
          <p className="mt-0.5 text-sm text-red-600">{error}</p>
        )}
      </div>
    )
  }
)

PennyInput.displayName = 'PennyInput'
