import { forwardRef, Ref } from 'react'

interface SVGProps extends React.SVGProps<SVGSVGElement> {}
const ChairIcon = forwardRef<SVGSVGElement, SVGProps>(
  (props, ref: Ref<SVGSVGElement>) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      ref={ref}
      {...props}
    >
      <path d="M192 32V224H160V37.5C122.7 50.6 96 86.2 96 128v96H64V128C64 57.3 121.3 0 192 0h64c70.7 0 128 57.3 128 128v96H352V128c0-41.8-26.7-77.4-64-90.5V224H256V32H192zM64 288L32 352l384 0-32-64H64zM35.4 273.7C40.8 262.8 51.9 256 64 256H384c12.1 0 23.2 6.8 28.6 17.7l32 64c5 9.9 4.4 21.7-1.4 31.1s-16.1 15.2-27.2 15.2l0 112c0 8.8-7.2 16-16 16s-16-7.2-16-16V384H64V496c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-112c-11.1 0-21.4-5.7-27.2-15.2s-6.4-21.2-1.4-31.1l32-64z" />
    </svg>
  )
)

export default ChairIcon
