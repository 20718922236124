import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleter, get, post, put } from '@/utils/api'
import {
  LocationPersonalisationCardsType,
  OrganisationPersonalisationCardType,
} from './personalisation.types'

export const getOrganisationPersonalisationCards = createAsyncThunk(
  'GET_ORGANISATION_PERSONALISATION_CARDS',
  async (props: { orgId: string }) => {
    const { orgId } = props

    const response = await get<OrganisationPersonalisationCardType[]>(
      `/organisations/${orgId}/personalisation/cards`
    )
    return response
  }
)

export const updateOrganisationPersonalisationCards = createAsyncThunk(
  'UPDATE_ORGANISATION_PERSONALISATION_CARD',
  async (props: { card: OrganisationPersonalisationCardType }) => {
    const { card } = props

    const response = await put<OrganisationPersonalisationCardType>(
      `/organisations/${card.organization_id}/personalisation/cards`,
      card
    )
    return response
  }
)

export const createOrganisationPersonalisationCards = createAsyncThunk(
  'CREATE_ORGANISATION_PERSONALISATION_CARD',
  async (props: { card: Partial<OrganisationPersonalisationCardType> }) => {
    const { card } = props

    const response = await post<OrganisationPersonalisationCardType>(
      `/organisations/${card.organization_id}/personalisation/cards`,
      card
    )
    return response
  }
)

//localhost:8888/organisations/03126055-361f-11ea-9472-06a4d6597160/personalisation/cards/venue/A0BXTVSH1GE4?card_id=302cf418-9b34-11ed-bde7-0242ac130007

export const getVenuePersonalisationCards = createAsyncThunk(
  'GET_VENUE_PERSONALISATION_CARDS',

  async (props: { orgId: string; serial: string }) => {
    const { orgId, serial } = props

    const response = await get<LocationPersonalisationCardsType[]>(
      `/organisations/${orgId}/personalisation/cards/venue/${serial}`
    )
    return response
  }
)

export const addVenuePersonalisationCards = createAsyncThunk(
  'ADD_VENUE_PERSONALISATION_CARDS',
  async (props: {
    orgId: string
    serial: string
    card: OrganisationPersonalisationCardType
    order_by: number
  }) => {
    const { orgId, serial, order_by, card } = props

    const response = await post<LocationPersonalisationCardsType>(
      `/organisations/${orgId}/personalisation/cards/venue/${serial}`,
      { card_id: card.id, order_by }
    )
    return response
  }
)

export const removeVenuePersonalisationCards = createAsyncThunk(
  'REMOVE_VENUE_PERSONALISATION_CARDS',
  async (props: { orgId: string; serial: string; card_id: string }) => {
    const { orgId, serial, card_id } = props

    await deleter(
      `/organisations/${orgId}/personalisation/cards/venue/${serial}?card_id=${card_id}`
    )
    return { serial, card_id }
  }
)

export const orderVenuePersonalisationCards = createAsyncThunk(
  'ORDER_VENUE_PERSONALISATION_CARDS',
  async (props: {
    orgId: string
    serial: string
    cards: LocationPersonalisationCardsType[]
  }) => {
    const { orgId, serial, cards } = props

    const response = await put<LocationPersonalisationCardsType[]>(
      `/organisations/${orgId}/personalisation/cards/venue/${serial}`,
      cards
    )
    return response
  }
)
