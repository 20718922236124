import { PagenationRequestType } from '@/connect-types/pagination/pagination.type'
import { EndUser } from '@/connect-types/user/user.type'
import { BackendDate } from '../date/date.type'
import { ReviewSettings } from './settings.type'

/** LEGACT */

export interface ReviewQuery {
  serial: string
  startDate: number
  endDate: number
  facebook: boolean
  tripAdvisor: boolean
  blackbx: boolean
  google: boolean
  sort: string
  search: string
  rating: number
}

export interface Review {
  authorName: string
  authorUrl: string | null
  authorProfilePhoto: string | null
  rating: number
  review: string
  reviewType: string
  createdAt: BackendDate
  email: string | null
  first?: string
  last?: string
  id: string
}

export interface Ratings {
  rating: number
  oneStars: number
  twoStars: number
  threeStars: number
  fourStars: number
  fiveStars: number
  ratings: number
}

export interface ReviewRating {
  overallRating: Ratings
}
export const review_sentiments = [
  'POSITIVE',
  'NEUTRAL',
  'MIXED',
  'NEGATIVE',
] as const
export type ReviewSentimentType = (typeof review_sentiments)[number]

export interface ReviewComprehendKeyword {
  sentiment: ReviewSentimentType
  text: string
  occurrences: number
  score: number
}

interface ReviewComprehendSentiment {
  sentiment: ReviewSentimentType
  occurrences: number
  scorePositive: number
  scoreNegative: number
  scoreMixed: number
  scoreNeutral: number
}

export interface ReviewComprehend {
  keywords: ReviewComprehendKeyword[]
  sentiment: ReviewComprehendSentiment[]
}

export interface ReviewContextType {
  id: number
  review: string
  text: string
  rating: number
  createdAt: BackendDate
}

/** END LEGACT */

interface UserReviewSentiment {
  overall: string
  score_positive: number
  score_negative: number
  score_mixed: number
  score_neutral: number
}

interface UserReviewKeyword {
  text: string
  score: number
  id: string
}

export const review_platforms = [
  'stampede',
  'google',
  'facebook',
  'tripadvisor',
  'nearly',
] as const

export type UserReviewPlatforms = (typeof review_platforms)[number]
export interface UserReview {
  id: string
  profile: EndUser
  created_at: BackendDate
  review_settings: ReviewSettings
  rating: number
  review: string
  keywords: UserReviewKeyword[]
  done: boolean
  serial: string | null
  metadata: {
    author_name?: string
    facebook_profile_id?: string
    profile_photo_url?: string
    author_url?: string
  }
  platform: UserReviewPlatforms
  sentiment: UserReviewSentiment
  org_reg_id: string
}

export interface UserReviewQuery extends PagenationRequestType {
  page_id?: string
  review?: string
  rating?: number
  platform?: UserReviewPlatforms
  serial?: string
  sentiment?: ReviewSentimentType
  done?: boolean
  sort?: 'asc' | 'desc'
}
