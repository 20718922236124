import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import config from '@/config'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import { shouldBailout } from '../helpers'

// const parseRoot = (value: Partial<Segment>) => {
//     const sendNode: any = cloneDeep(value)
//     if (!sendNode.segment.root) {
//       return sendNode
//     }
//     if (
//       Array.isArray(sendNode.segment.root.nodes) &&
//       sendNode.segment.root.nodes.length === 1
//     ) {
//       sendNode.segment.root = sendNode.segment.root.nodes[0]
//     }

//     if (
//       Array.isArray(sendNode.segment.root.nodes) &&
//       sendNode.segment.root.nodes.length === 0
//     ) {
//       sendNode.segment.root = null
//     }
//     return sendNode
//   }

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const notificationApi = createApi({
  reducerPath: 'notification',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.notifications }), {
    maxRetries: 2,
  }),
  tagTypes: ['Token'],
  endpoints: (build) => ({
    getBrowserToken: build.query<unknown, { token: string }>({
      query: ({ token }) => ({
        url: `/tokens`,
        method: 'put',
        data: { token, instanceId: 'browser' },
      }),
      providesTags: (result, _error, { token }) => [
        { type: 'Token', id: token },
      ],
    }),
  }),
})

export { notificationApi }
