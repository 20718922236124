import { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { ActionType, getType } from 'typesafe-actions'
import * as actions from './ui.actions'
import { HeaderSettingsType, SecondNavMenuType } from './ui.types'

export type SecondNavActions = ActionType<typeof actions>

export interface StateType {
  secondnav: SecondNavMenuType
  header: HeaderSettingsType
  venuesModalVisible: boolean
  bookingAddModalOpen: boolean

  serviceWorker: {
    updateAvailable: boolean
    registration: ServiceWorkerRegistration
  }
}

const initialState: StateType = {
  secondnav: {
    title: '',
    enabled: false,
    collapsed: false,
    menuItems: [],
    extraItems: [],
    openKeys: [],
    selectedKeys: [],
    statKeys: {},
  },
  header: {
    title: '',
  },
  venuesModalVisible: false,
  bookingAddModalOpen: false,
  serviceWorker: {
    updateAvailable: false,
    registration: null,
  },
}
/* tslint:disable cyclomatic-complexity */
const secondnavReducer: Reducer<StateType, SecondNavActions> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setAddBookingModalVisible): {
        draft.bookingAddModalOpen = action.payload.open
        break
      }

      case getType(actions.setVenuesModalVisible): {
        draft.venuesModalVisible = action.payload.visible

        break
      }
    }
  })

export default secondnavReducer
