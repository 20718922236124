import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import { setQuery } from './global.actions'
import { getQuerySelector } from './global.selectors'
import type { DefaultQueryType } from './global.types'

export const useGlobalQuery = () => {
  const query = useAppSelector(getQuerySelector)

  const dispatch = useAppDispatch()

  const setGlobalQuery = useCallback(
    (q: Partial<DefaultQueryType>) => {
      dispatch(setQuery(q))
    },
    [dispatch]
  )

  return [query, setGlobalQuery] as const
}
