import md5 from 'md5'
import QS from 'query-string'
import { isEmpty } from 'lodash'
import type { ImageTypes } from '@/helpers/image'
import { generateImage } from '@/helpers/image'
import type { CurrencyTypes } from '@/connect-types/billing/organisation.type'

export function isPushNotificationSupported() {
  return 'serviceWorker' in navigator && 'PushManager' in window
}

export const camelSpace = (s: string) =>
  (s = (s || '').replace(/([A-Z])/g, ' $1').trim())

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E']

export const fullName = (s: string) => ({
  first: (s || '').split(' ').slice(0, -1).join(' '),
  last: (s || '').split(' ').slice(-1).join(' '),
})

export function stringOrAbbreviateNumber(number: number, maxString = 99999) {
  if (number <= maxString) return number.toLocaleString()
  return abbreviateNumber(number)
}

export function abbreviateNumber(number: number, fractionDigits?: number) {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0

  // if zero, we don't need a suffix
  if (tier === 0) return number

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier]
  const scale = Math.pow(10, tier * 3)

  // scale the number
  const scaled = number / scale

  // format number and add suffix
  return (
    scaled.toFixed(fractionDigits ?? scaled.toFixed(1).endsWith('0') ? 0 : 1) +
    suffix
  )
}
export function getLast<T>(items: T[]): T {
  if (!items || items.length === 0) return undefined

  return items[items.length - 1]
}

export function getFirst<T>(items: T[]): T | undefined {
  if (!items || items.length === 0) return undefined

  return items[0]
}

/*
 * Encode object as URL params
 */
export function encodeUrlParams(
  params: Record<string, string | boolean | null>
): string {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&')
}

/*
 * Get array from object
 */
export function getArrayFromIdKeyedObject<T>(obj: Record<string, T>) {
  return Object.keys(obj).map((key) => obj[key])
}

/*
 * Get object from array
 */
export function getObjectFromArray<T extends Record<string, any>>(
  arr: T[],
  key: string
) {
  return arr.reduce((acc: Record<string, T>, item: T) => {
    const accKey: string = item[key]

    acc[accKey] = item

    return acc
  }, {})
}

function toHex(int: number) {
  const hex = int.toString(16)

  return hex.length === 1 ? `0${hex}` : hex
}

export function rgb2hex(color = '') {
  const arr: any = []

  if (color.startsWith('#')) return color

  color.replace(/[\d+.]+/g, (v) => arr.push(parseFloat(v)))

  return `#${arr.slice(0, 3).map(toHex).join('')}`
}

const parseBackendUnixTime = (time: number) => {
  if (time.toString().length !== 13) {
    return time
  }
  return Number((time / 1000).toFixed(0))
}

export function buildUrl(url: string, p: any) {
  const parameters = { ...p }
  if ('startDate' in parameters) {
    if (typeof parameters.startDate === 'number') {
      parameters.startDate = parseBackendUnixTime(parameters.startDate)
    }
  }
  if ('endDate' in parameters) {
    parameters.endDate = parseBackendUnixTime(parameters.endDate)
  }
  if ('start' in parameters && parameters.start instanceof Date) {
    parameters.start = parameters.start.toISOString()
  }
  if ('end' in parameters && parameters.end instanceof Date) {
    parameters.end = parameters.end.toISOString()
  }
  const params = QS.stringify(parameters, {
    arrayFormat: 'bracket',
    skipNull: true,
    skipEmptyString: true,
  })

  if (isEmpty(params)) {
    return url
  }

  return `${url}?${params}`
}

export const currency = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2,
})

export const currencySymbol = (curr: null | CurrencyTypes = 'GBP') => {
  switch (curr) {
    case 'GBP':
      return '£'
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
  }
}

export const currencyReplaceRegex = (curr: CurrencyTypes = 'GBP') => {
  switch (curr) {
    case 'GBP':
      return /£\s?|(,*)/g
    case 'EUR':
      return /\\€\s?|(,*)/g
    case 'USD':
      return /\$\s?|(,*)/g
  }
}

export const currencyLocale = (curr: CurrencyTypes = 'GBP') =>
  new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: curr || 'GBP',
    minimumFractionDigits: 2,
  })

export const formatPenny = (
  pennyPrice: number,
  locale: null | CurrencyTypes = 'GBP'
) => currencyLocale(locale).format(pennyPrice / 100)

export const currencyElement = (value: number) => (
  <>
    <span className="currenyUnit">£</span>
    {value.toFixed(2)}
  </>
)

export function formatBytes(bytes: number, decimals = 2, html = true) {
  if (bytes === 0 || !bytes) return html ? <span>0B</span> : '0B '

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  if (html) {
    return (
      <>
        {parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}
        <span className="bytes"> {sizes[i]}</span>
      </>
    )
  }

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const groupBy = (data: any, key: string) =>
  data.reduce((acc: any, item: any) => {
    const i = (acc[item[key]] = acc[item[key]] ?? [])

    i.push(item)

    return acc
  }, {})

export function relDiff(a: number, b: number, round = 2) {
  const num = parseFloat(
    (100 * Math.abs((a - b) / ((a + b) / 2))).toFixed(round)
  )
  if (isFinite(num)) {
    return parseFloat(num.toFixed(round))
  }
  return 0
}

export function percentage(
  firstNumber: number,
  lastNumber: number,
  round = 0
): number {
  const res = parseFloat(((firstNumber / lastNumber) * 100).toFixed(round))

  if (isFinite(res)) {
    return res
  }

  return 0
}

export const gravatarImage = (
  email: string,
  type: keyof ImageTypes = 'avatar'
) => {
  if (!email) return ''

  return generateImage(
    `https://www.gravatar.com/avatar/${md5(email)}?s=150&d=404`,
    type
  )
}

export const validateEmailRegex = (email: string) => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  return re.test(String(email).toLowerCase())
}

export const containsWord = (text: string, searchWord: string) => {
  const searchWordRegex = new RegExp(searchWord, 'i')

  // returns bool
  return searchWordRegex.test(text)
}

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export const uuid4 = () => {
  //// return uuid of form xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
  let uuid = ''
  let ii

  for (ii = 0; ii < 32; ii += 1) {
    switch (ii) {
      case 8:
      case 20:
        uuid += '-'
        uuid += ((Math.random() * 16) | 0).toString(16)
        break
      case 12:
        uuid += '-'
        uuid += '4'
        break
      case 16:
        uuid += '-'
        uuid += ((Math.random() * 4) | 8).toString(16)
        break
      default:
        uuid += ((Math.random() * 16) | 0).toString(16)
    }
  }

  return uuid
}

export function textEllipsis(
  str = '',
  maxLength: number,
  { side = 'end', ellipsis = '...' } = {}
) {
  if (str && str.length > maxLength) {
    switch (side) {
      case 'start':
        return ellipsis + str.slice(-(maxLength - ellipsis.length))
      case 'end':
      default:
        return str.slice(0, maxLength - ellipsis.length) + ellipsis
    }
  }

  return str
}

/**
 * Returns whether a given uuid is valid.
 *
 * @param string
 */
export const validateUuid = (uuid: string) => {
  const regex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regex.test(uuid)
}

export const isValidDate = (object) =>
  object instanceof Date && !isNaN(object?.valueOf())

export const formatNumberWithCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const name = (firstOrLast: string): string => {
  if (!firstOrLast) {
    return ''
  }

  if (typeof firstOrLast !== 'string') {
    return ''
  }

  return firstOrLast.charAt(0).toUpperCase()
}

export const firstAndLast = (first: string, last: string, full: boolean) => {
  if (!full) {
    if (!first && !last) {
      return null
    }

    if (first && !last) {
      return `${name(first)} .`
    }
    if (last && !first) {
      return `. ${name(last)}`
    }
    return `${name(first)} ${name(last)}`
  }
  if (first || last) {
    return `${first || ''} ${last || ''}`
  }
  if (!first && !last) return null
}
