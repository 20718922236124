import { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { ActionType, getType } from 'typesafe-actions'

import * as actions from './loaded.actions'

export type LoadedAction = ActionType<typeof actions>

export interface LoadedType {
  orgId: string
  serial: string
  organisations: boolean
  loaded: {
    websites: boolean
    marketing: boolean
    forms: boolean
    gifting: boolean
    marketing_messages: boolean
    stories: boolean
    reviews: boolean
    review_comprehend: boolean
    review_sources: boolean
    location_settings: boolean
    branding: boolean
    locationSettings: boolean
    unifi_integration: boolean
    unifi_location: boolean
    capture: boolean
    wifi: boolean
    sender_templates: boolean
    filters: boolean
    address: boolean
    locationOther: boolean
    stripe: boolean
    data_sources: boolean
    venue: boolean
    business_types: boolean
    vendors: boolean
    subscription: boolean
    supression_list: boolean
    venue_integrations: boolean
    enabled_integrations: boolean
    organisation_users: boolean
    organisation_progress: boolean
    organisation_settings: boolean
    loyalty: boolean
    loyalty_schemes: boolean
    menus: boolean
    segment_metadata: boolean
    segments: boolean
    facebook_accounts: boolean
    review_reports: boolean
  }
}

const initialState: LoadedType = {
  orgId: null,
  serial: null,
  organisations: false,
  loaded: {
    websites: false,
    marketing: false,
    forms: false,
    gifting: false,
    marketing_messages: false,
    stories: false,
    reviews: false,
    review_comprehend: false,
    review_sources: false,
    location_settings: false,
    branding: false,
    locationSettings: false,
    unifi_integration: false,
    unifi_location: false,
    capture: false,
    wifi: false,
    sender_templates: false,
    filters: false,
    address: false,
    locationOther: false,
    stripe: false,
    venue: false,
    subscription: false,
    supression_list: false,
    venue_integrations: true,
    enabled_integrations: false,
    organisation_users: false,
    /**Global loaded */
    data_sources: false,
    business_types: false,
    vendors: false,
    organisation_progress: false,
    organisation_settings: false,
    loyalty: false,
    loyalty_schemes: false,
    menus: false,
    segment_metadata: false,
    segments: false,
    facebook_accounts: false,
    review_reports: false,
  },
}

const loadingReducer: Reducer<LoadedType, LoadedAction> = (
  state = initialState,
  action
) =>
  produce<LoadedType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setLoadedSerial): {
        draft.serial = action.payload.serial
        draft.loaded.reviews = false
        draft.loaded.stories = false
        draft.loaded.location_settings = false
        draft.loaded.branding = false
        draft.loaded.locationSettings = false
        draft.loaded.capture = false
        draft.loaded.unifi_location = false
        draft.loaded.wifi = false
        draft.loaded.address = false
        draft.loaded.locationOther = false
        draft.loaded.venue = false
        draft.loaded.venue_integrations = false
        draft.loaded.review_comprehend = false
        draft.loaded.review_sources = false
        break
      }
      case getType(actions.setLoadedOrgId): {
        if (draft.orgId === action.payload.orgId) {
          break
        }

        draft.orgId = action.payload.orgId

        draft.loaded = {
          ...initialState.loaded,
          vendors: draft.loaded.vendors,
          data_sources: draft.loaded.data_sources,
          business_types: draft.loaded.business_types,
        }

        break
      }

      case getType(actions.setLoaded): {
        draft.loaded[action.payload.key] = action.payload.value
        break
      }
      case getType(actions.setLoadedOrganisation): {
        draft.organisations = action.payload.value
        break
      }
      case getType(actions.setAllLoaded): {
        draft.loaded = action.payload.loaded
        break
      }
      case getType(actions.clearLoaded): {
        draft.loaded = initialState.loaded
        Object.keys(initialState.loaded).forEach((key) => {
          localStorage.removeItem(key)
        })

        break
      }
    }
  })

export default loadingReducer
