import { cx } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(cx(inputs))
}

export { cx }

export function cnConcat(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// extracted from clsx v: ^2.0.0
export type ClassValue =
  | ClassArray
  | ClassDictionary
  | string
  | number
  | null
  | boolean
  | undefined
export type ClassDictionary = Record<string, any>
export type ClassArray = ClassValue[]
