import { createReducer } from '@reduxjs/toolkit'
import {
  defaultServicePagination,
  ServiceCursorPaginatedResponseType,
} from '@/connect-types/backend/service'
import { EndUser } from '@/connect-types/user/user.type'
import { getUserSearch } from './organisation-user.actions'
import { OrganizationRegistrationType } from './organisation-user.types'

interface InitialStateType {
  user: OrganizationRegistrationType
  user_search: ServiceCursorPaginatedResponseType<
    EndUser & { org_reg_id: string }
  >
  isLoading: boolean
}

const initialState: InitialStateType = {
  user_search: defaultServicePagination,
  user: null,
  isLoading: false,
}

const reducer = createReducer(initialState, (builder) =>
  builder

    .addCase(getUserSearch.pending, (state) => {
      state.user_search.isLoading = true
    })
    .addCase(getUserSearch.fulfilled, (state, action) => {
      state.user_search = action.payload
    })
    .addCase(getUserSearch.rejected, (state) => {
      state.user_search = defaultServicePagination
    })
)

export default reducer
