import morpheusApi from '@/state/morpheus/morpheus.slice'
import useGlobal from './useGlobal'
import { useLocation } from 'react-router-dom'

const useUser = () => {
  const { pathname } = useLocation()
  const user = morpheusApi.useGetUserQuery('me', {
    refetchOnFocus: true,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,

    skip:
      pathname.includes('oauth') ||
      pathname === '/code' ||
      pathname.includes('account-invite'),
  })

  return user.data
}

export const useUserRole = () => {
  const { orgId } = useGlobal()
  const uid = useUser()?.uid
  const { data } = morpheusApi.useGetOrganisationUserRoleQuery(
    {
      orgId,
      uid,
    },
    {
      skip: !uid,
    }
  )

  return data
}

export default useUser
