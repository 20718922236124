import { toast } from 'sonner'

const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')

  el.value = str
  document.body.appendChild(el)
  el.select()
  navigator.clipboard.writeText(el.value)
  document.body.removeChild(el)

  toast(`${str} copied to clipboard`)
}

export { copyToClipboard }
