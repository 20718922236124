import type { GalleryGetResponse } from '@/connect-types/members/galleryGetResponse.type'
import { deleter, get, post, put as putRequest } from '@/utils/api'
import { buildUrl } from '@/utils/common'
import { call, put, takeLatest } from 'redux-saga/effects'
import type { ActionType } from 'typesafe-actions'
import { getType } from 'typesafe-actions'
import * as actions from './member.actions'

/* Get Members Gallery
 * ========================================================================== */
function* membersGalleryRequest(
  action: ActionType<typeof actions.membersGalleryContentRequest>
) {
  try {
    const { orgId, path, kind, offset, label_like, name_like } = action.payload
    const url = buildUrl(`/organisations/${orgId}/gallery`, {
      offset,
      kind,
      path,
      limit: 50,
      label_like,
      name_like,
    })

    const response: GalleryGetResponse = yield call(async () => get(url))

    yield put(actions.membersGalleryContentRequestSuccess(response, kind, path))
  } catch (error) {
    yield put(actions.membersGalleryContentRequestFailure(error.response))
  }
}

/* Delete Members Gallery Image
 * ========================================================================== */
function* deleteMembersGalleryRequest(
  action: ActionType<typeof actions.deleteMembersGalleryContentRequest>
) {
  try {
    const { orgId, id } = action.payload
    yield call(async () => deleter(`/organisations/${orgId}/gallery/${id}`))

    yield put(actions.deleteMembersGalleryContentRequestSuccess(id))
  } catch (error) {
    yield put(actions.deleteMembersGalleryContentRequestFailure(error.response))
  }
}

/* Send email to support
 * ========================================================================== */
function* sendSupportEmailRequest(
  action: ActionType<typeof actions.sendSupportEmailRequest>
) {
  try {
    const { request } = action.payload
    const response: {
      status: number
      message: any
    } = yield call(async () =>
      post(`/members/me/subscriptions/cancellation-requests`, request)
    )

    yield put(actions.sendSupportEmailRequestSuccess(response))
  } catch (error) {
    yield put(actions.sendSupportEmailRequestFailure())
  }
}

export function* watchMemberRequest() {
  yield takeLatest(
    getType(actions.membersGalleryContentRequest),
    membersGalleryRequest
  )
  yield takeLatest(
    getType(actions.deleteMembersGalleryContentRequest),
    deleteMembersGalleryRequest
  )

  yield takeLatest(
    getType(actions.sendSupportEmailRequest),
    sendSupportEmailRequest
  )
}

export default [watchMemberRequest]
