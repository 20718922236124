import { type ReactNode } from 'react'
import { toast } from 'sonner'

interface MessageSpec {
  description?: string | ReactNode
  duration?: number
  isClosable?: boolean
}

const message = {
  error: (title: string, { description, duration }: MessageSpec = {}) =>
    toast.error(title, {
      description,
      duration: duration ?? 3000,
      closeButton: true,
    }),
  success: (title: string, { description, duration }: MessageSpec = {}) =>
    toast.success(title, {
      description,
      duration: duration ?? 3000,
      closeButton: true,
    }),
  warning: (title: string, { description, duration }: MessageSpec = {}) =>
    toast.warning(title, {
      description,
      duration: duration ?? 3000,
      closeButton: true,
    }),
  info: (title: string, { description, duration }: MessageSpec = {}) =>
    toast.info(title, {
      description,
      duration: duration ?? 3000,
      closeButton: true,
    }),
  loading: (title: string, { description, duration }: MessageSpec = {}) =>
    toast.loading(title, {
      description,
      duration: duration ?? 3000,
      closeButton: true,
    }),
}

export default message
