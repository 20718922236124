// TODO: re-implement when required
import { fork, all } from 'redux-saga/effects'

import auth from './auth/auth.saga'
import monitor from './monitor.saga'
import settings from './entities/settings/settings.saga'

import members from './entities/member/member.saga'
import billing from './entities/billing/billing.saga'
import analytics from './entities/analytics/analytics.saga'
import integrations from './entities/integration/integration.saga'
import organisations from './entities/organisations/organisations.saga'

import global from './global/global.saga'

const sagas = [
  ...monitor,
  ...global,
  ...auth,
  ...settings,
  ...members,
  ...billing,
  ...analytics,
  ...integrations,
  ...organisations,
]

function* rootSaga() {
  yield all(sagas.map(fork))
}

export default rootSaga
