import { StandardAnalyticsCard } from '@/common/Card/StandardAnalyticsCard'
import FieldGroup from '@/common/Form/FieldGroup'
import NoData, { hasArrayData } from '@/common/NoData/NoData'
import DatePickerSelector from '@/common/datepicker/selector'
import { renderTooltip } from '@/components/AnalyticsPage/ChartComponent'
import PageHeader from '@/components/Layout/page-header'
import type {
  GiftStatusType,
  GiftingCurrencyTotal,
} from '@/connect-types/analytics/giftingAnalytics.type'
import useAnalyticsQuery from '@/hooks/useAnalyticsQuery'
import useGlobal from '@/hooks/useGlobal'
import ContentContainer from '@/layouts/content/container'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { Box, Button, SimpleGrid, Skeleton } from '@chakra-ui/react'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

export const COLORS: Record<GiftStatusType, string> = {
  active: '#00D78F',
  redeemed: '#E4B400',
  unpaid: '#FF005E',
  refunded: '#395AFF',
}

const pieArray = (data: GiftingCurrencyTotal) => {
  const manual = data.manual
    ? {
        value: Number(data.manual?.total_amount ?? 0),
        name: data.manual?.name,
        formatted: data.manual?.formatted_amount,
        formattedAvg: data.manual?.formatted_average,
        total: data.manual?.count,
      }
    : null

  return [
    {
      value: Number(data.active.total_amount),
      name: data.active.name,
      formatted: data.active.formatted_amount,
      formattedAvg: data.active.formatted_average,
      total: data.active.count,
    },
    {
      value: Number(data.redeemed.total_amount),
      name: data.redeemed.name,
      formatted: data.redeemed.formatted_amount,
      formattedAvg: data.redeemed.formatted_average,
      total: data.redeemed.count,
    },
    {
      value: Number(data.unpaid.total_amount),
      name: data.unpaid.name,
      formatted: data.unpaid.formatted_amount,
      formattedAvg: data.unpaid.formatted_average,
      total: data.unpaid.count,
    },
    {
      value: Number(data.refunded.total_amount ?? 0),
      name: data.refunded.name,
      formatted: data.refunded.formatted_amount,
      formattedAvg: data.refunded.formatted_average,
      total: data.refunded.count,
    },
  ].concat(manual ? manual : [])
}

export const secondsEpoch = (epoch_number: number) => {
  return Math.abs(epoch_number).toString().length > 10
    ? Math.floor(epoch_number / 1000)
    : epoch_number
}

function GiftingReport() {
  const { orgId } = useGlobal()
  const { query } = useAnalyticsQuery()

  const { data, isLoading } = morpheusApi.useGetGiftReportsQuery({
    orgId,
    start: new Date(query.start),
    end: new Date(query.end),
  })

  const analytics = useMemo(
    () => Object.keys(data || {}).map((k) => data[k]),
    [data]
  )

  return (
    <ContentContainer>
      <PageHeader center={<DatePickerSelector />} onBack title="Gifting" />

      {hasArrayData(analytics) ? (
        <>
          {analytics.map((gift) => (
            <FieldGroup
              extra={
                gift.name !== 'All Cards' ? (
                  <Button
                    as={Link}
                    key="act"
                    to={`../${gift.id}`}
                    variant="link"
                  >
                    Setup
                  </Button>
                ) : (
                  <></>
                )
              }
              isOnlyColumn
              key={gift.id}
              title={gift.name}
            >
              {gift.totals ? (
                <SimpleGrid columns={2} spacing={3} w="full">
                  <Box>
                    {pieArray(gift.totals).map((status, i) => (
                      <SimpleGrid columns={2} key={i} spacing={3}>
                        {!isLoading && (
                          <>
                            <StandardAnalyticsCard
                              color={COLORS[status.name as keyof typeof COLORS]}
                              stat={status.total}
                              title={`${status.name} Cards`}
                            />

                            <StandardAnalyticsCard
                              color={COLORS[status.name as keyof typeof COLORS]}
                              stat={status.formatted ?? '£0'}
                              substat={status.formattedAvg ?? '£0'}
                              subtext="Avg value per card"
                              title={`${status.name} Cards Value`}
                            />
                          </>
                        )}

                        {isLoading ? (
                          <>
                            <Skeleton borderRadius="md" h="84px" mb={3} />
                            <Skeleton borderRadius="md" h="84px" mb={3} />
                          </>
                        ) : null}
                      </SimpleGrid>
                    ))}
                  </Box>
                  {!isLoading && (
                    <ResponsiveContainer height="100%" width="99%">
                      <PieChart>
                        <Pie
                          cx="50%"
                          cy="50%"
                          data={pieArray(gift.totals).filter(
                            (i) => i.value > 0 && i.name !== 'manual'
                          )}
                          dataKey="value"
                          fill="#8884d8"
                          innerRadius={40}
                          isAnimationActive
                          label={(data: any) => `${data.formatted || ''}`}
                          labelLine={!!data.formatted}
                          outerRadius={80}
                        >
                          {pieArray(gift.totals)
                            .filter((i) => i.value > 0 && i.name !== 'manual')
                            .map((entry, index) => {
                              return (
                                <Cell
                                  fill={
                                    COLORS[entry.name as keyof typeof COLORS]
                                  }
                                  key={`cell-${index}`}
                                />
                              )
                            })}
                        </Pie>
                        <Tooltip
                          content={(data: any) => renderTooltip(data, null)}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  )}
                  {isLoading ? (
                    <Skeleton borderRadius="md" h="100%" mb={3} />
                  ) : null}
                </SimpleGrid>
              ) : null}
            </FieldGroup>
          ))}
        </>
      ) : (
        <NoData />
      )}
    </ContentContainer>
  )
}

export default GiftingReport
