import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  CloseButton,
  Container,
  HStack,
  Slide,
  Stack,
  Text,
  Icon,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { WifiIcon, XMarkIcon } from '@heroicons/react/16/solid'
import { useEffect, useRef, useState } from 'react'
import { useAppSelector } from '@/state/helpers/useApp'
import { isInStandaloneMode } from '@/utils/helpers/pwa'

function Offline() {
  const isOnline = useAppSelector(({ offline }) => {
    if (offline.netInfo?.reach === 'NONE' && !offline.online) {
      return true
    }
    return offline.online as boolean
  })

  const cancelRef = useRef<any>()
  const isStandalone = isInStandaloneMode()
  const [shadowIsOnline, setShadowIsOnline] = useState(isOnline)

  useEffect(() => {
    setShadowIsOnline(isOnline)
  }, [isOnline])

  if (shadowIsOnline) return <></>

  return (
    <>
      {isStandalone ? (
        <Slide direction="bottom" in={!shadowIsOnline} style={{ zIndex: 10 }}>
          <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-4 lg:px-8">
            <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
              <p className="text-sm leading-6 text-white">
                You have just gone offline, experience maybe limited
              </p>
              <button
                type="button"
                className="-m-1.5 flex-none px-2"
                onClick={() => {
                  setShadowIsOnline(true)
                }}
              >
                <span className="sr-only">Dismiss</span>
                <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </button>
            </div>
          </div>
        </Slide>
      ) : null}
      {!isStandalone && (
        <AlertDialog
          motionPreset="slideInBottom"
          leastDestructiveRef={cancelRef}
          onClose={() => undefined}
          closeOnOverlayClick={false}
          isOpen={!shadowIsOnline}
          isCentered
        >
          <AlertDialogOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />

          <AlertDialogContent zIndex={10000}>
            <HStack px={8}>
              <Icon fontSize="60px" color="red" as={WifiIcon} />
              <Box>
                <AlertDialogBody py={7}>
                  <Stack spacing={3}>
                    <Text fontSize="md" fontWeight="bold">
                      Network disconnected
                    </Text>

                    <Text>
                      You have been disconnected from the network, please check
                      the devices connectivity settings.
                    </Text>
                  </Stack>
                </AlertDialogBody>
              </Box>
            </HStack>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  )
}

export default Offline
