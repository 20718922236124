import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react'
import { SIconButton, cx } from 'tailwind-ui'
import { XMarkIcon } from '@heroicons/react/16/solid'
import { useInView } from 'react-intersection-observer'
import { useHideZendesk } from '@/helpers/zendesk'

function Drawer({
  open = true,
  setOpen,
  children,
  width = 'w-full lg:max-w-7xl',
  title = '',
  classNames = {
    container: 'lg:max-w-7xl',
  },
}: {
  open: boolean
  setOpen: (isOpen: boolean) => void
  children: React.ReactNode
  width?: string
  title: string
  classNames?: {
    container: string
  }
}) {
  const handleDrawerClick = (e: React.MouseEvent) => {
    e.stopPropagation()
  }
  const [inViewRef, isInView] = useInView()
  useHideZendesk(isInView)

  return (
    <Dialog open={open} onClose={setOpen} transition className="relative z-50">
      <DialogBackdrop
        className="fixed inset-0 duration-300 ease-out data-[closed]:opacity-0 bg-white/50 dark:bg-black/50 backdrop-blur-sm"
        transition
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={cx(
              'pointer-events-none fixed inset-y-0 right-0 flex max-w-full lg:pl-16',
              classNames.container
            )}
          >
            <DialogPanel
              transition
              className={`flex flex-col pointer-events-auto w-screen shadow-md ${width} bg-white dark:bg-black lg:border-l border-black/10 dark:border-white/10 duration-300 ease-out data-[closed]:translate-x-full`}
              ref={inViewRef}
            >
              <div className="border-b border-black/10 dark:border-white/10 flex px-4 py-1.5 min-h-[54px] justify-between items-center bg-white dark:bg-black">
                <SIconButton
                  icon={XMarkIcon}
                  variant="ghost_default"
                  type="button"
                  onClick={() => {
                    setOpen(false)
                  }}
                />
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold capitalize leading-6 text-gray-900 dark:text-gray-50"
                >
                  {title}
                </DialogTitle>
                <div className="w-[40px]" />
              </div>
              <div className="flex-1 overflow-x-scroll overflow-y-scroll">
                {children}
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default Drawer
