import type {
  BaseQueryFn,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import {
  ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
  ServicePaginatedResponseType,
} from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import config from '@/config'
import { buildUrl } from '@/utils/common'
import { shouldBailout } from '../helpers'
import { type DesignMyNightIntegration } from './types'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      let axiosUrl = baseUrl + url
      if (url.startsWith('https://')) {
        axiosUrl = url
      }

      const result = await customAxios({
        url: axiosUrl,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      console.log(err, 'error-here')

      if (
        method !== 'get' &&
        err.response?.status !== 401 &&
        err.response?.status !== 404 &&
        !url.includes('segment') &&
        !url.includes('oauth')
      ) {
        ServiceErrorHandler(err)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

export const convertKeysToSnake = (item: Record<string, unknown>) => {
  const newObject = {}
  function camelToUnderscore(key: string) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
  }
  for (const camel in item) {
    // @ts-ignore
    newObject[camelToUnderscore(camel)] = item[camel]
  }
  return newObject
}

const agentSmithApi = createApi({
  reducerPath: 'agent-smith',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.agent_smith }), {
    maxRetries: 2,
  }),
  tagTypes: ['DesignMyNight'],
  endpoints: (build) => ({
    getIntegrationSettings: build.query<
      DesignMyNightIntegration,
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/design-my-night`,
        method: 'get',
      }),
      providesTags: (result, _error, { orgId }) => [
        { type: 'DesignMyNight', id: orgId },
      ],
    }),
    upsertIntegrationSettings: build.mutation<
      DesignMyNightIntegration,
      { orgId: string; data: DesignMyNightIntegration }
    >({
      query: ({ orgId, data }) => ({
        url: `/${orgId}/design-my-night`,
        method: 'put',
        data,
      }),
      invalidatesTags: (results) => [
        { type: 'DesignMyNight', id: results?.org_id },
      ],
    }),
  }),
})

export default agentSmithApi
