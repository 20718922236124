import orderBy from 'lodash/orderBy'
import { type CSSProperties, useMemo } from 'react'
import { Select } from 'tailwind-ui'
import useLocations from '@/state/entities/locations/hooks/useLocation'
import { type OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import BookingSelect from '@/pages/[org_id]/venues/[serial]/bookings/components/booking-select'
import Selector from './Select'

interface PassedProps {
  value: string | string[]
  onChange: (value: string | string[]) => void
  mode?: 'multiple' | 'tags'
  style?: CSSProperties
  reset?: boolean
  resetText?: string
  size?: 'sm' | 'md' | 'lg'
  altLocations?: OrganisationLocationType[]
}

function LocationSelect({
  value,
  onChange,
  mode,
  size,
  reset,
  resetText,
  altLocations,
}: PassedProps) {
  const locations = useLocations()

  const options = useMemo(
    () => orderBy(altLocations || locations, ['alias'], ['asc']),
    [locations, altLocations]
  )

  const selectOpts = [
    {
      value: null,
      label: resetText ?? 'No venue selected',
    },
    ...options.map(({ serial, alias }) => ({
      label: alias || serial,
      value: serial,
    })),
  ]

  if (mode === 'tags') {
    return (
      <Select
        style={{ width: '100%' }}
        value={value}
        onChange={({ target }) => {
          if (target.value === resetText || !target.value) {
            onChange(null)
            return
          }
          onChange(target.value)
        }}
      >
        {reset ? (
          <option value={undefined}>
            {resetText ? resetText : 'No venue selected'}
          </option>
        ) : null}
        {options.map(({ alias, serial }) => (
          <option value={serial} key={serial}>
            {alias || serial}
          </option>
        ))}
      </Select>
    )
  }

  const selValue = useMemo(() => {
    if (!value) return null
    const items = selectOpts.filter((item) => value.includes(item.value))
    if (items.length === 0) {
      return null
    }
    return items
  }, [selectOpts, value])

  return (
    <BookingSelect
      isMultiple
      value={selValue}
      onChange={(v) => {
        onChange(v ? (v.map((item) => item.value) as string[]) : [])
      }}
      placeholder={resetText}
      options={selectOpts}
    />
  )
}

export default LocationSelect
