import type { BrandKitType } from 'types'
import { invert, rgb2hex } from './helpers/pallet'
function addAlpha(color: string = '', opacity: number = 0) {
  // coerce values so it is between 0 and 1.
  const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}
export function BrandKitStyles({
  brand,
  ignoreBody = false,
}: {
  brand: BrandKitType
  ignoreBody?: boolean
}) {
  const isBackgroundTransparent =
    (brand?.background ?? 'transparent') === 'transparent'

  if (!brand) {
    return null
  }

  return (
    <style>
      {`:root {
        --background-image: ${brand.backgroundImage};
        --brand-color: ${brand.interfaceColor ?? '#ff3a9b'};
        --background-color: ${brand.background};
        --background-invert-color: ${invert(brand.background ?? '#ffffff', true)};
        --brand-invert-color: ${invert(brand.interfaceColor ?? '#ff3a9b')};
        --brand-or-black: ${invert(brand.interfaceColor ?? '#ffffff', true) === '#000000' ? '#000000' : brand.interfaceColor};
        --body-font-family: ${brand.body_font_family};
        --heading-font-family: ${brand.heading_font_family};
        --brand-button-border-radius: ${
          brand.roundInputs ? '9999px' : '0.125rem'
        };
        --brand-border-radius: ${brand.roundInputs ? '1rem' : '0px'};
        --label-color: ${!ignoreBody ? addAlpha(invert(brand?.background ?? '#ffffff', true), 0.5) : 'inherit'};
   
      }
${
  brand.interface_pallet &&
  `

:root {
  --interface-color-50: ${brand.interface_pallet[50]};
  --interface-color-100: ${brand.interface_pallet[100]};
  --interface-color-200: ${brand.interface_pallet[200]};
  --interface-color-300: ${brand.interface_pallet[300]};
  --interface-color-400: ${brand.interface_pallet[400]};
  --interface-color-500: ${brand.interface_pallet[500]};
  --interface-color-600: ${brand.interface_pallet[600]};
  --interface-color-700: ${brand.interface_pallet[700]};
  --interface-color-800: ${brand.interface_pallet[800]};
  --interface-color-900: ${brand.interface_pallet[900]};
  --interface-color-default: ${brand.interface_pallet.DEFAULT};
}`
}

${
  brand.background_pallet &&
  `

:root {
  --background-color-50: ${brand.background_pallet[50]};
  --background-color-100: ${brand.background_pallet[100]};
  --background-color-200: ${brand.background_pallet[200]};
  --background-color-300: ${brand.background_pallet[300]};
  --background-color-400: ${brand.background_pallet[400]};
  --background-color-500: ${brand.background_pallet[500]};
  --background-color-600: ${brand.background_pallet[600]};
  --background-color-700: ${brand.background_pallet[700]};
  --background-color-800: ${brand.background_pallet[800]};
  --background-color-900: ${brand.background_pallet[900]};
  --background-color-default: ${brand.background_pallet.DEFAULT};
}`
}

${
  !ignoreBody &&
  `body:not(.in-frame) {
    background-color: ${brand.background};
  } 

  @media only screen and (max-width: 768px) {
${
  isBackgroundTransparent &&
  `:root {
  --background-color: #ffffff;
}`
}

${
  !ignoreBody &&
  `
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h1,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h2,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h3,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h4,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h5,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) h6,
body:not(.in-frame):not(.in-frame-adam-ignore-unbarred) .font-heading {
      color: var( --background-invert-color);
         }
         *,:after,:before {
          border: 0 solid ${addAlpha(invert(brand?.background ?? '#ffffff', true), 0.15)};
         }
      `
}

  }
  
  body.in-frame h1,
  body.in-frame h2,
  body.in-frame h3,
  body.in-frame h4,
  body.in-frame h5,
  body.in-frame h6,
  body.in-frame .font-heading  {
    color: var( --background-invert-color);
  }
  body.in-frame *,
  body.in-frame  :after,
  body.in-frame  :before {
   // border: 0 solid ${addAlpha(invert(brand?.background ?? '#ffffff', true), 0.3)};
  }
  `
}

.in-frame .grid-rows-\[50vh_1fr\], .in-frame .grid-rows-\[40vh_1fr\] {
  grid-template-rows: auto;
}
      `}
    </style>
  )
}

/**
 *   html, body {
       // font-family: var(--body-font-family);
      }
      h1, h2, h3, h4, h5, h6 {
       // font-family: var(--heading-font-family);
      }
 */
