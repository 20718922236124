import {
  UserIcon,
  EnvelopeIcon,
  CalendarIcon,
  WifiIcon,
  WalletIcon,
  StarIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/16/solid'
import { useMemo } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import {
  defaultServicePagination,
  type ServiceCursorPaginatedResponseType,
} from '@/connect-types/backend/service'
import useGlobal from '@/hooks/useGlobal'
import TabbedNavLayout, { type LinkType } from '@/layouts/navigation/tabbed-nav'
import type { OrganizationUserGiftType } from '@/state/entities/organisation-user/organisation-user.types'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import type { InteractionType } from '@/state/morpheus/types/interaction'
import type { OrganizationRegistration } from '@/state/morpheus/types/organization_registration'
import type { ReviewResponseType } from '@/state/morpheus/types/reviews'
import type {
  OrgProfileTagTableType,
  ProfileMarketingEventType,
  ProfileTagsType,
  ProfileTransactionType,
} from '@/state/morpheus/types/segments/customers'
import wifi from '@/state/wifi/wifi.slice'
import { type Booking } from '@/state/zion/types'
import zionApi from '@/state/zion/zion.slice'
import { buildUrl, getFirst } from '@/utils/common'
import serviceApi from '@/state/service/service.slice'
import { type CustomQuestionAnswer } from '@/types/customer'

interface CustomerCountsType {
  _count: {
    custom_question_answers: number
    gift_card: number
    // organization_profile_marketing_events: number
    organization_profile_tags: number
    organization_profile_transaction: number
    organization_registration_personalisation_choice: number
    organization_utm_conversion: number
    user_booking: number
    user_review: number
    // website_trackable_profiles: number
  }
}

interface CustomerOverviewType {
  created_at: string
  custom_question_answers: CustomQuestionAnswer[]
  data_opt_in_at: string | null
  email_opt_in_at: string | null
  id: string
  last_interacted_at: string | null
  organization_id: string
  organization_profile_tags: {
    id: string
    organization_tag: {
      name: string
    }
    tag_id: string
  }[]

  organization_registration_personalisation_choice: {
    choice: number
    created_at: string
    organization_personalisation_cards: {
      id: string
      image: string
      question: string
    }
  }[]
  profile_id: number
  sms_opt_in_at: string | null
  user_profile: {
    email: string
    first: string
    id: number
    last: string
    lat: number | null
    lng: number | null
    phone: string
    birth_month: number | null
    birth_day: number | null
    postcode: string | null
    verified: 1 | 0 | null
  }

  visits: {
    _max: {
      last_interacted_at: string
    }
    _sum: {
      interactions: number
    }
  }
  transactions: {
    _sum: {
      penny_value: number
    }
  }
}
interface SimpleQueryType {
  limit: number
  cursor: string
}
const morpheusApiwithTags = morpheusApi.enhanceEndpoints({
  addTagTypes: ['CustomerQuery'],
})

export const customerApi = morpheusApiwithTags.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<
      ServiceCursorPaginatedResponseType<OrganizationRegistration>,
      {
        orgId: string
        search: string
        query: { limit: number; cursor: string; serial: string; tag_id: string }
      }
    >({
      query: ({ orgId, search, query }) => ({
        method: 'GET',
        url: buildUrl(`/organisations/${orgId}/crm/customers`, {
          search,
          ...query,
        }),
      }),
    }),
    getOrgRegUsingProfileId: build.query<
      OrganizationRegistration,
      { orgId: string; profileId: number }
    >({
      query: ({ orgId, profileId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/profile/${profileId}`,
      }),
    }),
    getCustomer: build.query<
      CustomerOverviewType,
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/${customerId}`,
      }),
      providesTags: (data) =>
        data ? [{ type: 'CustomerQuery', id: data?.id }] : [],
    }),
    getCustomerCounts: build.query<
      CustomerCountsType,
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/${customerId}/count`,
      }),
    }),
    getCustomerGifts: build.query<
      ServiceCursorPaginatedResponseType<OrganizationUserGiftType>,
      { orgId: string; customerId: string; query: SimpleQueryType }
    >({
      query: ({ orgId, customerId, query }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/crm/customers/${customerId}/gift`,
          query
        ),
      }),
    }),
    getCustomerReviews: build.query<
      ServiceCursorPaginatedResponseType<ReviewResponseType>,
      { orgId: string; customerId: string; query: SimpleQueryType }
    >({
      query: ({ orgId, customerId, query }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/crm/customers/${customerId}/reviews`,
          query
        ),
      }),
    }),
    getCustomerMarketing: build.query<
      ServiceCursorPaginatedResponseType<ProfileMarketingEventType>,
      { orgId: string; customerId: string; query: SimpleQueryType }
    >({
      query: ({ orgId, customerId, query }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/crm/customers/${customerId}/marketing`,
          query
        ),
      }),
    }),
    getCustomerTransactions: build.query<
      ServiceCursorPaginatedResponseType<ProfileTransactionType>,
      { orgId: string; customerId: string; query: SimpleQueryType }
    >({
      query: ({ orgId, customerId, query }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/crm/customers/${customerId}/transactions`,
          query
        ),
      }),
    }),
    getCustomerActivity: build.query<
      ServiceCursorPaginatedResponseType<InteractionType>,
      { orgId: string; customerId: string; query: SimpleQueryType }
    >({
      query: ({ orgId, customerId, query }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/crm/customers/${customerId}/activity`,
          query
        ),
      }),
    }),
    getCustomerMostRecent: build.query<
      (
        | ProfileMarketingEventType
        | ReviewResponseType
        | OrganizationUserGiftType
        | Booking
      )[],
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/${customerId}/activity/recent`,
      }),
    }),
    getCustomerRoi: build.query<
      { data: { month: string; amount: number }[]; currency: string },
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/${customerId}/activity/roi`,
      }),
    }),
    getCustomerTags: build.query<
      ServiceCursorPaginatedResponseType<ProfileTagsType>,
      { orgId: string; customerId: string }
    >({
      query: ({ orgId, customerId }) => ({
        method: 'GET',
        url: `/organisations/${orgId}/crm/customers/${customerId}/tags`,
      }),
    }),

    addTagToCustomer: build.mutation<
      ProfileTagsType[],
      { orgId: string; tag: { name: string }; customerIds: string[] }
    >({
      query: ({ orgId, tag, customerIds }) => ({
        url: `/organisations/${orgId}/crm/customers/${getFirst(customerIds)}/tags`,
        method: 'put',
        data: { ...tag, customerIds },
      }),
      invalidatesTags: (_1, _3, item) => [
        ...item.customerIds.map((item) => ({
          id: item,
          type: 'CustomerQuery' as const,
        })),
      ],
      transformResponse(item: ProfileTagsType[]) {
        toast.success('Tag added to customer(s)')
        return item
      },
    }),

    removeTagFromCustomer: build.mutation<
      OrgProfileTagTableType,
      { profileTagId: string; orgId: string; customerId: string }
    >({
      query: ({ orgId, profileTagId, customerId }) => ({
        url: `/organisations/${orgId}/crm/customers/${customerId}/tags/${profileTagId}`,
        method: 'delete',
      }),
      invalidatesTags: (_1, _3, item) => [
        { id: item.customerId, type: 'CustomerQuery' },
      ],
      transformResponse(item: OrgProfileTagTableType) {
        toast.success('Tag removed from customer')
        return item
      },
    }),
  }),
})

function CustomerSingleLayout() {
  const { orgId } = useGlobal()
  const { id } = useParams<{ id: string }>()
  const { data } = customerApi.useGetCustomerQuery({
    orgId,
    customerId: id,
  })

  const { data: counts = null } = customerApi.useGetCustomerCountsQuery({
    orgId,
    customerId: id,
  })

  const { data: bookingsCount } = zionApi.useGetCustomerBookingsCountQuery(
    {
      org_id: orgId,
      profile_id: data?.profile_id ?? NaN,
    },
    { skip: !data?.profile_id }
  )

  const { data: sessions } = wifi.useGetUserSessionsQuery(
    {
      orgId,
      customerId: id ?? '',
      cursor: '',
      limit: 25,
    },
    { skip: !orgId || !id }
  )

  const { data: emailData = defaultServicePagination } =
    serviceApi.useGetUserEmailEventsQuery({
      orgId,
      email: data?.user_profile?.email ?? '',
      cursor: '',
    })

  const links = useMemo(() => {
    const items: LinkType[] = [
      {
        value: 'Overview',
        key: '',
        icon: UserIcon,
      },
      {
        value: 'Activity',
        key: 'activity',
      },
    ]

    if (sessions?.data && Boolean(sessions?.data.length > 0)) {
      items.push({
        value: 'Wi-Fi',
        key: 'wi-fi',
        icon: WifiIcon,
      })
    }
    if (bookingsCount && Number(bookingsCount) > 0) {
      items.push({
        value: `Bookings (${bookingsCount})`,
        key: 'bookings',
        icon: CalendarIcon,
      })
    }
    if (counts && Boolean(counts?._count?.gift_card)) {
      items.push({
        value: `Gift Cards (${counts._count.gift_card})`,
        key: 'gift',
        icon: WalletIcon,
      })
    }

    if (counts && Boolean(counts?._count?.user_review)) {
      items.push({
        value: `Reviews (${counts._count.user_review})`,
        key: 'reviews',
        icon: StarIcon,
      })
    }

    if (
      counts &&
      Boolean(counts?._count?.organization_profile_marketing_events)
    ) {
      items.push({
        value: `Marketing (${counts._count.organization_profile_marketing_events})`,
        key: 'marketing',
        icon: PaperAirplaneIcon,
      })
    }

    if (counts && Boolean(counts?._count?.organization_profile_transaction)) {
      items.push({
        value: `Transactions (${counts._count.organization_profile_transaction})`,
        key: 'transactions',
        icon: PaperAirplaneIcon,
      })
    }

    if (emailData?.data && Boolean(emailData?.data.length > 0)) {
      items.push({
        value: 'Email Log',
        key: 'email',
        icon: EnvelopeIcon,
      })
    }

    return items
  }, [sessions?.data, bookingsCount, counts, emailData?.data])

  return (
    <TabbedNavLayout
      links={links}
      context={data}
      outletRequiresContext
      root="/:org_id/customer/:id"
      title="Customer"
    />
  )
}

export const useCustomerProfileContext = () => {
  return useOutletContext<CustomerOverviewType>()
}

export default CustomerSingleLayout
