import { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'

import {
  FrameOrganisationSubscriptionRequest,
  OrganisationSubscription,
  PlanTypes,
} from '@/connect-types/billing/organisation.type'
import { Plans } from '@/connect-types/billing/plans.types'
import { SubscriptionResponse } from '@/connect-types/billing/subscriptionResponse.type'
import { SubscriptionCreateRequestBody } from '@/connect-types/subscriptions/subscriptionCreateRequestBody.type'
import { ActionType, getType } from 'typesafe-actions'
import { openBilling, setSession } from '@/utils/billing'
import * as actions from './billing.actions'

export type BillingAction = ActionType<typeof actions>

export interface StateType {
  subscriptionAddOns: Plans[]
  subscriptionPlans: Plans[]
  subscriptions: Record<string, unknown>[]
  isLoading: boolean
  subscritionRequest: SubscriptionCreateRequestBody
  subscriptionResponse: SubscriptionResponse
  subscription: OrganisationSubscription
  serial: string
  subscriptionChange: FrameOrganisationSubscriptionRequest
}

const initialState: StateType = {
  subscriptionChange: {
    plan: PlanTypes.Free,
    annual: false,
    contacts: 0,
    venues: 0,
    selectedPlan: '',
    addons: [],
    currency: 'GBP',
  },
  subscription: null,
  subscriptionAddOns: [],
  subscriptionPlans: [],
  subscriptions: [],
  isLoading: false,

  subscritionRequest: {
    method: { name: '', serial: null },
    planId: 'starter',
    trial: false,
    annually: false,
    total: 50,
    addOns: [],
    iKnowSerial: false,
  },
  subscriptionResponse: null,
  serial: null,
}

/* tslint:disable cyclomatic-complexity */
const billingReducer: Reducer<StateType, BillingAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.getMemberPortalRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.isLoading = true
        break
      }

      /**
       * DELETE_MEMBERS_SUBSCRIPTION_REQUEST_SUCCESS
       *
       * /members/{uid}/subscriptions/{id}
       * Delete a Subscription
       */
      case getType(actions.getMemberPortalRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        setSession(action.payload.portal)

        if (action.payload.open) {
          openBilling()
        }

        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_SUBSCRIPTION_REQUEST_FAILURE
       *
       * /members/{uid}/subscriptions/{id}
       * Delete a Subscription
       */
      case getType(actions.getMemberPortalRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        draft.isLoading = false
        break
      }
    }
  })

export default billingReducer
/* tslint:enable cyclomatic-complexity */
