import type { SimpleGridProps, StackProps } from '@chakra-ui/react'
import { Box, Button, HStack, Icon, Spacer } from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/16/solid'
import { cx } from 'class-variance-authority'
import { motion } from 'framer-motion'
import type { FunctionComponent, ReactElement } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { cn, Icn, Skeleton } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { ContentContainerMd } from '@/layouts/content/container'
import { generateImage } from '@/helpers/image'
import { BentoGrid } from './bento-grid'

const variants = {
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  out: {
    // y: -50,
    scale: 0.8,
    opacity: 0,
  },
  enter: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    //  y: -50,
    scale: 0.8,

    opacity: 0,
  },
}

const stringToId = (str = '') => str.replace(/ /g, '_').toLocaleLowerCase()

export function LayoutNavigationCard({
  route,
  name,
  icon,
  children,
  onClick,
  actions,
  isRounded = true,
  cover,
  bgColor,
  className,
  ...rest
}: {
  route?: string
  onClick?: () => void
  name: string
  icon?: JSX.Element
  actions?: ReactElement
  isRounded?: boolean
  cover?: string
  bgColor?: string
  className?: string
} & StackProps) {
  return (
    <motion.div
      key={`layout_cards_item_${stringToId(name)}`}
      className={cn(
        'row-span-1 group relative   border border-black/5 dark:border-white/5 rounded-2xl overflow-hidden bg-black/10 dark:bg-white/10 hover:bg-black/15 dark:hover:bg-white/15 backdrop-blur-sm transition-all duration-500 justify-between flex flex-col space-y-4',
        { 'group/bento  h-64': Boolean(cover) },
        className
      )}
      style={{ backgroundColor: bgColor }}
      id={`layout_cards_item_${stringToId(name)}`}
    >
      <NavLink
        to={route}
        onClick={onClick}
        className=" inset-0 flex p-1.5 items-end h-full w-full text-white"
      >
        {cover ? (
          <img
            src={generateImage(cover, 'tile')}
            className="object-cover absolute bg-white/10 dark:bg-black/10 inset-0 w-full h-full group-hover:scale-110 transition-all duration-500"
            alt={name}
          />
        ) : null}

        <div
          className={cx(
            'relative   transition-all  ease-in-out duration-250 rounded-xl  overflow-hidden flex flex-col',
            {
              'h-full justify-end flex-1': Boolean(children),
              'w-full': !cover,
              'bg-white/25 backdrop-blur-xl dark:bg-black/25  border border-white/5':
                !children && Boolean(cover),
            }
          )}
        >
          {children ? (
            <div
              className={cn(
                'w-full ',
                'absolute flex p-3 flex-col items-center justify-center h-full flex-1 rounded-t-xl',
                'bg-white/15 backdrop-blur-md dark:bg-black/15'
              )}
            >
              {children}
            </div>
          ) : null}
          <div
            className={cx(
              'inline-flex gap-2 z-10 justify-between items-center pl-2 pr-2 py-1.5 rounded-b-xl'
            )}
          >
            <div className="flex  gap-2 items-center  transition-colors duration-250 overflow-hidden  text-black dark:fill-slate-50 dark:text-slate-50 group-hover:fill-white">
              {icon ? icon : null}
              <p className="font-semibold text-sm truncate capitalize">
                {name}
              </p>
            </div>
            <div className="flex items-center ">
              <div
                className="flex flex-row"
                onClick={(e) => {
                  e.preventDefault()
                }}
              >
                {actions}
              </div>
              <div className="flex p-0.5 items-center border rounded-full border-black/10 dark:border-white/10 relative left-0 group-hover:left-1  bg-white/50 transition-all duration-500 dark:bg-black/50">
                <Icn
                  icon={ChevronRightIcon}
                  className=" text-black dark:text-white  "
                  aria-label="go to"
                />
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </motion.div>
  )
}

export const LayoutNavigationAddCard: FunctionComponent<{
  title: string
  to: string
}> = ({ title, to, ...props }) => (
  <motion.div
    className="flex items-center justify-center h-full py-12 border border-dashed border-black/10 dark:border-white/10 rounded-xl transition-background ease-in-out duration-250"
    {...props}
  >
    <Link to={to} className="flex flex-col items-center">
      <div className="flex items-center justify-center w-12 h-12 bg-interface-500 rounded-full shadow-lg">
        <PlusIcon className="w-6 h-6 text-white" />
      </div>
      <p className="mt-3">{title}</p>
    </Link>
  </motion.div>
)

export function LayoutClickAddCard({
  title,
  onClick,
  ...props
}: {
  title: string
  onClick: () => void
}) {
  return (
    <motion.div
      className="flex items-center justify-center h-full py-12 border border-dashed border-black/10 dark:border-white/10 rounded-xl transition-background ease-in-out duration-250"
      {...props}
    >
      <div
        onClick={onClick}
        className="flex flex-col items-center cursor-pointer"
      >
        <div className="flex items-center justify-center w-12 h-12 bg-stampede-500 rounded-full shadow-lg">
          <PlusIcon className="w-6 h-6 text-white" />
        </div>
        <p className="mt-3">{title}</p>
      </div>
    </motion.div>
  )
}

const tvariants = {
  in: {
    x: 0,

    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    x: -5,

    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export function CardsTitle({
  title,
  subTitle,
  goBack = '',
}: {
  title?: string
  subTitle?: string
  goBack?: string
}) {
  return (
    <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
      {Boolean(goBack) && (
        <div className="flex-1">
          <Link
            to=".."
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-md"
          >
            <ArrowLeftIcon className="w-5 h-5 mr-2" />
            {goBack}
          </Link>
        </div>
      )}

      <motion.div
        className="flex-1 text-left flex flex-col gap-0.5 "
        initial="out"
        animate="in"
        variants={tvariants}
      >
        {subTitle ? (
          <p className="text-lg font-medium text-slate-600 dark:text-slate-300 leading-none">
            {subTitle}
          </p>
        ) : null}
        {title ? (
          <h1 className="text-2xl font-bold leading-none">{title}</h1>
        ) : null}
      </motion.div>

      {Boolean(goBack) && <div className="flex-1" />}
    </div>
  )
}

export function LayoutCardsGrid({ className, ...props }: SimpleGridProps) {
  return (
    <BentoGrid
      className={cx(
        'grid grid-cols-1 md:grid-cols-2 w-full  lg:grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-2',
        className
      )}
      {...props}
    />
  )
}

export const LayoutNavigationLoader: FunctionComponent<{ height?: string }> = ({
  height = '220px',
}) => {
  return (
    <ContentContainerMd>
      <div className="space-y-1">
        <Skeleton className="w-24 h-5 rounded-lg" />
        <Skeleton className="w-44 h-8 rounded-lg" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        <Skeleton className="w-full  h-20" />
        <Skeleton className="w-full h-20" />
        <Skeleton className="w-full h-20" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
    </ContentContainerMd>
  )
}

export default LayoutNavigationCard
