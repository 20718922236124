import {
  Box,
  Skeleton,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import { type ReactText, useCallback, useMemo, useState } from 'react'
import {
  Bar,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { format as formatDate, fromUnixTime, differenceInDays } from 'date-fns'
import { useAppSelector } from '@/state/helpers/useApp'
import { currency, formatBytes } from '@/utils/common'
import { type TimeGroupTypes } from '@/utils/TimeGenerator'
import { formatCalculator } from '@/utils/times'

interface ChartData {
  x: number
  y: number
  y1?: number
}

interface State {
  formatting: string
  tikFormatting: string
}

interface PassedProps {
  data: ChartData[]
  height?: number | string
  yaxisName: string
  y1axisName?: string
  chartStyleOverride?: string
  layout?: 'horizontal' | 'vertical'
  groupBy?: TimeGroupTypes
  color?: string
  color1?: string
  range?: [number, number]
  noCompound?: boolean
  isLoading?: boolean
}

const initState: State = {
  formatting: 'MMM YYYY',
  tikFormatting: 'MMM YYYY',
}

function ChartComponent({
  data,
  height: propsHeight,
  yaxisName,
  y1axisName,
  layout,
  chartStyleOverride,
  groupBy,
  color,
  range,
  color1,
  noCompound,
  isLoading,
}: PassedProps) {
  const [height] = useState(propsHeight || 300)
  const modeColor = mode('var(--chakra-colors-blue-500)', 'white')
  const chartStyle = useAppSelector(({ global }) => global.chartStyle)
  const compound = useAppSelector(({ global }) => global.isCompound)

  const { tikFormatting, formatting } = useMemo(() => {
    if (!data.length) return initState
    const startValue = fromUnixTime(data[0].x)
    const endValue = fromUnixTime(data[data.length - 1].x)
    return formatCalculator(differenceInDays(endValue, startValue), groupBy)
  }, [data, groupBy])

  const parseChart = useMemo(() => {
    if (!data.length) return []

    let d = [...data]

    if (layout) {
      if (layout === 'vertical') {
        d = d.map((value) => ({
          x: value.y,
          y: value.x,
        }))
      }
    }

    if (!compound || noCompound) {
      return d
    }

    let compoundCalc = 0
    let compoundCalc1 = 0

    return data.map((value) => {
      const v: { y: number; x: number; y1: number } = {
        x: value.x,
        y: value.y,
        y1: value?.y1 ?? 0,
      }

      v.y += compoundCalc
      compoundCalc = v.y

      if ('y1' in value && typeof v.y1 === 'number') {
        v.y1 = value.y1
        v.y1 += compoundCalc1
        compoundCalc1 = v.y1
      }

      return v
    })
  }, [compound, data, layout, noCompound])

  const getChartStyle = useCallback(
    (type: string) => {
      if (chartStyleOverride) {
        return chartStyleOverride === type
      }

      return chartStyle === type
    },
    [chartStyle, chartStyleOverride]
  )

  const formatXTick = useCallback(
    (x: any): string => {
      const parseX = parseInt(String(x), 0)

      if ((!isNaN(parseX) || typeof x === 'number') && layout !== 'vertical') {
        return formatDate(fromUnixTime(Number(x)), formatting)
      }

      return String(x)
    },
    [formatting, layout]
  )

  const formatYaxis = useCallback(
    (value: any) => {
      if (yaxisName === 'Download' || yaxisName === 'Upload') {
        return formatBytes(value, 0, false)
      }

      if (yaxisName === 'Amount') {
        return currency.format(value / 100)
      }

      return value
    },
    [yaxisName]
  )

  if (!parseChart || parseChart.length === 0) {
    return <></>
  }

  return (
    <>
      {isLoading ? <Skeleton h={height} borderRadius="md" /> : null}
      {!isLoading && (
        <ResponsiveContainer height={height} width="99%">
          <ComposedChart layout={layout} syncId="anyId" data={parseChart}>
            <XAxis
              dataKey="x"
              axisLine={false}
              tickLine={false}
              hide={height <= 100}
              tickFormatter={formatXTick}
            />

            <YAxis
              hide={height <= 100}
              axisLine={false}
              tick={{ dx: -20 }}
              tickLine={false}
              domain={range}
              tickFormatter={formatYaxis}
              type="number"
            />

            <Tooltip
              content={(payload: any) =>
                renderTooltip(payload, tikFormatting, groupBy)
              }
            />

            {getChartStyle('line') && (
              <Line
                dot={false}
                type="monotone"
                dataKey="y"
                name={yaxisName}
                stroke={color || modeColor}
                strokeWidth={3}
                activeDot={{
                  stroke: '#FFFFFF',
                  strokeWidth: 2,
                  fill: color || modeColor,
                  r: 6,
                }}
              />
            )}
            {getChartStyle('line') && y1axisName ? (
              <Line
                dot={false}
                type="monotone"
                dataKey="y1"
                name={y1axisName}
                stroke={color1 ?? 'var(--chakra-colors-gray-400)'}
                strokeWidth={3}
                activeDot={{
                  stroke: '#FFFFFF',
                  strokeWidth: 2,
                  fill: color1 || 'var(--chakra-colors-gray-400)',
                  r: 6,
                }}
              />
            ) : null}
            {getChartStyle('bar') && (
              <Bar
                dataKey="y"
                name={yaxisName}
                radius={2}
                barSize={8}
                fill={color || modeColor}
              />
            )}
            {getChartStyle('bar') && y1axisName ? (
              <Bar
                dataKey="y1"
                name={y1axisName}
                radius={2}
                barSize={8}
                fill={color1 ?? 'var(--chakra-colors-gray-400)'}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </>
  )
}

const formatTick = (label: ReactText, formatting: string) => {
  const parseX = parseInt(String(label), 0)

  if (!isNaN(parseX) || typeof label === 'number') {
    return formatDate(fromUnixTime(Number(label)), formatting)
  }

  return label
}

export const renderTooltip = (
  data: any,
  formatting: string,
  groupBy?: string
) => {
  return (
    <Box
      boxShadow="lg"
      p={3}
      borderRadius="md"
      zIndex={300}
      color="white"
      bg="gray.500"
    >
      <Text variant="time">{formatTick(data.label, formatting)}</Text>
      {(data.payload ?? []).map((label: any, key: number) => (
        <div key={key}>
          {label.name === 'Download' || label.name === 'Upload' ? (
            <Text variant="stat">{formatBytes(Number(label.value))}</Text>
          ) : label.name === 'Amount' ? (
            <Text variant="stat">
              {currency.format(Number(label.value) / 100)}
            </Text>
          ) : (
            <Text variant="stat">
              {label.payload.formatted ||
                Math.round(Number(label.value) * 10) / 10}
              {groupBy ? <small>%</small> : null}
            </Text>
          )}
          <Text variant="label">{label.name}</Text>
        </div>
      ))}
    </Box>
  )
}

export default ChartComponent
