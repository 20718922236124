import { DetailedHTMLProps, HTMLAttributes } from 'react'
import { cn } from '../helpers/classnames'

const Description = ({
  className,
  ...props
}: DetailedHTMLProps<
  HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>) => {
  return (
    <p
      {...props}
      className={cn(
        'text-gray-500 dark:text-gray-300 text-sm max-w-lg',
        className
      )}
    />
  )
}

export default Description
