import { useMemo } from 'react'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'

const useLocations = () => {
  const organisation = useSelectedOrganisation()

  return useMemo(() => {
    return [...(organisation?.locations ?? [])].sort((a, b) =>
      (a?.alias ?? '').localeCompare(b.alias ?? '')
    )
  }, [organisation])
}

export const useAllLocations = () => {
  const locations = useLocations()

  return locations
}

export default useLocations
