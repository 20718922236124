const backgroundColors = [
  '#ffbe0b',
  '#1c77c3',
  '#d91e36',
  '#7fb7be',
  '#ff3399',
  '#8cd867',
]

const GetIndexInAlphabet = (char: string) =>
  char.toUpperCase().charCodeAt(0) - 65

export const getColorFromString = (value: string): string => {
  if (!value) return backgroundColors[0]

  const first = value.charAt(0)
  const alphabetIndex = GetIndexInAlphabet(first)
  const fillArray = []
    .concat(...Object.values(Array(26).fill(backgroundColors)))
    .slice(0, 26)

  return fillArray[alphabetIndex]
}

function rgb2hex(rgb: string) {
  const newRgb =
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i.exec(
      rgb
    )

  return newRgb && newRgb.length === 4
    ? `#${`0${parseInt(newRgb[1], 10).toString(16)}`.slice(-2)}${`0${parseInt(
        newRgb[2],
        10
      ).toString(16)}`.slice(-2)}${`0${parseInt(newRgb[3], 10).toString(
        16
      )}`.slice(-2)}`
    : ''
}

export function invert(initHex = '', bw: boolean) {
  let hex = initHex ?? ''

  if (hex.startsWith('#')) {
    hex = hex.slice(1)
  }
  if (hex.length !== 6) {
    hex = rgb2hex(hex)
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  let r: number | string = parseInt(hex.slice(0, 2), 16)
  let g: number | string = parseInt(hex.slice(2, 4), 16)
  let b: number | string = parseInt(hex.slice(4, 6), 16)

  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
  }
  // invert color components
  r = (255 - r).toString(16)
  g = (255 - g).toString(16)
  b = (255 - b).toString(16)
  // pad each with zeros and return
  return `#${padZero(r)}${padZero(g)}${padZero(b)}`
}

function padZero(str: string, len?: number) {
  const newLen = len || 2
  const zeros = new Array(newLen).join('0')

  return (zeros + str).slice(-newLen)
}
