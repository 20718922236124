import { cloneDeep } from 'lodash'
import type { Segment } from './segment.types'

export const newSegment: Partial<Segment> = {
  name: 'New segment',
  is_editable: true,
  segment: {
    is_venue_segment: false,
    serials: [],
    root: {
      operator: 'and',
      nodes: [],
    },
  },
}

export const allSegment: Segment = {
  name: 'All customers',
  id: 'all',
  is_editable: false,
  organization_id: '',
  reach: {
    version: 1,
    reach: {
      email: 0,
      sms: 0,
      all: 0,
    },
  },
  version: '',
  loading: false,
  segment: {
    serials: [],
    root: {
      operator: 'and',
      nodes: [],
    },
  },
}

export const parseRoot = (value: Partial<Segment>) => {
  const sendNode: any = cloneDeep(value)
  if (!sendNode.segment.root) {
    return sendNode
  }
  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 1
  ) {
    sendNode.segment.root = sendNode.segment.root.nodes[0]
  }

  if (
    Array.isArray(sendNode.segment.root.nodes) &&
    sendNode.segment.root.nodes.length === 0
  ) {
    sendNode.segment.root = null
  }
  return sendNode
}
