import type { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'
import type { ActionType } from 'typesafe-actions'
import { getType } from 'typesafe-actions'
import { toast } from 'sonner'
import message from '@/common/message/message'
import type { IntegrationEnabled } from '@/connect-types/integrations/integrationEnabled.type'
import type { ListSync } from '@/connect-types/integrations/listSync.type'
import type {
  ExternalListType,
  ListUnifyRequestBody,
} from '@/connect-types/integrations/listUnifyRequestBody'
import type { PaypalAccountUpdateRequestBody } from '@/connect-types/integrations/paypalAccountUpdateRequestBody.type'
import type { PaypalLocationAccount } from '@/connect-types/integrations/paypalLocationAccount.type'
import type { UnifyUserCredentialCreateRequestBody } from '@/connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import * as actions from './integration.actions'

export type IntegrationAction = ActionType<typeof actions>

export interface StateType {
  paypalAccounts: PaypalAccountUpdateRequestBody[]
  paypalCurrentSiteSetup: PaypalLocationAccount
  mailchimpApiKey: UnifyUserCredentialCreateRequestBody
  campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody

  integrationListDetail: ListSync
  integrationList: ListUnifyRequestBody[]
  integrationExternalList: ExternalListType[]

  isIntegrationRequestLoading: boolean
  integrationEnabled: IntegrationEnabled
  isPaypalSaveRequired: boolean

  isMailChimpSaveRequired: boolean
  isCampaignMonitorSaveRequired: boolean
}

const initialState: StateType = {
  integrationEnabled: {
    paypal: false,
    unifi: false,
    stripe: false,
    mailchimp: false,
    zapier: false,
    campaignMonitor: false,

    storekit: false,
    access: false,
    roller: false,
    opentable: false,
    toggle: false,
    resdiary: false,
    hart: false,
    loke: false,
  },

  paypalAccounts: [],
  paypalCurrentSiteSetup: {
    paypalAccount: null,
  },
  mailchimpApiKey: {
    apiKey: null,
  },
  integrationListDetail: null,
  integrationList: [],
  integrationExternalList: [],
  campaignMonitorApiKey: {
    apiKey: null,
  },
  isIntegrationRequestLoading: false,
  isPaypalSaveRequired: false,
  isMailChimpSaveRequired: false,
  isCampaignMonitorSaveRequired: false,

  isAirshipSaveRequired: false,
}

const integrationReducer: Reducer<StateType, IntegrationAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      /**
       * INTEGRATION_MAILCHIMP_REQUEST
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequest): {
        draft.isIntegrationRequestLoading = true
        draft.mailchimpApiKey.apiKey = null
        break
      }

      /**
       * INTEGRATION_MAILCHIMP_REQUEST_SUCCESS
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey = action.payload.mailchimpApiKey
        break
      }

      /**
       * INTEGRATION_MAILCHIMP_REQUEST_FAILURE
       *
       * /integration/{uid}/mailchimp
       * Get the user's current details
       */
      case getType(actions.integrationMailchimpRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey.apiKey = null
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequest): {
        draft.isIntegrationRequestLoading = true
        message.loading('Loading')
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST_SUCCESS
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.mailchimpApiKey = action.payload.mailchimpApiKey
        draft.integrationEnabled.mailchimp = true
        toast.success('Mailchimp key updated')
        break
      }

      /**
       * UPDATE_INTEGRATION_MAILCHIMP_REQUEST_FAILURE
       *
       * /integration/{uid}/mailchimp
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationMailchimpRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.warning('Error updating Mailchimp key')
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.updateIntegrationMailchimpInStore): {
        draft.mailchimpApiKey = action.payload.mailChimpApiKey
        draft.isMailChimpSaveRequired = true
        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequest): {
        draft.isIntegrationRequestLoading = true
        draft.campaignMonitorApiKey.apiKey = null

        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey

        break
      }

      /**
       * INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Get the user's current details
       */
      case getType(actions.integrationCampaignMonitorRequestFailure): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey.apiKey = null

        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequest): {
        draft.isIntegrationRequestLoading = true
        message.loading('Loading')
        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey
        draft.integrationEnabled.campaignMonitor = true
        message.warning('Campaign Monitor key updated')
        break
      }

      /**
       * UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE
       *
       * /integration/{uid}/campaign-monitor
       * Create/update user credentials
       */
      case getType(actions.updateIntegrationCampaignMonitorRequestFailure): {
        draft.isIntegrationRequestLoading = false
        message.warning('Error updating Campaign Monitor key', {
          description: 'Invalid API Key',
        })
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.updateIntegrationCampaignMonitorInStore): {
        draft.campaignMonitorApiKey = action.payload.campaignMonitorApiKey
        draft.isCampaignMonitorSaveRequired = true
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(actions.integrationCampaignMonitorListRequest): {
        draft.isIntegrationRequestLoading = true
        draft.integrationList = []
        break
      }

      case getType(actions.integrationCampaignMonitorListRequestSuccess): {
        draft.isIntegrationRequestLoading = false
        draft.integrationList = action.payload.campaignMonitorLists
        break
      }

      case getType(actions.integrationCampaignMonitorListRequestFailure): {
        draft.isIntegrationRequestLoading = false
        break
      }

      case getType(actions.integrationCampaignMonitorSpecificListRequest): {
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(
        actions.integrationCampaignMonitorSpecificListRequestSuccess
      ): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.campaignMonitorList

        break
      }

      case getType(
        actions.integrationCampaignMonitorSpecificListRequestFailure
      ): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       *
       *
       *
       *
       */

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequest
      ): {
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequestSuccess
      ): {
        draft.isIntegrationRequestLoading = false
        draft.integrationListDetail = action.payload.campaignMonitorListDetails

        break
      }

      case getType(
        actions.updateIntegrationCampaignMonitorSpecificListRequestFailure
      ): {
        draft.isIntegrationRequestLoading = false
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequest): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST_SUCCESS
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequestSuccess): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * INTEGRATION_PAYPAL_REQUEST_FAILURE
       *
       * /integration/{uid}/paypal
       * Retrieve PayPal Account associated with the user
       */
      case getType(actions.integrationPaypalRequestFailure): {
        // TODO: implemement logic here
        // draft.something = whatever
        break
      }

      /**
       * INTEGRATION_ENABLED_REQUEST
       *
       * /integration/{uid}/connected
       * Get the Enabled unities
       */
      case getType(actions.integrationEnabledRequest): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = true
        break
      }

      case getType(actions.integrationEnabledRequestSuccess): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = false
        draft.integrationEnabled = {
          ...draft.integrationEnabled,
          ...action.payload.integrationEnabled,
        }
        break
      }

      case getType(actions.integrationEnabledRequestFailure): {
        // TODO: implemement logic here
        draft.isIntegrationRequestLoading = false
        break
      }
    }
  })

export default integrationReducer
