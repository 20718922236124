import { UserCircleIcon } from '@heroicons/react/16/solid'
import { memo } from 'react'
import { Tooltip } from 'tailwind-ui'
import { usePush as useNavigate } from '@/helpers/router'
import { invert } from '@/utils/color'
import { firstAndLast, gravatarImage } from '@/utils/common'
import { copyToClipboard } from '@/utils/copy'
import { getColorFromString } from '@/utils/less'

interface ProfileRowType {
  email: string
  first: string
  last: string
  phone?: string
  id?: number | string | any
  link?: string
  opt?: {
    smsOptInAt: string | null
    emailOptInAt: string | null
    dataOptInAt: string | null
  }
  fallbackText?: string
  onClick?: () => void
}

function ProfileRow({
  email,
  first,
  last,
  link,
  phone,
  fallbackText,
  ...rest
}: ProfileRowType) {
  const push = useNavigate()
  return (
    <div className="flex items-center pii-data profile-row">
      <div
        className="size-10 min-w-10 relative  rounded-full inline-flex items-center justify-center text-xs font-black border border-transparent group:hover:border-black/10 dark:group:hover:border-white/10"
        style={{
          backgroundColor: getColorFromString(email),
          color: invert(getColorFromString(email), true),
        }}
      >
        <img
          src={gravatarImage(email)}
          alt={firstAndLast(first, last, false)}
          className="w-full h-full rounded-full"
          onError={(e) => {
            e.currentTarget.style.display = 'none'
            e.currentTarget.parentElement
              ?.querySelector('.fallback-icon')
              ?.classList.remove('hidden')
          }}
        />
        <div className="fallback-icon hidden">
          {first || last ? (
            <span className=" font-extrabold uppercase">
              {`${first?.[0] || ''}${last?.[0] || ''}`}
            </span>
          ) : (
            <UserCircleIcon className="size-8" />
          )}
        </div>
      </div>

      <div className="ml-2 w-full truncate">
        <p className="text-xs font-bold leading-4 m-0 whitespace-break-spaces text-black dark:text-white">
          {firstAndLast(first, last, true) || fallbackText || 'Someone'}
        </p>

        {phone && false ? (
          <div className="group relative pii-data">
            <button
              className="block text-[10px] font-medium leading-4 max-w-[170px] truncate"
              onClick={
                link
                  ? () => {
                      push(link)
                    }
                  : () => {
                      copyToClipboard(phone)
                    }
              }
            >
              {phone}
            </button>
            <span className="invisible group-hover:visible absolute bottom-full left-1/2 -translate-x-1/2 px-2 py-1 bg-gray-800 text-white text-sm rounded">
              {phone}
            </span>
          </div>
        ) : null}

        <Tooltip information={email} className="group relative pii-data">
          <button
            className="block text-[10px] font-medium leading-4 max-w-[170px] truncate"
            onClick={
              link
                ? () => {
                    push(link)
                  }
                : () => {
                    copyToClipboard(email)
                  }
            }
            {...rest}
          >
            {email}
          </button>
        </Tooltip>
      </div>
    </div>
  )
}

export default memo(ProfileRow)
