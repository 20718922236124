import {
  type BaseQueryFn,
  createApi,
  retry,
} from '@reduxjs/toolkit/query/react'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'
import config from '@/config'
import { customAxios } from '@/utils/axiosHelper'
import {
  type ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
} from '@/connect-types/backend/service'
import { buildUrl } from '@/utils/common'
import { type JobType, type PartialJobType } from '@/pages/admin/queue/failed'
import { shouldBailout } from '../helpers'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })

      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError

      if ((method ?? '').toUpperCase() !== 'GET') {
        ServiceErrorHandler(axiosError)
      }

      if (shouldBailout(err)) {
        retry.fail(err)
      }

      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const cypherApi = createApi({
  reducerPath: 'cypher',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.cypher }), {
    maxRetries: 2,
  }),

  tagTypes: ['FailedJobs'],

  endpoints: (build) => ({
    failedJobs: build.query<
      ServiceCursorPaginatedResponseType<PartialJobType>,
      { cursor: string; queue?: string }
    >({
      query: ({ queue, cursor }) => ({
        url: buildUrl('/admin/failed-jobs', { queue, cursor }),
        method: 'GET',
      }),
      providesTags: [{ type: 'FailedJobs', id: 'LIST' }],
    }),

    failedJob: build.query<JobType, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/failed-jobs/${id}`,
        method: 'GET',
      }),
      providesTags: ({ uuid }) => [{ type: 'FailedJobs', id: uuid }],
    }),

    requeueFailedJob: build.mutation<{ output: string }, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/failed-jobs/${id}/requeue`,
        method: 'PUT',
      }),
      transformResponse: (item: { output: string }) => {
        toast.success(item.output)
        return item
      },
      invalidatesTags: (_1, _2, { id }) => [
        { type: 'FailedJobs', id },
        { type: 'FailedJobs', id: 'LIST' },
      ],
    }),

    batchRequeueFailedJobs: build.mutation<
      { output: string },
      { failed_jobs: string[] }
    >({
      query: ({ failed_jobs }) => ({
        url: `/admin/failed-jobs/requeue`,
        method: 'POST',
        data: { failed_jobs },
      }),
      transformResponse: (item: { output: string }) => {
        toast.success(item.output)
        return item
      },
      invalidatesTags: (_1, _2, _3) => [{ type: 'FailedJobs', id: 'LIST' }],
    }),

    deleteFailedJob: build.mutation<JobType, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/failed-jobs/${id}`,
        method: 'DELETE',
      }),
      transformResponse: ({ output }) => {
        toast.success(output)
        return output
      },
      invalidatesTags: (_1, _2, { id }) => [
        { type: 'FailedJobs', id },
        { type: 'FailedJobs', id: 'LIST' },
      ],
    }),
  }),
})

export default cypherApi
