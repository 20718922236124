import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import type { InboxContextProps } from '@/components/inbox/context'
import InboxContext from '@/components/inbox/context'
import inboxApi from '@/state/inbox/inbox.slice'
import useGlobal from '@/hooks/useGlobal'
import { initThread } from '@/state/entities/inbox/inbox.types'
import Drawer from '@/pages/[org_id]/venues/[serial]/bookings/components/drawer'
import ThreadCreateSendMessageBox from './create'

export interface InboxCreateWithType {
  contacts: { email: string; name: string }[]
  serial?: string
  subject: string
  content: string
}
export interface InboxPassedProps extends InboxContextProps {
  isOpen: boolean
  onClose: () => void
  start_text?: string
  createWith: InboxCreateWithType
}

export const CreateOrChatContext: FunctionComponent<
  Omit<InboxPassedProps, 'isOpen' | 'onClose'>
> = ({ createWith, ...rest }) => {
  const { orgId } = useGlobal()
  const { data: contextThreads } = inboxApi.useGetInboxThreadsQuery({
    orgId,
    query: {
      context_id: rest.context_id,
      context_type: rest.context_type,
      serial: rest.serial,
    },
  })

  const selectedThread = useMemo(() => {
    if (!contextThreads)
      return {
        ...initThread,
        fake_thread: true,
      }
    return (
      contextThreads.data.find((i) => i.context_id === rest.context_id) || {
        ...initThread,
        fake_thread: true,
      }
    )
  }, [contextThreads])

  return (
    <>
      {!selectedThread?.id && (
        <ThreadCreateSendMessageBox
          context={{
            id: rest.context_id,
            type: rest.context_type,
          }}
          createWith={createWith}
          onComplete={() => { }}
        />
      )}
      {rest.context_id ? (
        <InboxContext {...{ ...rest, content: createWith.content }} />
      ) : null}
    </>
  )
}

const InboxContextModal: FunctionComponent<InboxPassedProps> = ({
  isOpen,
  onClose,
  createWith,
  ...rest
}) => {
  return (
    <Drawer
      open={isOpen}
      setOpen={(o) => {
        if (!o) {
          onClose()
        }
      }}

      classNames={{ container: 'md:max-w-md lg:max-w-md' }}
      title="Start conversation"
    >
      <CreateOrChatContext createWith={createWith} {...rest} />
    </Drawer>
  )
}

type InboxCreateType = InboxContextProps & InboxCreateWithType

type InboxContextType = [
  InboxCreateType | null,
  (value: InboxCreateType | null) => void,
]

const InboxWrapContext = createContext<Partial<InboxContextType>>([])

export function InboxModalProvider({ children }: PropsWithChildren) {
  const [inboxContext, setInboxContext] = useState<InboxCreateType | null>(null)
  return (
    <InboxWrapContext.Provider value={[inboxContext, setInboxContext]}>
      <InboxContextModal
        context_id={inboxContext?.context_id}
        context_type={inboxContext?.context_type}
        createWith={inboxContext}
        isOpen={Boolean(inboxContext)}
        onClose={() => {
          setInboxContext(null)
        }}
        serial={inboxContext?.serial}
      />
      {children}
    </InboxWrapContext.Provider>
  )
}

export const useInboxContext = () => {
  return useContext(InboxWrapContext)
}

export default InboxContextModal
