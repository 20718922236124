import { FacebookAccount } from '@/connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from '@/connect-types/reviews/facebookPages.type'
import { createAction } from 'typesafe-actions'

export const locationsFacebookAuthorizeAccountRequest = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST',
  (resolve) => (orgId: string, redirectUrl: string, appName: string) =>
    resolve({ orgId, redirectUrl, appName })
)

export const locationsFacebookAuthorizeAccountRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST_SUCCESS',
  (resolve) => (redirectUrl: string) => resolve({ redirectUrl })
)

export const locationsFacebookAuthorizeAccountRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_AUTHORIZE_ACCOUNT_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const locationsFacebookAccountsRequest = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST',
  (resolve) => (orgId: string) => resolve({ orgId })
)

export const locationsFacebookAccountsRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST_SUCCESS',
  (resolve) => (facebookAccounts: FacebookAccount[]) =>
    resolve({ facebookAccounts })
)

export const locationsFacebookAccountsRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const locationsFacebookAccountsPagesRequest = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST',
  (resolve) => (orgId: string, accountId: string) =>
    resolve({ orgId, accountId })
)

export const locationsFacebookAccountsPagesRequestSuccess = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_SUCCESS',
  (resolve) => (facebookPages: FacebookPage[]) => resolve({ facebookPages })
)

export const locationsFacebookAccountsPagesRequestFailure = createAction(
  'LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

export const updateLocationsFacebookPageRequest = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST',
  (resolve) =>
    (orgId: string, pageId: string, token: string, review_id: string) =>
      resolve({ orgId, pageId, token, review_id })
)

export const updateLocationsFacebookPageRequestSuccess = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_SUCCESS',
  (resolve) => (page: FacebookPage) => resolve({ page })
)

export const updateLocationsFacebookPageRequestFailure = createAction(
  'UPDATE_LOCATIONS_FACEBOOK_ACCOUNTS_PAGES_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)
