import { createAction } from 'typesafe-actions'

export const setVenuesModalVisible = createAction(
  'SET_VENUES_MODAL_VISIBLE',
  (resolve) => (visible: boolean) => resolve({ visible })
)

export const setAddBookingModalVisible = createAction(
  'TOGGLE_ADD_BOOKING_MENU',
  (resolve) => (open: boolean) => resolve({ open })
)
