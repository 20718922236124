import { Icon } from '@chakra-ui/react'
import {
  WifiIcon,
  CalendarIcon,
  PaperAirplaneIcon,
  InboxIcon,
  StarIcon,
  ChartPieIcon,
  GiftIcon,
  HeartIcon,
  UserCircleIcon,
  BellAlertIcon,
  CreditCardIcon,
  UserPlusIcon,
  Cog6ToothIcon,
  TagIcon,
  PaintBrushIcon,
  ArchiveBoxIcon,
  BellIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon,
  ArrowsRightLeftIcon,
  ViewColumnsIcon,
  BuildingStorefrontIcon,
  ChatBubbleOvalLeftIcon,
  ArrowRightEndOnRectangleIcon,
} from '@heroicons/react/16/solid'
import type { ReactElement } from 'react'
import type { AddonTypes } from '@/connect-types/billing/organisation.type'
import {
  AnalyticsIcon,
  FacebookLogo,
  GoogleIcon,
} from '@/common/Icons/CustomIcons'
import TripadvisorIcon from '@/ux/icons/tripadvisor'
import type { VendorType } from '@/state/entities/venue/venue.type'

export interface MenuType {
  title: string
  key: string
  locationType?: number[]
  icon?: ReactElement
  role?: number[]
  locationCount?: number
  addOnFlag?: AddonTypes
  news?: boolean
  vendor?: VendorType[]
  requiresLocation?: boolean
  notVendor?: string[]
  experimental?: boolean
  sub?: MenuType[]
  subCount?: number
  hiddenSub?: boolean
  animatedIcon?: any
  dropdown?: boolean
  fillStatKey?: string
  orgType?: 'default' | 'partner'
  hideProd?: boolean
}

export const parentIconSize = 24

export const MenuItems: MenuType[] = [
  /*
  {
    title: 'Home',
    key: '/',
    icon: <FontAwesomeIcon icon={faHouse} />
  },
*/
  {
    title: 'Wi-Fi',
    key: 'venues',
    requiresLocation: true,
    icon: <Icon as={WifiIcon} />,
    hiddenSub: true,
    addOnFlag: 'wifi',
    role: [0, 1, 2, 3],
  },
  {
    title: 'Bookings',
    key: 'bookings',
    news: true,
    icon: <Icon as={CalendarIcon} />,
    hideProd: false,
    role: [0, 1, 2, 3],
    requiresLocation: true,
  },
  /*
  {
    title: 'Customers',
    key: '/customers',
    icon: <FontAwesomeIcon icon={faUsers} />,
    role: [0, 1, 2, 3, 4, 5],
    requiresLocation: false,
    sub: [
      {
        key: '/interactions',
        title: 'Interactions',
      },
    ],
  },
  */
  {
    title: 'Marketing',
    key: 'marketing',
    icon: <Icon as={PaperAirplaneIcon} />,
    addOnFlag: 'marketingautomation',
    hiddenSub: true,
    role: [0, 1, 2, 3, 5],
  },
  {
    title: 'Inbox',
    key: 'inbox',
    icon: <Icon as={InboxIcon} />,
  },
  {
    title: 'Reviews',
    key: 'reviews',
    addOnFlag: 'reviews',
    icon: <Icon as={StarIcon} />,
    role: [0, 1, 2, 3],

    sub: [
      {
        title: 'Setup',
        key: '/setup',
        sub: [
          {
            title: 'Pages',
            key: '/reviews/pages',
            icon: <Icon as={ViewColumnsIcon} />,
          },
          {
            title: 'Get more reviews',
            key: '/reviews/boost',
            icon: <Icon as={RocketLaunchIcon} />,
          },
        ],
      },
      {
        title: 'Responses',
        key: '/reviews/responses',
        sub: [
          {
            title: 'All',
            key: '/reviews/responses',
            icon: <Icon as={ChatBubbleLeftRightIcon} />,
            fillStatKey: 'total',
          },
          {
            title: 'Positive',
            key: '/reviews/responses?sentiment=POSITIVE',
            icon: <Icon as={FaceSmileIcon} />,
            fillStatKey: 'positive',
          },
          {
            title: 'Negative',
            key: '/reviews/responses?sentiment=NEGATIVE',
            icon: <Icon as={FaceFrownIcon} />,
            fillStatKey: 'negative',
          },
          {
            title: 'Needs attention',
            key: '/reviews/responses?done=false',
            icon: <Icon as={BellIcon} />,
            fillStatKey: 'negative_not_done',
          },
        ],
      },
      {
        title: 'Platforms',
        key: '/reviews/platforms',
        dropdown: true,
        sub: [
          {
            title: 'Google',
            key: '/reviews/responses?platform=google',
            icon: <Icon as={GoogleIcon} />,
            fillStatKey: 'google',
          },
          {
            title: 'Facebook',
            key: '/reviews/responses?platform=facebook',
            icon: <Icon as={FacebookLogo} />,
            fillStatKey: 'facebook',
          },
          {
            title: 'Tripadvisor',
            key: '/reviews/responses?platform=tripadvisor',
            icon: <Icon as={TripadvisorIcon} />,
            fillStatKey: 'tripadvisor',
          },
          {
            title: 'Stampede',
            key: '/reviews/responses?platform=stampede',
            fillStatKey: 'stampede',
          },
          {
            title: 'Happy or Not',
            key: '/reviews/responses?platform=nearly',
          },
        ],
      },
      {
        title: 'Reports',
        key: '/reviews/reports',
        icon: <AnalyticsIcon />,
      },
    ],
  },
  {
    title: 'Loyalty',
    key: 'loyalty',
    icon: <Icon as={HeartIcon} />,
    role: [0, 1, 2, 3],
    requiresLocation: false,
  },
  {
    title: 'Gift cards',
    key: 'gifting',
    icon: <Icon as={GiftIcon} />,
    role: [0, 1, 2, 3],
    requiresLocation: false,
  },

  {
    title: 'Reports',
    key: 'reports',
    hiddenSub: true,
    icon: <Icon as={ChartPieIcon} />,
  },
]

export const AccountMenuItems: MenuType[] = [
  {
    title: 'Profile',
    key: 'profile',
    sub: [
      {
        title: 'My information',
        key: 'account/profile',
        icon: <Icon as={UserCircleIcon} />,
      },
      {
        title: 'Notifications',
        key: 'account/notifications',
        icon: <Icon as={BellAlertIcon} />,
      },
    ],
  },
  {
    title: 'Organisation',
    key: 'organisation',
    sub: [
      {
        title: 'Billing',
        key: 'organisation/billing',
        icon: <Icon as={CreditCardIcon} />,
      },

      {
        title: 'Team',
        key: 'organisation/team',
        icon: <Icon as={UserPlusIcon} />,
      },
      {
        title: 'Settings',
        key: 'organisation/settings',
        icon: <Icon as={Cog6ToothIcon} />,
      },
      {
        title: 'Customer tags',
        key: 'organisation/tags',
        icon: <Icon as={TagIcon} />,
      },
      {
        title: 'Brand kits',
        key: 'organisation/brand',
        icon: <Icon as={PaintBrushIcon} />,
      },
      {
        title: 'Event Log',
        key: 'organisation/event-log',
        icon: <Icon as={ArchiveBoxIcon} />,
      },
    ],
  },
  {
    title: 'Resource',
    key: '/resource',
    sub: [
      {
        title: 'Hardware store',
        key: 'https://stampede.ai/shop',
      },
      {
        title: 'Knowledge Base',
        key: 'https://get.stampede.help',
      },
    ],
  },
  {
    title: 'Logout',
    key: '../oauth/logout',
    icon: <Icon as={ArrowRightEndOnRectangleIcon} />,
  },
  {
    title: 'Customer Support Portal',
    key: 'https://help.stampede.ai',
  },
]

export const profileOrgNavigationItems = [
  {
    title: 'Profile',
    key: 'account/profile',
    icon: UserCircleIcon,
  },
  {
    title: 'Notifications',
    key: 'account/notifications',
    icon: BellAlertIcon,
  },
]

export const orgNavigationItems = [
  {
    title: 'Billing',
    key: 'organisation/billing',
    icon: CreditCardIcon,
  },

  {
    title: 'Apps Marketplace',
    key: 'marketplace/apps',
    icon: BuildingStorefrontIcon,
  },

  {
    title: 'Team',
    key: 'organisation/team',
    icon: UserPlusIcon,
  },
  {
    title: 'Settings',
    key: 'organisation/settings',
    icon: Cog6ToothIcon,
  },
  {
    title: 'Customer tags',
    key: 'organisation/tags',
    icon: TagIcon,
  },
  {
    title: 'Brand kits',
    key: 'organisation/brand',
    icon: PaintBrushIcon,
  },
  {
    title: 'Event log',
    key: 'organisation/event-log',
    icon: ArchiveBoxIcon,
  },

  {
    title: 'Switch organisation',
    key: '/',
    icon: ArrowsRightLeftIcon,
  },
]
