import { useParams } from 'react-router-dom'
import billingApi from '@/state/billing/billing.slice'

const useSubscription = () => {
  const { org_id } = useParams<{ org_id: string }>()
  const { data: subscription } = billingApi.useGetQuery(
    { orgId: org_id! },
    { refetchOnFocus: true }
  )

  return subscription
}

export const useFullSubscription = () => {
  const { org_id } = useParams<{ org_id: string }>()
  const data = billingApi.useGetQuery({ orgId: org_id! })

  return data
}

export default useSubscription
