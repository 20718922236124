import { call, put, takeLeading } from 'redux-saga/effects'
import { ActionType } from 'typesafe-actions'
import { deleter } from '@/utils/api'
import * as actions from './global.actions'

function* removeVenueFromOrgSaga(
  action: ActionType<typeof actions.unlinkVenueFromOrg.request>
) {
  const { serial, orgId } = action.payload

  try {
    const response: {
      status: number
      message: string
    } = yield call(
      async () =>
        await deleter(
          `/organisations/${orgId}/admin/unlink-location-from-organisation`,
          { data: { serial: serial } }
        )
    )
    localStorage.removeItem(`/organisations/${orgId}`)
    yield put(actions.unlinkVenueFromOrg.success(response))
    yield put(actions.clearSelectedLocation())
  } catch (error) {
    yield put(actions.unlinkVenueFromOrg.failure(error))
  }
}

export function* watch() {
  yield takeLeading(actions.unlinkVenueFromOrg.request, removeVenueFromOrgSaga)
}

export default [watch]
