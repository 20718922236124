import { forwardRef } from 'react'
import { ErrorLabel } from '../labels/error'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../..'

interface PassedProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size'
    >,
    VariantProps<typeof checkboxVariants> {
  brandKit?: boolean
  error?: string
  label: string
}

export const checkboxVariants = cva(
  [
    'mt-0 border-2 form-checkbox text-black bg-gray-50 dark:bg-black dark:border-gray-700 border-gray-300 rounded cursor-pointer',
  ],
  {
    variants: {
      size: {
        sm: 'w-4 h-4',
        base: 'w-5 h-5',
        lg: 'w-6 h-6',
        xl: 'w-8 h-8',
      },
      brandKit: {
        true: 'text-brand-or-black focus:ring-brand-or-black rounded-brand',
        false: 'text-stampede-500 focus:ring-stampede-500',
      },
    },
    defaultVariants: {
      brandKit: false,
      size: 'base',
    },
  }
)

export const Checkbox = forwardRef<HTMLInputElement, PassedProps>(
  ({ error, label = '', brandKit, size, ...rest }, ref) => {
    return (
      <div className="relative">
        <ErrorLabel error={error} />

        <div className="flex bg-white dark:bg-black items-start border-gray-200 form-input px-3.5 py-3.5 rounded-brand focus:border-brand focus:ring-brand w-full border cursor-pointer">
          <input
            className={cn(checkboxVariants({ brandKit, size }))}
            id={rest.id ?? rest.name}
            type="checkbox"
            {...rest}
            ref={ref}
          />
          {!!label && (
            <label
              className="w-full ml-3 text-sm cursor-pointer"
              htmlFor={rest.id ?? rest.name}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
        </div>
      </div>
    )
  }
)

Checkbox.displayName = 'Checkbox'
