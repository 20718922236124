import { HStack, Text } from '@chakra-ui/react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format } from 'date-fns'
import { FunctionComponent } from 'react'
import { InboxThreadEventEventableSystemNoteType } from '@/state/entities/inbox/inbox.types'

const ThreadSystemNoteBox: FunctionComponent<{
  event: InboxThreadEventEventableSystemNoteType
}> = ({ event }) => {
  return (
    <HStack align="center" justify="center" role="group">
      <Text variant="desc" textAlign="center">
        {format(event.created_at, TimeFormats.FourHourApple)} -{' '}
        {event.description}
      </Text>
    </HStack>
  )
}

export default ThreadSystemNoteBox
