import { Box } from '@chakra-ui/react'
import { Textarea } from '@chakra-ui/textarea'
import type { FunctionComponent } from 'preact'
import type { ReactQuillProps } from 'react-quill'
import ReactQuillLib from 'react-quill'
import 'react-quill/dist/quill.bubble.css'

import './quill.css'

const QuillTextarea: FunctionComponent<ReactQuillProps> = (props) => {
  return (
    <ReactQuillLib
      formats={[
        'header',
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'align',
      ]}
      modules={{
        toolbar: [
          [{ header: '1' }, { header: '2' }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'],
          ['clean'],
          [
            { align: '' },
            { align: 'center' },
            { align: 'right' },
            { align: 'justify' },
          ],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      }}
      style={{ height: '100%' }}
      theme="bubble"
      {...props}
    />
  )
}

export const QuillTextareaWrapped: FunctionComponent<ReactQuillProps> = (
  props
) => {
  return (
    <Box>
      {/*//@ts-expect-error*/}
      <Textarea autoFocus={false} as={QuillTextarea} {...props} />
    </Box>
  )
}

export default QuillTextarea
