import {
  addDays,
  addHours,
  addMonths,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  startOfHour,
  startOfDay,
  getDate,
  getDay,
  getHours,
  getMonth,
  getYear,
  getWeek,
  fromUnixTime,
  getUnixTime,
  parseISO,
} from 'date-fns'
import uniqBy from 'lodash/uniqBy'
import sumBy from 'lodash/sumBy'

export type TimeGroupTypes = 'hour' | 'day' | 'month' | 'week'
export const timeGroups: TimeGroupTypes[] = ['hour', 'day', 'week', 'month']
interface SeriesResponseType {
  timestamp: string
  day_of_month: number
  day_of_week: number
  hour_of_day: number
  month_of_year: number
  year_week: number
  year: number
  value?: number
}

class Series {
  private start: number
  private end: number
  private asTime: string
  private inputItems: any[] = []
  group: TimeGroupTypes = null

  constructor(start: number, end: number, asTime = 'days') {
    this.start = start
    this.end = end
    this.asTime = asTime
  }

  setAsTime(asTime: string) {
    this.asTime = asTime

    return this
  }

  setInputItems(inputItems: any[]) {
    this.inputItems = inputItems ? inputItems : []

    return this
  }

  setGroup(group: TimeGroupTypes) {
    this.group = group

    if (this.group === 'hour') {
      this.setAsTime('hours')
    }

    return this
  }

  private timeItemFilter(time: SeriesResponseType) {
    if (this.asTime === 'hours') {
      return this.inputItems.filter(
        (item) =>
          item.hour === time.hour_of_day &&
          item.day === time.day_of_month &&
          item.month === time.month_of_year &&
          item.year === time.year
      )
    }

    if (this.asTime === 'days') {
      return this.inputItems.filter(
        (item) =>
          item.day === time.day_of_month &&
          item.month === time.month_of_year &&
          item.year === time.year
      )
    }

    if (this.asTime === 'months') {
      return this.inputItems.filter(
        (item) => item.month === time.month_of_year && item.year === time.year
      )
    }

    return []
  }

  private groupTimeItemFilter(time: SeriesResponseType) {
    if (this.group === 'hour') {
      return this.inputItems.filter((item) => item.hour === time.hour_of_day)
    }

    if (this.group === 'day') {
      return this.inputItems.filter(
        (item) => item.day_of_week === time.day_of_week
      )
    }

    if (this.group === 'month') {
      return this.inputItems.filter((item) => item.month === time.month_of_year)
    }

    return []
  }

  generateFromSeries(key: string) {
    const generator = this.generate()
    const total = sumBy(this.inputItems, key)
    generator.map((time) => {
      let toAdd: any[] = []
      let intToAdd = 0

      if (this.group) {
        toAdd = this.groupTimeItemFilter(time)
        intToAdd = Number(((sumBy(toAdd, key) / total) * 100).toFixed(2)) || 0
      } else {
        toAdd = this.timeItemFilter(time)
        intToAdd = sumBy(toAdd, key)
      }

      time.value += intToAdd

      return time
    })

    return generator
  }

  generate = (): SeriesResponseType[] => {
    const startTime = fromUnixTime(this.start)
    const endTime = fromUnixTime(this.end)

    const units = this.durationToUnits(startTime, endTime, this.asTime)
    const response = []

    for (let index = 0; index < units; index++) {
      if (
        this.asTime === 'minute' &&
        index + 1 !== 1 &&
        (index + 1) % 15 !== 0
      ) {
        continue
      }

      let timestamp: Date
      if (this.asTime === 'hours') {
        timestamp = startOfHour(addHours(startTime, index))
      } else if (this.asTime === 'days') {
        timestamp = startOfDay(addDays(startTime, index))
      } else if (this.asTime === 'months') {
        timestamp = startOfDay(addMonths(startTime, index))
      }

      response.push({
        timestamp: timestamp.toISOString(),
        day_of_month: getDate(timestamp),
        day_of_week: getDay(timestamp) + 1,
        hour_of_day: getHours(timestamp),
        month_of_year: getMonth(timestamp) + 1,
        year: getYear(timestamp),
        year_week: getWeek(timestamp),
        value: 0,
      })
    }

    if (this.group) {
      if (this.group === 'hour') {
        return uniqBy(response, 'hour_of_day')
      }

      if (this.group === 'day') {
        return uniqBy(response, 'day_of_week')
      }

      if (this.group === 'month') {
        return uniqBy(response, 'month_of_year')
      }
      if (this.group === 'week') {
        return uniqBy(response, 'year_week')
      }
    }

    return response
  }

  parseForPlot(series: SeriesResponseType[]) {
    return series.map((item) => ({
      x: getUnixTime(parseISO(item.timestamp)),
      y: item.value,
    }))
  }

  parseForMutiplePlot(series: SeriesResponseType[][]) {
    return series[0].map((item, key) => {
      const ys: any = {}

      series.forEach((i, k) => {
        let y = 'y'

        if (k !== 0) {
          y = `y${k}`
        }

        ys[y] = i[key].value
      })

      return {
        ...ys,
        x: getUnixTime(parseISO(item.timestamp)),
      }
    })
  }

  private durationToUnits = (start: Date, end: Date, asTime: string) => {
    if (asTime === 'months') return differenceInMonths(end, start)
    if (asTime === 'days') return differenceInDays(end, start)
    if (asTime === 'hours') return differenceInHours(end, start)
    if (asTime === 'minute') return differenceInMinutes(end, start)
  }
}

export default Series
