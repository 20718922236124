import message from '@/common/message/message'
import { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'

import { FacebookAccount } from '@/connect-types/reviews/facebookAccounts.type'
import { FacebookPage } from '@/connect-types/reviews/facebookPages.type'
import uniqBy from 'lodash/uniqBy'
import { ActionType, getType } from 'typesafe-actions'
import * as actions from './settings.actions'

export type SettingsAction = ActionType<typeof actions>

export interface StateType {
  isLoading: boolean

  facebookAccounts: FacebookAccount[]
  facebookAuthorizationUrl: string | null
  facebookPages: FacebookPage[]
}

const initialState: StateType = {
  isLoading: false,
  facebookAccounts: [],
  facebookAuthorizationUrl: null,
  facebookPages: [],
}

/* tslint:disable cyclomatic-complexity */
const settingsReducer: Reducer<StateType, SettingsAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.locationsFacebookAccountsRequest): {
        draft.isLoading = true
        draft.facebookAccounts = []
        break
      }

      case getType(actions.locationsFacebookAccountsRequestSuccess): {
        draft.isLoading = false

        draft.facebookAccounts = uniqBy(
          action.payload.facebookAccounts,
          'accessToken'
        ).map((item) => item)
        break
      }

      case getType(actions.locationsFacebookAccountsRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequest): {
        draft.isLoading = true
        draft.facebookPages = []
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequestSuccess): {
        draft.isLoading = false
        draft.facebookPages = action.payload.facebookPages
        break
      }

      case getType(actions.locationsFacebookAccountsPagesRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequest): {
        draft.isLoading = true
        draft.facebookAuthorizationUrl = null
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequestSuccess): {
        draft.isLoading = false
        draft.facebookAuthorizationUrl = action.payload.redirectUrl
        break
      }

      case getType(actions.locationsFacebookAuthorizeAccountRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.updateLocationsFacebookPageRequest): {
        draft.isLoading = true

        break
      }

      case getType(actions.updateLocationsFacebookPageRequestSuccess): {
        draft.isLoading = false

        message.success('Facebook settings updated')
        break
      }

      case getType(actions.updateLocationsFacebookPageRequestFailure): {
        draft.isLoading = false

        break
      }
    }
  })

export default settingsReducer
/* tslint:enable cyclomatic-complexity */
