import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { setDemoMode } from '@/state/global/global.actions'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'

// exported for testing without connect
const Blurrer = () => {
  const dispatch = useAppDispatch()
  const [search] = useSearchParams()

  const isDemoMode = useAppSelector((state) => state.global.isDemoMode)
  const updateDemoModeDom = useCallback((demoMode: boolean) => {
    if (demoMode) {
      document.body.classList.add('pii')
    } else {
      document.body.classList.remove('pii')
    }
  }, [])

  useEffect(() => {
    const isDemoParamMode = search.get('demo')
    if (!isDemoParamMode) return

    dispatch(setDemoMode(isDemoParamMode === 'true'))
  }, [dispatch, search])

  useEffect(() => {
    updateDemoModeDom(isDemoMode)
  }, [isDemoMode, updateDemoModeDom])

  return <></>
}

export default Blurrer
