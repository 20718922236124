import {
  CheckIcon,
  ChevronUpDownIcon,
  ShareIcon,
} from '@heroicons/react/16/solid'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import {
  useLocation,
  useMatch,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { toast } from 'sonner'
import {
  FadeInBelowOutAbove,
  Input,
  Modal,
  SButton,
  SIconButton,
  type SIconButtonProps,
  Select,
  cx,
} from 'tailwind-ui'
import { z } from 'zod'
import { Combobox } from '@headlessui/react'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import useUser from '@/hooks/useUser'
import LocationSelect from '@/common/Form/LocationSelect'
import { type OrganisationUsersAccess } from '@/state/auth/auth.types'
import config from '@/config'
import { copyToClipboard } from '@/utils/copy'
import { hookFormErrorToast } from '@/helpers/zod'

const initialState = {
  isOpen: false,
  onClose: () => {},
  onOpen: () => {},
  serials: [],
}

const UserInviteContext = createContext(initialState)

export function UserInviteProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false)
  const { org_id } = useParams<{ org_id: string }>()
  const [search] = useSearchParams()

  const { data: users = [] } = morpheusApi.useGetOrganisationUsersQuery(
    {
      orgId: org_id!,
    },
    {
      skip: !isOpen,
    }
  )
  const { serial } = useParams<{ serial: string }>()
  const { pathname } = useLocation()
  const initialValues = useMemo(
    () => ({
      serials: serial ? [serial] : [],
      email: '',
      success_link: pathname.includes('organisation/team')
        ? null
        : `${config.url.connect}${pathname}${search.toString() ? `?${search.toString()}` : ''}`,
      role: 'admin',
    }),
    [serial, pathname]
  )

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, isValid },
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    delayError: 300,
    defaultValues: initialValues,
    resolver: zodResolver(
      z.object({
        serials: z.array(z.string().length(12)).default([]),
        email: z.string().email(),
        role: z.enum(['admin', 'moderator', 'reports', 'booking', 'marketeer']),
      })
    ),
  })

  useEffect(() => {
    reset(initialValues)
  }, [isOpen])

  const [invite, { isLoading }] = morpheusApi.useInviteMutation()
  const [inviteId, setInviteId] = useState('')
  const onSubmit = () => {
    const values = getValues()
    return toast.promise(invite({ orgId: org_id, data: values }).unwrap(), {
      loading: `Sending invite`,
      success: (data) => {
        reset(initialValues)
        // setIsOpen(false)
        setInviteId(data.id)
        return `Invite sent`
      },
      error: `Error sending invite`,
    })
  }

  const serials = watch('serials')
  const email = watch('email')
  const user = useUser()

  const filteredUsers = useMemo(() => {
    return users.filter((item) =>
      item.oauth_users.email
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(email.toLowerCase().replace(/\s+/g, ''))
    )
  }, [users, email])

  const [selectedUser, setSelectedUser] =
    useState<OrganisationUsersAccess | null>(null)

  return (
    <UserInviteContext.Provider
      value={{
        isOpen,
        onClose: () => {
          setIsOpen(false)
        },
        onOpen: () => {
          setIsOpen(true)
        },
      }}
    >
      <Modal
        title="Share With Team"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        classNames={{
          body: '!p-0',
        }}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit, hookFormErrorToast)}
          className="flex flex-col gap-3 py-4"
        >
          {pathname.replace(`/${org_id}`, '') && (
            <div className="w-full px-6 pb-4 border-b ">
              <div>
                <p className="text-sm text-gray-400">
                  {pathname.replace(`/${org_id}`, '')}
                  {search.toString() ? `?${search.toString()}` : ''}
                </p>
              </div>
            </div>
          )}
          <div className="grid grid-cols-6 gap-3 px-6">
            <div className="col-span-4">
              <label
                htmlFor="guest_invite"
                className="block text-sm font-medium leading-6"
              >
                Invite Email
              </label>
              <Combobox
                onChange={(v: OrganisationUsersAccess) => {
                  console.log({ v }, 'combo-box-value')
                  if (typeof v !== 'string') {
                    setValue('email', v.oauth_users.email, {
                      shouldValidate: true,
                    })
                    setValue('role', v.role.key, {
                      shouldValidate: true,
                    })
                    setValue(
                      'serials',
                      initialValues.serials.length >= 1
                        ? [
                            ...new Set([
                              ...(v.oauth_users.location_access ?? []).map(
                                (item) => item.serial
                              ),
                              initialValues.serials[0],
                            ]),
                          ]
                        : (v.oauth_users.location_access ?? []).map(
                            (item) => item.serial
                          ),
                      {
                        shouldValidate: true,
                      }
                    )
                    setSelectedUser(v)
                  } else {
                    setValue('email', v)
                  }
                }}
                value={selectedUser}
              >
                <div className="relative w-full">
                  <Combobox.Input
                    id="guest_search"
                    value={email}
                    onChange={(v) => {
                      setValue('email', v.target.value)
                    }}
                    displayValue={(v) => {
                      if (!v) {
                        return
                      }
                      return v.email
                    }}
                    autoComplete="off"
                    className="rounded-button text-md h-10 pr-8 w-full border-2 border-gray-200 bg-white dark:bg-gray-900  form-input ring-brand focus:ring-brand focus:border-brand"
                    placeholder="jane@doe.com"
                  />

                  <Combobox.Button
                    className="absolute inset-y-0 right-0 flex items-center pr-2"
                    onClick={() => {
                      setValue('email', '')
                    }}
                  >
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                </div>
                <div className="relative ">
                  <Combobox.Options className="text-sm  z-20 mt-1 bg-white p-2 dark:bg-black w-full fixed lg:absolute max-lg:left-0 max-lg:bottom-0  lg:shadow-md border rounded-t-xl lg:rounded-md space-y-1 max-h-[300px] overflow-y-auto">
                    {email && filteredUsers.length === 0 ? (
                      <Combobox.Option
                        value={email}
                        key="add-key"
                        className={({ active }) =>
                          cx(
                            'cursor-pointer  hover:bg-brand hover:text-brand-invert px-4  py-4 lg:py-2 rounded-md',
                            {
                              'bg-brand text-brand-invert': active,
                            }
                          )
                        }
                      >
                        <div>Create "{email}"</div>
                      </Combobox.Option>
                    ) : null}
                    {filteredUsers.map((item) => (
                      <Combobox.Option
                        key={item.id}
                        value={item}
                        className={({ active }) =>
                          cx(
                            `relative flex items-center justify-between cursor-pointer rounded-md select-none py-4 lg:py-2 px-4`,
                            {
                              'bg-brand text-brand-invert': active,
                              'text-gray-500 dark:text-gray-400': !active,
                            }
                          )
                        }
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate  ${
                                selected ? 'font-medium pl-6' : 'font-normal'
                              }`}
                            >
                              {item.oauth_users.email}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                  active ? 'text-white' : 'text-teal-600'
                                }`}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </div>
              </Combobox>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="role"
                className="block text-sm font-medium leading-6"
              >
                Role
              </label>
              <Select
                brandKit
                id="role"
                {...register('role')}
                className="capitalize"
              >
                {['admin', 'moderator', 'marketeer', 'booking'].map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </div>
            <div className="col-span-6">
              <div className="flex justify-between">
                <label
                  htmlFor="role"
                  className="block text-sm font-medium leading-6"
                >
                  Access to venues
                </label>
              </div>

              <LocationSelect
                resetText="Can access all locations"
                size="md"
                mode="multiple"
                value={serials}
                onChange={(v: string[]) => {
                  console.log({ allSerials: v })
                  const hasNullValue = v.filter((item) => !item)
                  setValue('serials', hasNullValue.length >= 1 ? [] : v)
                }}
              />
            </div>
          </div>

          <FadeInBelowOutAbove show={isValid}>
            <div className="bg-white dark:bg-black border-t border-b py-3 px-6">
              <p className="text-gray-500 text-sm">
                An email will be sent to (<strong>{email}</strong>) with a link
                to create their account, once they complete this step we will
                send you an email to (<strong>{user.email}</strong>) letting you
                know.
              </p>
            </div>
          </FadeInBelowOutAbove>
          <div className="self-end pt-1 px-6">
            <SButton
              size="lg"
              brandKit
              type="submit"
              isLoading={isSubmitting || isLoading}
            >
              Invite Product User
            </SButton>
          </div>

          <FadeInBelowOutAbove show={Boolean(inviteId)}>
            <div className="bg-white dark:bg-black border-t border-b py-3 px-6 flex gap-3">
              <p className="text-gray-500 text-xs">
                {`${config.url.connect}/account-invite/${inviteId}/accept`}
              </p>
              <SButton
                onClick={() => {
                  copyToClipboard(
                    `${config.url.connect}/account-invite/${inviteId}/accept`
                  )
                }}
              >
                Copy
              </SButton>
            </div>
          </FadeInBelowOutAbove>
        </form>
      </Modal>

      {children}
    </UserInviteContext.Provider>
  )
}

export const useUserInvite = () => {
  return useContext(UserInviteContext)
}

export const ShareButton: React.ForwardRefExoticComponent<
  SIconButtonProps & React.RefAttributes<HTMLButtonElement>
> = (props) => {
  const { onOpen } = useUserInvite()
  return (
    <SIconButton
      aria-label="share-thread"
      icon={ShareIcon}
      onClick={onOpen}
      variant="ghost_default"
      isRound
      {...props}
      className={cx(props.className)}
    />
  )
}
