import type { Reducer } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { toast } from 'sonner'
import type { ActionType } from 'typesafe-actions'
import { getType } from 'typesafe-actions'
import type { Filter } from '@/connect-types/members/filter.type'
import type { FilterCreateRequestBody } from '@/connect-types/members/filterCreateRequestBody.type'
import type { GalleryGetResponse } from '@/connect-types/members/galleryGetResponse.type'
import type {
  MemberRole,
  MembersResponse,
} from '@/connect-types/members/getMembers.type'
import type { NotificationList } from '@/connect-types/members/profileNotificationList.type'
import type { SingleTemplate } from '@/connect-types/members/template.type'
import { initPage } from '@/connect-types/pagination/pagination.type'
import type { MembersSubscriptionResponse } from '@/connect-types/subscriptions/subscription.type'
import type { User } from '@/connect-types/user/user.type'
import type * as authActions from '@/state/auth/auth.actions'
import * as actions from './member.actions'

export type MemberAction = ActionType<typeof actions & typeof authActions>

export interface StateType {
  members: MembersResponse
  selectedMember: User
  pendingSave: boolean
  templates: SingleTemplate[]
  notifications: NotificationList
  isLoading: boolean
  isEmailSending: boolean
  memberError: boolean
  roles: MemberRole[]
  subscriptionResponse: MembersSubscriptionResponse
  gallery: GalleryGetResponse
  filters: Filter[]
  filtersDetails: FilterCreateRequestBody[]
  newFilter: FilterCreateRequestBody
}

export const initialState: StateType = {
  members: {
    has_more: false,
    total: 0,
    next_offset: 0,
    results: [],
    request: null,
  },
  isEmailSending: false,
  selectedMember: null,
  pendingSave: false,
  templates: [],
  notifications: null,
  isLoading: false,
  memberError: false,
  roles: [
    {
      name: 'Super Admin',
      code: 'superadmin',
      orgName: 'Super Admin',
      int: 0,
      min: 0,
      description: 'Has access to everything',
    },
    {
      name: 'Reseller',
      orgName: 'Reseller',
      code: 'reseller',
      int: 1,
      min: 0,
      description: 'Has full access to mutiple admin accounts',
    },
    {
      name: 'Admin',
      orgName: 'Admin',
      code: 'admin',
      int: 2,
      min: 1,
      description: 'Has full access to mutiple admin accounts',
    },
    {
      name: 'Moderator',
      code: 'moderator',
      orgName: 'Moderator',
      int: 3,
      min: 2,
      description: 'Has admin level access at selected locations',
    },
    {
      name: 'Analytics',
      orgName: 'Reports',
      code: 'report',
      int: 4,
      min: 2,
      description: 'Has access to analytics at selected locations',
    },
    {
      name: 'Marketeer',
      orgName: 'Marketeer',
      code: 'market',
      int: 5,
      min: 2,
      description: 'Has access to marketing at selected locations',
    },
    {
      name: 'Booking',
      orgName: 'Booking',
      code: 'booking',
      int: 6,
      min: 2,
      description: 'Has access to bookings',
    },
  ],
  subscriptionResponse: {
    has_more: false,
    next_offset: 0,
    subscriptions: [],
    total: 0,
  },
  gallery: initPage,
  filters: [],
  filtersDetails: [],
  newFilter: {
    type: '',
    name: '',
    events: [
      {
        joinType: '',
        operand: '',
        position: 0,
        question: '',
        value: '',
      },
    ],
  },
}

const memberReducer: Reducer<StateType, MemberAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.addTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels.push(action.payload.label)
        }

        break
      }

      case getType(actions.removeTagToImage): {
        const item = draft.gallery.body.find(
          (item) => item.id.toString() === action.payload.imageId
        )
        if (item) {
          item.labels = item.labels.filter(
            (item) => item.id !== action.payload.label.id
          )
        }

        break
      }

      /** SET_SELECTED_USER */
      case getType(actions.setSelectedMember): {
        if (
          !Object.is(draft.selectedMember, action.payload.member) &&
          draft.selectedMember
        ) {
          draft.pendingSave = true
        }

        draft.selectedMember = action.payload.member
        break
      }

      case getType(actions.membersGalleryPushContent): {
        draft.gallery.body.unshift(action.payload.item)
        draft.gallery.total += 1
        break
      }

      case getType(actions.membersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      case getType(actions.membersGalleryContentRequestSuccess): {
        draft.isLoading = false
        draft.gallery = action.payload.gallery

        break
      }

      case getType(actions.membersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequest): {
        draft.isLoading = true
        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_SUCCESS
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestSuccess): {
        draft.isLoading = false
        toast.success('Image deleted')
        const find = draft.gallery.body.findIndex(
          (image) => image.id === action.payload.id
        )
        draft.gallery.body.splice(find, 1)

        break
      }

      /**
       * DELETE_MEMBERS_GALLERY_CONTENT_REQUEST_FAILURE
       *
       * /members/{uid}/gallery/{id}
       * Delete a piece of content
       */
      case getType(actions.deleteMembersGalleryContentRequestFailure): {
        draft.isLoading = false
        break
      }

      case getType(actions.sendSupportEmailRequest): {
        draft.isEmailSending = true
        break
      }

      case getType(actions.sendSupportEmailRequestSuccess): {
        draft.isEmailSending = false
        toast.success('Cancel request is being processed')
        break
      }

      case getType(actions.sendSupportEmailRequestFailure): {
        draft.isEmailSending = false
        break
      }
    }
  })

export default memberReducer
