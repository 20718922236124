import { Button, IconButton } from '@chakra-ui/react'
import { HStack, SimpleGrid, Stack } from '@chakra-ui/react'
import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  startOfYear,
  subYears,
} from 'date-fns'
import { format, startOfMonth } from 'date-fns'
import { FunctionComponent, useState } from 'react'
import { NextIcon, BackIcon } from '@/ux/icons/defaults'
import { TimeFormats } from './formats'
import { SButton, SIconButton } from 'tailwind-ui'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/16/solid'
import Heading from 'tailwind-ui/src/typeography/heading'

interface PassedProps {
  dates: [Date, Date]
  onChange: (dates: [Date, Date]) => void
}

const MonthPicker: FunctionComponent<PassedProps> = ({ onChange, dates }) => {
  const [start] = dates
  const [year, setYear] = useState(startOfYear(start))

  return (
    <Stack spacing={3}>
      <HStack justify="space-between">
        <SIconButton
          variant="ghost_default"
          icon={ArrowLeftIcon}
          aria-label="back-year"
          onClick={() => setYear((y) => subYears(y, 1))}
        />
        <Heading size="xs">{format(year, TimeFormats.Year)}</Heading>
        <SIconButton
          variant="ghost_default"
          icon={ArrowRightIcon}
          aria-label="forward-year"
          onClick={() => setYear((y) => addYears(y, 1))}
          disabled={
            addYears(year, 1).getTime() > endOfYear(new Date()).getTime()
          }
        />
      </HStack>
      <div className="grid grid-cols-3 gap-2">
        {Array.from({ length: 12 }).map((_, index) => (
          <SButton
            key={index}
            disabled={addMonths(year, index).getTime() > new Date().getTime()}
            isActive={addMonths(year, index).getTime() === start.getTime()}
            onClick={() =>
              onChange([
                startOfMonth(addMonths(year, index)),
                endOfMonth(addMonths(addMonths(year, index), 12)),
              ])
            }
          >
            {format(addMonths(year, index), TimeFormats.Month)}
          </SButton>
        ))}
      </div>
    </Stack>
  )
}

export default MonthPicker
