import { useDrawer } from './drawer-context'
import {
  Drawer as DrawerUi,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from './drawer-ui'

export default function Drawer() {
  const { isOpen, content, closeDrawer } = useDrawer()

  if (!isOpen || !content) return null

  return (
    <DrawerUi open={isOpen} onClose={closeDrawer}>
      {/* <DrawerTrigger>Open</DrawerTrigger> */}
      <DrawerContent>
        {content}
        {/* <DrawerHeader>
          <DrawerTitle>Are you absolutely sure?</DrawerTitle>
          <DrawerDescription>This action cannot be undone.</DrawerDescription>
        </DrawerHeader> */}
        {/* <DrawerFooter>
         
        </DrawerFooter> */}
      </DrawerContent>
    </DrawerUi>
  )
}
