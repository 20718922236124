import type { AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios'
import config from '../config'
import { putter, poster, deler, getter, isAxiosError } from './axiosHelper'

class RequestError extends Error {
  name = 'API Request Error'

  response: AxiosError

  constructor(response: any) {
    super()
    if (isAxiosError(response)) {
      this.response = response
    }

    return response
  }
}

type ServiceUrls =
  | 'api'
  | 'service'
  | 'url_generator'
  | 'booking_integration'
  | 'bookings'
  | 'notifications'
  | 'reporting'
  | 'inbox'
  | 'morpheus'
  | 'trinity'
  | 'auth'

const getApiUri = (accessing: ServiceUrls = 'api') => {
  switch (accessing) {
    case 'api':
      return config.url.api
    case 'service':
      return config.url.service
    case 'url_generator':
      return config.url.urlGenerator
    case 'booking_integration':
      return config.url.bookingIntegration
    case 'bookings':
      return config.url.bookings
    case 'notifications':
      return config.url.notifications
    case 'reporting':
      return config.url.reporting
    case 'inbox':
      return config.url.inbox
    case 'morpheus':
      return config.url.morpheus
    case 'trinity':
      return config.url.trinity
    case 'auth':
      return config.url.auth
  }
}

export function isResponseError(error: unknown): error is RequestError {
  return (error as RequestError).response !== undefined
}

function handleGeneralError(response: Response, error: any) {
  console.groupCollapsed('Fetch error ', response.url)
  console.log(response)
  console.error(error)
  console.groupEnd()
}

function isErrorCode(statusCode: number) {
  return (
    /4\d\d/.exec(String(statusCode)) ||
    /5\d\d/.exec(String(statusCode)) ||
    statusCode === 204
  )
}

/*
 * Get oauth credentials from local storage
 */

const handleAxiosResponse = async <T>(
  request: Promise<AxiosResponse>
): Promise<AxiosResponse<T>['data']> => {
  try {
    const response = await request

    if (isErrorCode(response.status)) {
      throw new RequestError(response)
    }

    return response.data
  } catch (error) {
    const errorRes = new RequestError(error)
    console.log({ errorRes, error })
    throw errorRes
  }
}

export async function get<T>(
  url: string,
  service: ServiceUrls = 'auth',
  config?: AxiosRequestConfig
) {
  let sendUrl = `${getApiUri(service)}${url}`

  if (url.startsWith('http')) {
    sendUrl = url
  }

  return handleAxiosResponse<T>(getter(`${sendUrl}`, config))
}

export async function postWithoutAuth(
  url: string,
  data?: Record<string, unknown>,
  options?: RequestInit
) {
  const response = await fetch(`${config.url.auth}${url}`, {
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    ...options,
    method: 'POST',
    body: JSON.stringify(data),
  })

  if (isErrorCode(response.status)) {
    const error = await response.json()

    throw new RequestError(error)
  }

  try {
    return await response.json()
  } catch (error) {
    handleGeneralError(response, error)
  }
}

export async function post<T>(
  url: string,
  data?: any,
  settings?: AxiosRequestConfig | null,
  service?: ServiceUrls
) {
  let sendUrl = `${getApiUri(service)}${url}`

  if (url.includes('http')) {
    sendUrl = url
  }

  return handleAxiosResponse<T>(poster(`${sendUrl}`, data, settings))
}

export async function postCsv(url: string, data?: any) {
  return post(url, data, {
    headers: {
      'Content-Type': 'text/csv',
    },
    timeout: 1000 * 60,
  })
}

export async function put<T>(
  url: string,
  data?: any,
  service: ServiceUrls = 'auth'
) {
  let sendUrl = `${getApiUri(service)}${url}`
  if (url.includes('http')) {
    sendUrl = url
  }

  console.log(sendUrl, data, ' putter')

  return handleAxiosResponse<T>(putter(`${sendUrl}`, data))
}

export async function deleter(
  url: string,
  config?: AxiosRequestConfig | null,
  service: ServiceUrls = 'auth'
) {
  let sendUrl = `${getApiUri(service)}${url}`

  if (url.includes('http')) {
    sendUrl = url
  }

  return handleAxiosResponse(deler(`${sendUrl}`, config))
}
