import { DocumentMagnifyingGlassIcon } from '@heroicons/react/16/solid'
import { memo } from 'react'
import { SButton } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { zEOpen } from '@/helpers/zendesk'

export const hasData = (analytics: any): boolean => {
  if (!analytics) return false
  if (!('table' in analytics)) return false
  if (!analytics.table.length) return false

  return true
}

export const hasArrayData = (analytics: any): boolean => {
  if (!analytics) return false
  if (analytics.length === 0) return false

  return true
}

interface PassedProps {
  title?: string
  text?: string
  padded?: boolean
  action?: () => void
  actionText?: string
  isLoading?: boolean
}

function NoData({
  text,
  padded,
  action,
  actionText,
  title,
  isLoading = false,
}: PassedProps) {
  return (
    <div className="flex flex-col space-y-4 h-full">
      <DocumentMagnifyingGlassIcon className="w-12 h-12" />
      <div>
        <Heading>{title ?? 'No Data'}</Heading>
        <Description>
          If you’re still having issues,{' '}
          <SButton className="" variant="link" onClick={zEOpen}>
            contact our support
          </SButton>
        </Description>
      </div>
      {action ? (
        <div>
          <SButton
            className="rounded-full px-4"
            onClick={action}
            isLoading={isLoading}
            variant="outline"
          >
            {actionText}
          </SButton>
        </div>
      ) : null}
    </div>
  )
}

export default memo(NoData)
