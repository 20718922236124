import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CursorSearchType,
  ServiceCursorPaginatedResponseType,
} from '@/connect-types/backend/service'
import { EndUser } from '@/connect-types/user/user.type'
import { get } from '@/utils/api'

type ActionPropTypes = { id: string; orgId: string } & CursorSearchType

export const getUserSearch = createAsyncThunk(
  'ORGANIZATION_REGISTRATION_USER_SEARCH_REQUEST',
  async (props: Partial<ActionPropTypes> & { search: string }) => {
    const { orgId, search } = props
    const response = await get<
      ServiceCursorPaginatedResponseType<EndUser & { org_reg_id: string }>
    >(`/organisations/${orgId}/user-profiles/search?search=${search}&limit=5`)
    return response
  }
)
