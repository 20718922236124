import { useParams } from 'react-router-dom'
import { useSelectedOrganisationVenue } from '@/state/backend/venues/venues.hooks'

const useSelectedLocation = (serial = '') => {
  const { serial: paramsSerial } = useParams<{ serial: string }>()
  if (!serial && !!paramsSerial) {
    serial = paramsSerial
  }
  const location = useSelectedOrganisationVenue(serial)

  return location
}

export default useSelectedLocation
