import { useEffect } from 'react'

const brandName = '| Stampede'

export const usePageTitle = (title = '') => {
  useEffect(() => {
    if (typeof title !== 'string') return
    document.title = `${title} ${brandName} `
  }, [title])
}
