import { call, put, takeLatest } from 'redux-saga/effects'
import { PortalSession } from '@/connect-types/billing/chargebee.types'
import { ActionType, getType } from 'typesafe-actions'
import { get } from '@/utils/api'
import * as actions from './billing.actions'

/* Get user locations
 * ========================================================================== */
function* getMemberPortalRequest(
  action: ActionType<typeof actions.getMemberPortalRequest>
) {
  try {
    const { orgId, open } = action.payload

    // TODO: if this response format is standard, could have a type for it
    const response: {
      status: number
      message: PortalSession
    } = yield call(async () => get(`/organisations/${orgId}/billing/portal`))

    const portal = response.message

    yield put(actions.getMemberPortalRequestSuccess(portal, open))
  } catch (error) {
    yield put(actions.getMemberPortalRequestFailure())
  }
}

export function* watchBillingRequest() {
  yield takeLatest(
    getType(actions.getMemberPortalRequest),
    getMemberPortalRequest
  )
}

export default [watchBillingRequest]
