import ProfileRow from '@/common/Table/ProfileRow'
import { defaultServicePagination } from '@/connect-types/backend/service'
import { customerApi } from '@/pages/[org_id]/customer/[id]/layout'
import { OrganizationRegistration } from '@/state/entities/interactions/interactions.types'
import morpheusApi from '@/state/morpheus/morpheus.slice'
import { ChevronLeftIcon } from '@heroicons/react/16/solid'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { NavLink, useParams } from 'react-router-dom'
import { Spinner, SNavLink, SButton, ButtonGroup } from 'tailwind-ui'
import useDebounce from 'tailwind-ui/src/helpers/useDebounce'
import { useFilters } from '../filter/filters'
import Pagination from '@/common/Table/Pagination'

const SearchProfiles: React.FC<{
  onSelect?: (reg: OrganizationRegistration) => void
  limit: number
}> = ({ onSelect, limit = 5 }) => {
  const { register, watch, setValue } = useForm<{ term: string }>()
  const term = watch('term') || ''
  const { org_id: orgId } = useParams<{ org_id: string }>()
  const [{ serial }] = useFilters()
  const [cursor, setCursor] = useState('')
  const search = useDebounce(term)
  const {
    data = defaultServicePagination,
    isLoading,
    isFetching,
  } = customerApi.useGetCustomersQuery({
    orgId,
    search,
    query: {
      serial,
      limit,
      cursor,
    },
  })
  useEffect(() => {
    setCursor('')
  }, [search])

  return (
    <>
      <form className="flex justify-between items-center  sticky top-2 z-10">
        <input
          className="form-input  rounded-lg text-neutral-900 dark:text-neutral-100 placeholder:text-neutral-800 placeholder:dark:text-neutral-50 bg-white/75 dark:bg-black/75 backdrop-blur-lg z-50 flex-1 w-full border-0 focus:ring-transparent"
          placeholder="Search by email or phone..."
          type="text"
          {...register('term')}
        />
        {isLoading || isFetching ? (
          <Spinner className="mr-0.5 absolute right-2" />
        ) : null}
      </form>
      <div className="">
        {data.data.map((item) => (
          <NavLink
            key={item?.id}
            {...(onSelect
              ? {
                  onClick: () => {
                    if (!item) return
                    onSelect(item as OrganizationRegistration)
                  },
                }
              : { to: `/${orgId}/customer/${item?.id}` })}
            className="flex justify-between  items-center bg-white dark:bg-black hover:bg-neutral-100 dark:hover:bg-neutral-900 p-2 rounded-full"
          >
            <ProfileRow
              onClick={() => {
                if (onSelect && item) {
                  onSelect(item as OrganizationRegistration)
                }
              }}
              id={item?.id}
              email={item?.user_profile.email}
              phone={item?.user_profile.phone}
              first={item?.user_profile.first}
              last={item?.user_profile.last}
            />
            <ChevronRightIcon className="mr-2 h-4 w-4" />
          </NavLink>
        ))}

        {data.data.length === 0 && !isFetching && !isLoading && term ? (
          <div className="p-4 flex items-end flex-col">
            <p className="text-sm text-gray-400 dark:text-gray-500">
              No results for {term}
            </p>
          </div>
        ) : null}

        {data.data.length === 0 && (isFetching || isLoading) ? (
          <div className="p-4 flex items-end flex-col">
            <p className="text-sm text-gray-400 dark:text-gray-500">
              Loading {term ? `results for ${term}` : `initial results`}
            </p>
          </div>
        ) : null}

        {data.data.length !== 0 ? (
          <ButtonGroup className="p-0.5 pl-0 inline-flex space-x-0.5 pr-2 bg-white/75 dark:bg-black/75 backdrop-blur-lg border items-center sticky bottom-2">
            <>
              <Pagination
                className="px-0"
                pageSize={limit}
                cursor={data.links}
                onChange={(cursor) => {
                  setCursor(cursor.nextLink)
                }}
              />
            </>
            <p className="text-sm pl-4 text-gray-400 dark:text-gray-500">
              {data.data.length}
              {data.data.length >= 5 ? `+ ` : ` `}
              customers
            </p>
          </ButtonGroup>
        ) : null}
      </div>
    </>
  )
}

export default SearchProfiles
