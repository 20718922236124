import config from '@/config'
import useGlobal from '@/hooks/useGlobal'
import Pusher, { Channel } from 'pusher-js'
import { useEffect } from 'react'
import { getToken } from '@/utils/AuthTokenHelper'

const pusherClient = (orgId: string) =>
  new Pusher(config.sockets.pusher_client_key, {
    channelAuthorization: {
      transport: 'ajax',
      endpoint: `${config.url.morpheus}/organisations/${orgId}/pusher/auth`,
      headers: {
        Authorization: `Bearer ${getToken()?.accessToken}`,
      },
    },
    cluster: 'eu',
  })

export const usePusherChannel = () => {
  const { orgId } = useGlobal()

  const client = pusherClient(orgId)

  return client.subscribe(`private-${orgId}`)
}

let client: Pusher
let channel: Channel

export const usePusherSubscription = <T extends object>(
  topic: string = '',
  callback: (data: T) => void
) => {
  const { orgId } = useGlobal()

  useEffect(() => {
    if (!topic) return
    if (!client) {
      client = pusherClient(orgId)
    }

    if (!channel) {
      channel = client.subscribe(`private-${orgId}`)
    }

    channel.bind(topic, (data) => {
      callback(data as T)
    })

    return () => {
      client.unbind(topic)
    }
  }, [callback, orgId, topic])
}

export default pusherClient
