import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import type { MenuType } from '@/components/SideNav/menu-config'
import { useSelectedVenue } from '@/state/backend/venues/venues.hooks'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import type { SecondNavMenuItemType } from '@/state/entities/ui/ui.types'
import type { OrganisationLocationType } from '@/connect-types/organisations/organisations.type'
import { generateSubMenuItem } from '@/common/SecondNav/SecondNavHelpers'
import useLocations from '../state/entities/locations/hooks/useLocation'
import useUser from './useUser'

//const allVenueItems = MenuItems.find(({ key }) => key === '/venues').sub

const useVenue = () => {
  const { serial } = useParams<{ serial: string }>()

  const selectedLocation = useSelectedVenue(serial)

  return selectedLocation.data
}

export const useVenues = () => {
  const venues = useLocations()

  const initialState: Record<string, OrganisationLocationType> = {}
  const keyedVenues = venues.reduce(
    (obj, item) => ({
      ...obj,
      [item.serial]: item,
    }),
    initialState
  )

  const findVenue = useCallback(
    (serial: string = '') => {
      if (serial in keyedVenues) {
        return keyedVenues[serial]
      }
      return null
    },
    [keyedVenues]
  )

  const findAlias = useCallback(
    (serial: string = '') => {
      const venue = findVenue(serial)
      if (!venue) return serial

      return venue.alias || serial
    },
    [findVenue]
  )

  const setVenue = useCallback(
    (venue: OrganisationLocationType) => {
      const id = venues.findIndex(
        (location) => location.serial === venue.serial
      )

      venues[id] = venue
    },
    [venues]
  )

  return [venues, findAlias, setVenue, keyedVenues] as const
}

interface InitialMenuState {
  items: MenuType[]
  nav: SecondNavMenuItemType[]
}

const initialMenuState: InitialMenuState = {
  items: [],
  nav: [],
}

export const useVenueMenu = (serial: string) => {
  const user = useUser()
  const organisation = useSelectedOrganisation()
  const { data: venue } = useSelectedVenue(serial)
  const userRole = user?.role
  const inform = venue?.inform
  const venuesCount = (organisation?.locations ?? []).length
  const venueType = venue?.type

  const itemFilter = useCallback(
    (Item: MenuType) => {
      const conditions = [true]

      if (!venue) {
        conditions.push(false)
      }

      if (Item.requiresLocation && !venue) {
        conditions.push(false)
      }

      if (Item.notVendor && inform) {
        conditions.push(!Item.notVendor.includes(inform.vendor_item.key))
      }

      if (Item.vendor && inform) {
        conditions.push(Item.vendor.includes(inform.vendor_item.key))
      }

      if (Item.locationCount) {
        conditions.push(venuesCount >= Item.locationCount)
      }

      if (Item.locationType && venueType !== null) {
        conditions.push(Item.locationType.includes(venueType))
      }

      if (userRole && Item.role) {
        conditions.push(Item.role.includes(userRole))
      }

      if (!Item.requiresLocation) {
        conditions.push(true)
      }

      return conditions.every((value) => value)
    },
    [inform, userRole, venue, venueType, venuesCount]
  )

  const applyFilterToMenu = useCallback(
    (menu: MenuType[]): MenuType[] => {
      if (!venue) return []
      return menu.filter(itemFilter).map((item) => {
        if (item.sub) {
          item.sub = applyFilterToMenu(item.sub)
        }

        return item
      })
    },
    [itemFilter, venue]
  )

  const res = useMemo(
    () =>
      applyFilterToMenu(cloneDeep(initialMenuState.items)).map(
        generateSubMenuItem
      ),
    [applyFilterToMenu]
  )

  return res
}

export default useVenue
