const isBrowser = typeof window !== `undefined`

const getLang = () => {
  if (!isBrowser) {
    return 'en-GB'
  }

  return window.navigator?.language || 'en-GB'
}

export const currency_symbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
  NOK: 'kr',
} as const

export type SupportedCurrencyType = keyof typeof currency_symbols

export const language = getLang()

export const currency = (locale: string = 'GBP', style: string | undefined) =>
  new Intl.NumberFormat(language, {
    style,
    currency: locale,
    minimumFractionDigits: 2,
  })

export const formatCurrency = (
  pennyPrice: number,
  locale: string = 'GBP',
  style: string | undefined = undefined
) => currency(locale, style).format(pennyPrice / 100)

export const currencySymbol = (locale: SupportedCurrencyType = 'GBP') => {
  if (currency_symbols[locale]) {
    return currency_symbols[locale]
  }
  return currency_symbols.GBP
}

export const addTrailingZeros = (
  num: string | number = '',
  totalLength: number = 2
) => {
  return String(num).padEnd(totalLength, '0')
}
