import { Badge, Box, HStack, Stack, StackDivider, Text } from '@chakra-ui/react'
import { FunctionComponent } from 'react'
import { format, roundToNearestMinutes } from 'date-fns'
import { InboxThreadEventEventableIncomingFormSubmissionType } from '@/state/entities/inbox/inbox.types'
import { TimeFormats } from '@/common/datepicker/utilities/formats'

const ThreadIncomingFormSubmissionBox: FunctionComponent<{
  event: InboxThreadEventEventableIncomingFormSubmissionType
  type: string
}> = ({ event, type }) => {
  console.log({ event })
  console.log({ type })
  //i need to set the type here when stephen passes correct string for now i just make it submission
  return (
    <HStack align="end" justify="start" mr={24} role="group">
      <Stack>
        <Box>
          <Badge colorScheme="green" variant="subtle">
            Submission
          </Badge>
        </Box>
        <Box
          py={2}
          pl={5}
          bg="bg-muted"
          borderRadius="2xl"
          id="content"
          position="relative"
        >
          <Stack divider={<StackDivider />}>
            {event.entries.map((entry) => (
              <HStack
                pr={5}
                flexDirection={
                  (entry.answers ?? '').length > 24 ? 'column' : 'row'
                }
                key={entry.question}
                justify="space-between"
                align="baseline"
              >
                <Text textTransform="capitalize" fontWeight="bold">
                  {entry.question}
                </Text>
                <Text>{entry.answers}</Text>
              </HStack>
            ))}
          </Stack>
        </Box>
        <Text variant="desc" textAlign="right" id="time">
          {format(
            roundToNearestMinutes(event.created_at, {
              nearestTo: 10,
            }),
            TimeFormats.FourHourApple
          )}
        </Text>
      </Stack>
    </HStack>
  )
}

export default ThreadIncomingFormSubmissionBox
