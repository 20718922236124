import type { FunctionComponent, PropsWithChildren } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'

const OnlineStatusContext = createContext(true)

export const OnlineStatusProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true)
  const isOnline = useAppSelector((state) => state?.offline?.online ?? true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setOnlineStatus(isOnline)
    if (!isOnline) {
      dispatch({ type: 'PAUSE' })
    } else {
      dispatch({ type: 'RESUME' })
    }
  }, [dispatch, isOnline])

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  )
}

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext)
  return store
}
