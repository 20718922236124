interface PassedProps {
  error?: string
}

export function ErrorLabel({ error }: PassedProps) {
  if (!error) {
    return null
  }
  return (
    <p className="mt-1 float-right px-1 text-xs text-right text-white bg-red-500 rounded-lg absolute -top-3 right-2">
      {error}
    </p>
  )
}
