import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react'
import {
  Link,
  LinkProps,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

export const OrgLink: ForwardRefExoticComponent<
  LinkProps & RefAttributes<HTMLAnchorElement>
> = forwardRef((props, ref) => {
  const { org_id } = useParams()

  return <Link ref={ref} {...props} to={`/${org_id}${props.to}`} />
})

export const usePush = (): NavigateFunction => {
  const goto = useNavigate()
  // const { org_id } = useParams()
  /*
  const gotoPath: NavigateFunction = useCallback(
    (...props) => {
      if (typeof to !== 'string') {
        goto(to, options)
      }
      if (typeof to === 'string') {
        if (!to.startsWith('/')) {
          goto(to, options)
        }
      }
      goto(`/${org_id}${to}`, options)
      return goto
    },
    [goto, org_id]
  )
*/
  return goto
}

export const usePathname = () => {
  const { org_id } = useParams()
  const { pathname } = useLocation()
  return `${pathname.replace(`/${org_id}`, '')}`
}
