import { FunctionComponent } from 'react'
import { InboxThreadEventEventableSmsMessage } from '@/state/entities/inbox/inbox.types'
import { IconButton } from '@chakra-ui/react'
import { Box, HStack, Text, Icon } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format, roundToNearestMinutes } from 'date-fns'
import { ThreadPopover } from './common'

const ThreadSmsBox: FunctionComponent<{
  threadId: string
  event: InboxThreadEventEventableSmsMessage
}> = ({ event }) => {
  console.log({ event })
  return (
    <>
      <HStack align="end" justify="start" mr={24} role="group">
        <Box boxSize="32px" minW="32px" display="none"></Box>

        <Box>
          <Box
            py={1}
            px={5}
            bg="border"
            borderRadius="2xl"
            borderBottomLeftRadius="none"
            position="relative"
          >
            <ThreadPopover event={event}>
              <IconButton
                opacity={0}
                right={0}
                _groupHover={{
                  opacity: 1,
                  right: '-32px',
                }}
                position="absolute"
                transition="ease-in-out left  250ms, ease-in opacity 250ms"
                variant="ghost"
                isRound
                icon={<Icon as={InformationCircleIcon} />}
                aria-label="info"
              />
            </ThreadPopover>
            <Text dangerouslySetInnerHTML={{ __html: event.message }} />
          </Box>
          <Text variant="desc" textAlign="left" id="time">
            {format(
              roundToNearestMinutes(event.created_at, {
                nearestTo: 10,
              }),
              TimeFormats.FourHourApple
            )}
          </Text>
        </Box>
      </HStack>
    </>
  )
}

export default ThreadSmsBox
