import { Image, Box, Icon, type BoxProps } from '@chakra-ui/react'
import { DocumentCheckIcon } from '@heroicons/react/16/solid'
import { type FunctionComponent } from 'react'
import { generateImage } from '@/helpers/image'
import { type InboxThreadEventEventableEmailType } from '@/state/entities/inbox/inbox.types'

interface PassedProps {
  attachment: InboxThreadEventEventableEmailType['attachments'][number]
}

//@todo - bring back with new icons
// const iconSwitcher = (contentType: string) => {
//   const firstPart = contentType.split('/')[0]
//   switch (firstPart) {
//     case 'video':
//     return faFileVideo
//     case 'application':
//     return faFilePdf
//     default:
//     return faFile
//   }
// }

const AttachmentIcon: FunctionComponent<PassedProps & BoxProps> = ({
  attachment,
  ...rest
}) => {
  console.log({ attachment })
  if (attachment.content_type.includes('image/')) {
    return (
      <Image
        bg="bg-muted"
        borderRadius="md"
        objectFit="cover"
        src={generateImage(attachment.url, 'tiny')}
        {...rest}
      />
    )
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bg="bg-muted"
      borderRadius="md"
      {...rest}
    >
      {/* <Icon boxSize="50%" as={iconSwitcher(attachment.content_type)} /> */}
      <Icon boxSize="50%" as={DocumentCheckIcon} />
    </Box>
  )
}

export default AttachmentIcon
