import { CheckIcon, ChevronDownIcon } from '@heroicons/react/16/solid'
import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { cx, Badge, TStack, cn } from 'tailwind-ui'
import { Combobox } from '@headlessui/react'
import useGlobal from '@/hooks/useGlobal'
import inboxApi from '@/state/inbox/inbox.slice'
import Label from 'tailwind-ui/src/forms/labels/label'
import { useQueryParams, withDefault, StringParam } from 'use-query-params'
import { InboxTagType } from '@/state/entities/inbox/inbox.types'

interface PassedProps {
  existingTags?: InboxTagType[]
  onCreate?: (v: string) => void
  label?: string
  row?: boolean
  threadId?: string
  showExisting?: boolean
}

export function ThreadTagCreateSelect({
  label,
  row,
  onCreate,
  existingTags = [],
  threadId,
  showExisting = false,
}: PassedProps) {
  const [query, setQuery] = useQueryParams({
    inbox_thread_tag: withDefault(StringParam, ''),
    tags_query: withDefault(StringParam, ''),
  })

  const [inputValue, setInputValue] = useState(query.tags_query ?? '')
  const [cursor, setCursor] = useState('')
  const [bottomRef, isInView] = useInView()
  const { orgId } = useGlobal()

  const { data: tags } = inboxApi.useGetThreadTagsQuery({
    orgId,
    query: { tags_query: inputValue, cursor },
  })

  const [deleteTag] = inboxApi.useDeleteThreadTagMutation()

  useEffect(() => {
    if (!isInView) return
    if (!tags?.next_cursor) return
    setCursor(tags.next_cursor)
  }, [isInView, tags?.next_cursor])

  return (
    <>
      {showExisting && existingTags.length > 0 ? (
        <div className="mb-2 space-y-1">
          <Label>Current tags</Label>
          <TStack
            horizontal
            className={cn(
              'gap-x-2 gap-y-1 flex-wrap max-w-xl items-start pr-2 py-0'
            )}
          >
            {existingTags.map((tag) => (
              <Badge
                key={tag.id}
                className="rounded-md font-light"
                action={() => {
                  if (!threadId) return
                  deleteTag({ orgId, tagId: tag.id, threadId })
                }}
              >
                {tag.name}
              </Badge>
            ))}
          </TStack>
        </div>
      ) : null}
      <Combobox
        as="div"
        className={row ? 'flex items-center justify-between' : 'space-y-1'}
        onChange={
          onCreate
            ? (ev) => {
                onCreate(ev)
                setQuery({ tags_query: '' })
              }
            : (ev) => setQuery({ inbox_thread_tag: ev, tags_query: ev })
        }
        value={
          onCreate
            ? query.tags_query
            : query.tags_query || query.inbox_thread_tag
        }
      >
        {label ? (
          <Combobox.Label>
            <Label>{label}</Label>
          </Combobox.Label>
        ) : null}
        <div className="relative">
          <Combobox.Button as="div">
            <Combobox.Input
              className="w-full rounded-sm border-0 h-10 pl-3 pr-10  ring-2 ring-gray-200 dark:ring-gray-700 sm:text-lg sm:leading-6 placeholder:text-gray-800 dark:placeholder:text-white dark:bg-gray-900 focus:border-stampede-500 focus:border focus:ring-stampede focus:outline-2 focus:outline-offset-2 focus:outline-stampede-500"
              displayValue={inputValue}
              onChange={({ target }) => {
                setCursor('')
                setInputValue(target.value)
                setQuery({ tags_query: target.value })
              }}
              placeholder={onCreate ? '' : 'Show all'}
            />
          </Combobox.Button>
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </Combobox.Button>
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-black py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {onCreate ? (
              <Combobox.Option
                className={({ active }) =>
                  cx(
                    'relative cursor-default select-none py-2 pl-8 pr-4',
                    active ? 'bg-stampede text-white' : ''
                  )
                }
                disabled={
                  !inputValue ||
                  tags?.data.map((t) => t.name).includes(inputValue)
                }
                key=""
                onClick={() => {
                  onCreate(inputValue)
                }}
                value={null}
              >
                {({ selected, disabled }) => (
                  <span
                    className={cx(
                      'block truncate',
                      selected && 'font-semibold',
                      disabled && 'cursor-not-allowed opacity-50'
                    )}
                  >
                    Create tag{' '}
                    <span className="font-semibold">
                      {!disabled ? `\`${inputValue}\`` : ''}
                    </span>
                  </span>
                )}
              </Combobox.Option>
            ) : null}
            {[{ id: '', name: '' }, ...(tags?.data ?? [])].map(
              (item, index) => (
                <Combobox.Option
                  className={({ active }) =>
                    cx(
                      'relative cursor-default select-none py-2 pl-8 pr-4',
                      active ? 'bg-stampede text-white' : '',
                      !item.name && onCreate && 'hidden '
                    )
                  }
                  key={index}
                  value={item.name}
                  disabled={existingTags.map((t) => t.name).includes(item.name)}
                >
                  {({ active, selected, disabled }) => (
                    <>
                      <span
                        className={cx(
                          'block truncate',
                          selected && 'font-semibold',
                          disabled && 'cursor-not-allowed opacity-50'
                        )}
                      >
                        {item.name || 'Show all'}
                      </span>

                      {selected ? (
                        <span
                          className={cx(
                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            active ? 'text-white' : 'text-indigo-600'
                          )}
                        >
                          <CheckIcon aria-hidden="true" className="h-5 w-5" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              )
            )}
            {tags?.next_cursor ? (
              <>
                <Combobox.Option
                  className="relative cursor-default select-none py-2  flex justify-center font-semibold"
                  key="cursor-pagination"
                  value="cursor-pagination"
                >
                  Show more
                </Combobox.Option>
                <div ref={bottomRef} />
              </>
            ) : null}
          </Combobox.Options>
        </div>
      </Combobox>
    </>
  )
}
