import { ConnectionAnalyticsResponseType } from '@/connect-types/analytics/connectionAnalytics.type'
import { CustomerAnalyticsResponse } from '@/connect-types/analytics/customerAnalytics.type'
import {
  DeviceAnalyticsResponseType,
  DeviceBrowserAnalyticsResponseType,
  DeviceOsAnalyticsResponseType,
} from '@/connect-types/analytics/deviceAnalytics.type'
import { ImpressionAnalyticsResponseType } from '@/connect-types/analytics/impressionAnalytics.type'
import { MarketingAnalytics } from '@/connect-types/analytics/marketingAnalytics.type'
import { MarketingDeliverableAnalyticsResponseType } from '@/connect-types/analytics/marketingDeliverableAnalytics.type'
import { MultisiteAnalytics } from '@/connect-types/analytics/multisiteAnalytics.type'
import {
  OptInMarketingResponse,
  OptInResponse,
} from '@/connect-types/analytics/optInAnalytics.type'
import { PaymentAnalyticsResponseType } from '@/connect-types/analytics/paymentAnalytics.type'
import { RegistrationAnalyticsResponseType } from '@/connect-types/analytics/registrationAnalytics.type'
import { DefaultAnalyticsRequest } from '@/connect-types/analytics/request.type'
import { MarketingGetRequest } from '@/connect-types/marketing/marketing.type'
import { createAction, createAsyncAction } from 'typesafe-actions'
import { OrganisationRegistrationReportType } from '../insight/insight.type'
import { InteractionRequestType } from '../interactions/interactions.types'

export const getMarketingAnalytics = createAction(
  'ANALYTICS_MARKETING_REQUEST',
  (resolve) => (request: MarketingGetRequest) => resolve({ request })
)

export const getMarketingAnalyticsSuccess = createAction(
  'ANALYTICS_MARKETING_REQUEST_SUCCESS',
  (resolve) => (overview: MarketingAnalytics) => resolve({ overview })
)

export const getMarketingAnalyticsFailure = createAction(
  'ANALYTICS_MARKETING_FAILURE',
  (resolve) => resolve
)

export const getCustomerAnalytics = createAction(
  'ANALYTICS_CUSTOMER_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getCustomerAnalyticsSuccess = createAction(
  'ANALYTICS_CUSTOMER_REQUEST_SUCCESS ',
  (resolve) => (overview: CustomerAnalyticsResponse) => resolve({ overview })
)

export const getCustomerAnalyticsFailure = createAction(
  'ANALYTICS_CUSTOMER_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getRegistrationAnalytics = createAction(
  'ANALYTICS_REGISTRATION_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getRegistrationAnalyticsSuccess = createAction(
  'ANALYTICS_REGISTRATION_REQUEST_SUCCESS',
  (resolve) => (overview: RegistrationAnalyticsResponseType) =>
    resolve({ overview })
)

export const getRegistrationAnalyticsFailure = createAction(
  'ANALYTICS_REGISTRATION_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getConnectionAnalytics = createAction(
  'ANALYTICS_CONNECTION_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getConnectionAnalyticsSuccess = createAction(
  'ANALYTICS_CONNECTION_REQUEST_SUCCESS',
  (resolve) => (overview: ConnectionAnalyticsResponseType) =>
    resolve({ overview })
)

export const getConnectionAnalyticsFailure = createAction(
  'ANALYTICS_CONNECTION_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getPaymentAnalytics = createAction(
  'ANALYTICS_PAYMENT_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getPaymentAnalyticsSuccess = createAction(
  'ANALYTICS_PAYMENT_REQUEST_SUCCESS',
  (resolve) => (overview: PaymentAnalyticsResponseType) => resolve({ overview })
)

export const getPaymentAnalyticsFailure = createAction(
  'ANALYTICS_PAYMENT_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getDeviceAnalytics = createAction(
  'ANALYTICS_DEVICE_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getDeviceAnalyticsSuccess = createAction(
  'ANALYTICS_DEVICE_REQUEST_SUCCESS',
  (resolve) => (overview: DeviceAnalyticsResponseType) => resolve({ overview })
)

export const getDeviceAnalyticsFailure = createAction(
  'ANALYTICS_DEVICE_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getBrowserDeviceAnalytics = createAction(
  'ANALYTICS_DEVICE_BROWSER_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getBrowserDeviceAnalyticsSuccess = createAction(
  'ANALYTICS_DEVICE_BROWSER_REQUEST_SUCCESS',
  (resolve) => (overview: DeviceBrowserAnalyticsResponseType) =>
    resolve({ overview })
)

export const getBrowserDeviceAnalyticsFailure = createAction(
  'ANALYTICS_DEVICE_BROWSER_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getOsDeviceAnalytics = createAction(
  'ANALYTICS_DEVICE_OS_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getOsDeviceAnalyticsSuccess = createAction(
  'ANALYTICS_DEVICE_OS_REQUEST_SUCCESS',
  (resolve) => (overview: DeviceOsAnalyticsResponseType) =>
    resolve({ overview })
)

export const getOsDeviceAnalyticsFailure = createAction(
  'ANALYTICS_DEVICE_OS_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getImpressionAnalytics = createAction(
  'ANALYTICS_IMPRESSION_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getImpressionAnalyticsSuccess = createAction(
  'ANALYTICS_IMPRESSION_REQUEST_SUCCESS',
  (resolve) => (overview: ImpressionAnalyticsResponseType) =>
    resolve({ overview })
)

export const getImpressionAnalyticsFailure = createAction(
  'ANALYTICS_IMPRESSION_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getOptinAnalytics = createAction(
  'ANALYTICS_OPTIN_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getOptinAnalyticsSuccess = createAction(
  'ANALYTICS_OPTIN_REQUEST_SUCCESS',
  (resolve) => (overview: OptInResponse) => resolve({ overview })
)

export const getOptinAnalyticsFailure = createAction(
  'ANALYTICS_OPTIN_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getOptinMarketingAnalytics = createAction(
  'ANALYTICS_OPTIN_MARKETING_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getOptinMarketingAnalyticsSuccess = createAction(
  'ANALYTICS_OPTIN_MARKETING_REQUEST_SUCCESS',
  (resolve) => (overview: OptInMarketingResponse) => resolve({ overview })
)

export const getOptinMarketingAnalyticsFailure = createAction(
  'ANALYTICS_OPTIN_MARKETING_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getMarketingDeliverableAnalytics = createAction(
  'ANALYTICS_MARKETING_DELIVERABLE_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest) => resolve({ request })
)

export const getMarketingDeliverableAnalyticsSuccess = createAction(
  'ANALYTICS_MARKETING_DELIVERABLE_REQUEST_SUCCESS',
  (resolve) => (overview: MarketingDeliverableAnalyticsResponseType) =>
    resolve({ overview })
)

export const getMarketingDeliverableAnalyticsFailure = createAction(
  'ANALYTICS_MARKETING_DELIVERABLE_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getMultisiteAnalytics = createAction(
  'ANALYTICS_MULTISITE_REQUEST',
  (resolve) => (request: DefaultAnalyticsRequest, orgId: string) =>
    resolve({ request, orgId })
)

export const getMultisiteAnalyticsSuccess = createAction(
  'ANALYTICS_MULTISITE_REQUEST_SUCCESS',
  (resolve) => (overview: MultisiteAnalytics) => resolve({ overview })
)

export const getMultisiteAnalyticsFailure = createAction(
  'ANALYTICS_MULTISITE_REQUEST_FAILURE',
  (resolve) => resolve
)

export const getOrganisationRegistrationsTotals = createAsyncAction(
  'GET_INTERACTIONS_REGISTRATION_TOTALS',
  'GET_INTERACTIONS_REGISTRATION_TOTALS_SUCCESS',
  'GET_INTERACTIONS_REGISTRATION_TOTALS_FAILURE'
)<
  { orgId: string; query: InteractionRequestType },
  OrganisationRegistrationReportType,
  Error
>()
