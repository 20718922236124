import * as Sentry from '@sentry/react'
import type { RouteObject } from 'react-router-dom'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { SButton, SNavLink, Spinner, StampedeWordmark } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { newRoutesArray } from '@/paths'
import config from './config'
import type { NormalRoutesType } from './types'

function ErrorBoundary() {
  const error = useRouteError()
  const isChunkError =
    error.toString().includes('ChunkLoad') ||
    error.toString().includes('Loading CSS chunk') ||
    error.toString().includes('Failed to fetch dynamically imported module') ||
    error.toString().includes(`'text/html' is not a valid JavaScript MIME`)

  if (isRouteErrorResponse(error) && error.status === 401) {
    // the response json is automatically parsed to
    // `error.data`, you also have access to the status
    return (
      <div>
        <h1>{error.status}</h1>
        <h2>{error.data.sorry}</h2>
        <p>{error.data.hrEmail} if this is a mistake.</p>
      </div>
    )
  }

  if (isRouteErrorResponse(error) && error.status === 404) {
    return (
      <div className="bg-white dark:bg-black">
        <main className="mx-auto w-full max-w-7xl px-6 pb-16 pt-10 sm:pb-24 lg:px-8">
          <div className="mx-auto text-center">
            <StampedeWordmark />
          </div>

          <div className="mx-auto mt-20 max-w-2xl text-center sm:mt-24">
            <p className="text-base font-semibold leading-8 text-stampede">
              404
            </p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight  sm:text-5xl">
              This page does not exist
            </h1>
            <p className="mt-4 text-base leading-7 text-gray-400 sm:mt-6 sm:text-lg sm:leading-8">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
          </div>
          <div className="mx-auto mt-16 flow-root max-w-lg sm:mt-20">
            <h2 className="sr-only">Popular pages</h2>
            <ul className="-mt-6 divide-y divide-gray-900/5 dark:divide-gray-900">
              <li className="relative flex gap-x-6 py-6">
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                  <svg
                    aria-hidden="true"
                    className="h-6 w-6 text-stampede"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      clipRule="evenodd"
                      d="M6 3a3 3 0 00-3 3v12a3 3 0 003 3h12a3 3 0 003-3V6a3 3 0 00-3-3H6zm1.5 1.5a.75.75 0 00-.75.75V16.5a.75.75 0 001.085.67L12 15.089l4.165 2.083a.75.75 0 001.085-.671V5.25a.75.75 0 00-.75-.75h-9z"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex-auto">
                  <h3 className="text-sm font-semibold leading-6 ">
                    <a href="https://help.stampede.ai/">
                      <span aria-hidden="true" className="absolute inset-0" />
                      Guides
                    </a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-400">
                    Installation guides that cover popular setups.
                  </p>
                </div>
                <div className="flex-none self-center">
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      clipRule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </li>
              <li className="relative flex gap-x-6 py-6">
                <div className="flex h-10 w-10 flex-none items-center justify-center rounded-lg shadow-sm ring-1 ring-gray-900/10">
                  <svg
                    aria-hidden="true"
                    className="h-6 w-6 text-stampede"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      clipRule="evenodd"
                      d="M3.75 4.5a.75.75 0 01.75-.75h.75c8.284 0 15 6.716 15 15v.75a.75.75 0 01-.75.75h-.75a.75.75 0 01-.75-.75v-.75C18 11.708 12.292 6 5.25 6H4.5a.75.75 0 01-.75-.75V4.5zm0 6.75a.75.75 0 01.75-.75h.75a8.25 8.25 0 018.25 8.25v.75a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75v-.75a6 6 0 00-6-6H4.5a.75.75 0 01-.75-.75v-.75zm0 7.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="flex-auto">
                  <h3 className="text-sm font-semibold leading-6 ">
                    <a href="https://stampede.ai/blog">
                      <span aria-hidden="true" className="absolute inset-0" />
                      Blog
                    </a>
                  </h3>
                  <p className="mt-2 text-sm leading-6 text-gray-400">
                    Read our latest news and articles.
                  </p>
                </div>
                <div className="flex-none self-center">
                  <svg
                    aria-hidden="true"
                    className="h-5 w-5 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      clipRule="evenodd"
                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
              </li>
            </ul>
            <div className="mt-10 flex justify-center">
              <a
                className="text-sm font-semibold leading-6 text-stampede"
                href={
                  config.prod
                    ? 'https://product.stampede.ai'
                    : 'https://product.stage.stampede.ai'
                }
              >
                <span aria-hidden="true">&larr;</span>
                Back to product
              </a>
            </div>
          </div>
        </main>
        <footer className="border-t  py-6 sm:py-10">
          <div className="mx-auto flex max-w-7xl flex-col items-center justify-center gap-8 px-6 sm:flex-row lg:px-8">
            <p className="text-sm leading-7 text-gray-400">
              &copy; Stampede. All rights reserved.
            </p>
            <div className="hidden sm:block sm:h-7 sm:w-px sm:flex-none sm:bg-gray-200 dark:sm:bg-gray-800" />
            <div className="flex gap-x-4">
              <a
                className="text-gray-400 hover:text-gray-400"
                href="https://twitter.com/stampede_speaks"
              >
                <span className="sr-only">Twitter</span>
                <svg
                  aria-hidden="true"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>

              <a
                className="text-gray-400 hover:text-gray-400"
                href="https://www.instagram.com/stampede_ig"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  aria-hidden="true"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    clipRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    fillRule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    )
  }
  if (isChunkError) {
    window.location.reload()
    return (
      <div className="size-full flex items-center justify-center">
        <Spinner />
      </div>
    )
  }
  Sentry.captureException(error)

  console.error({ error })
  // rethrow to let the parent error boundary handle it
  // when it's not a special case for this route
  return (
    <div className="flex size-full items-center justify-center flex-col gap-2">
      <Heading>Ooops</Heading>
      <Description>
        Something went wrong loading this section. Please try reloading the page
      </Description>
      <div className="rounded-full gap-1 flex p-1">
        <SButton
          onClick={() => {
            window.location.reload()
          }}
        >
          Reload Page
        </SButton>
        <SNavLink to="/" variant="ghost_default">
          Reload App
        </SNavLink>
      </div>
    </div>
  )
}

const RoutesToItem = (routes: NormalRoutesType[]): RouteObject[] =>
  routes.map((item) => {
    const newItem: RouteObject = {
      ...(!item.index
        ? {
            handle: item.handle,
            children: RoutesToItem(item?.children ?? []),
          }
        : {
            index: item.index,
            handle: item.handle,
          }),

      ...(typeof item.component === 'string'
        ? {
            lazy: async () => {
              const Component = await import(
                item.component ? `./pages/${item.component}` : './pages'
              )
              return { Component: Component.default }
            },
          }
        : {
            element: item.component,
          }),
      path: item.path,
      errorElement: <ErrorBoundary />,
    }
    return newItem
  })

export const routes = RoutesToItem(newRoutesArray)
