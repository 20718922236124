import {
  differenceInDays,
  differenceInHours,
  format,
  formatDistance,
  parseISO,
  subHours,
} from 'date-fns'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { type BackendDate } from '../connect-types/date/date.type'
import { type TimeGroupTypes } from './TimeGenerator'

const formatBackendDateToLocal = (date: BackendDate | string) =>
  parseISO(typeof date === 'string' ? date : date.date)

export const fromNow = (date: BackendDate | string = '') => {
  if (!date) {
    return 'Never'
  }
  if (typeof date === 'string') {
    return formatDistance(parseISO(date), new Date(), {
      addSuffix: true,
      includeSeconds: true,
    })
  }

  if ('date' in date) {
    return formatDistance(parseISO(date.date), new Date(), {
      addSuffix: true,
      includeSeconds: true,
    })
  }

  return 'Never'
}

export const previousPeriodDates = (startDate: Date, endDate: Date) => {
  const diff = differenceInHours(startDate, endDate)
  return [subHours(startDate, diff), startDate] as const
}

export const fullDate = (date: BackendDate | string) => {
  if (!date) {
    return 'Unknown'
  }

  return format(
    formatBackendDateToLocal(date),
    TimeFormats.StandardDateAndDayMonthYear
  )
}

export const tableTime = (date: BackendDate | string) => {
  if (!date) {
    return 'Unknown'
  }

  return format(
    formatBackendDateToLocal(typeof date === 'string' ? date : date.date),
    TimeFormats.StandardWithTime
  )
}

export const formatDateFnCalculator = ([startDate, endDate]: [
  Date,
  Date,
]): string => {
  const days = differenceInDays(startDate, endDate)
  if (days > 365) {
    return TimeFormats.StandardDateMonthYear
  }

  if (days >= 149) {
    return TimeFormats.StandardDateMonthYear
  }

  if (days >= 30) {
    return TimeFormats.StandardDateMonthYear
  }

  if (days >= 8) {
    return TimeFormats.StandardDateMonthYear
  }

  if (days === 0) {
    return TimeFormats.StandardDateMonthYear
  }
  return TimeFormats.StandardDateMonthYear
}

export const formatCalculator = (
  days: number,
  groupBy?: TimeGroupTypes
): { formatting: string; tikFormatting: string } => {
  if (groupBy) {
    if (groupBy === 'hour') {
      return { formatting: 'haaa', tikFormatting: 'h aaa' }
    }

    if (groupBy === 'day') {
      return { formatting: 'EEE', tikFormatting: 'EEEE' }
    }

    if (groupBy === 'month') {
      return { formatting: 'MMM', tikFormatting: 'MMM' }
    }
  }

  if (days > 365) {
    return { formatting: 'MM/yy', tikFormatting: 'do MMM yyyy' }
  }

  if (days >= 149) {
    return { formatting: 'MM/yy', tikFormatting: 'MMM yyyy' }
  }

  if (days >= 30) {
    return { formatting: 'dd/MM', tikFormatting: 'do MMM yyyy' }
  }

  if (days >= 8) {
    return {
      formatting: 'EEE do',
      tikFormatting: 'EEE do MMM yyyy',
    }
  }

  if (days === 0) {
    return {
      formatting: 'HH:00',
      tikFormatting: 'HH:00 do MMM yyyy',
    }
  }

  return {
    formatting: 'HH:00',
    tikFormatting: 'HH:00 do MMM yyyy',
  }
}

export const createDateAsUTC = (date: Date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  )

export const convertDateToUTC = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

export const splitHoursMinutesTimeString = (time = '') => {
  const hours = parseInt(time.split(':')[0])
  const minutes = parseInt(time.split(':')[1])

  return {
    hours,
    minutes,
  }
}
