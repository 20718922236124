import { Avatar } from '@chakra-ui/avatar'
import { Box, Text } from '@chakra-ui/react'
import type { AutoCompleteRefMethods } from '@choc-ui/chakra-autocomplete'
import {
  AutoComplete,
  AutoCompleteCreatable,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteTag,
} from '@choc-ui/chakra-autocomplete'
import { FieldArray } from 'formik'
import debounce from 'lodash/debounce'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'
import useGlobal from '@/hooks/useGlobal'
import type { EventableContactType } from '@/state/entities/inbox/inbox.types'
import { getUserSearch } from '@/state/entities/organisation-user/organisation-user.actions'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import { gravatarImage } from '@/utils/common'
import { toast } from 'sonner'

function ValidateEmail(email = '') {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    String(email).toLowerCase()
  )
}

interface PassedProps {
  contacts: Partial<EventableContactType>[]
  formName: string
  onContactAdd?: (contact: EventableContactType) => void
  onContactRemove?: (contact: Partial<EventableContactType>) => void
}

/**
 * REQUIRES FORMIK PROVIDER CONTEXT
 */

const EmailContacts: FunctionComponent<PassedProps> = ({
  contacts,
  formName,
  onContactAdd,
  onContactRemove,
}) => {
  const autoCompleteRef = useRef<AutoCompleteRefMethods | null>(null)
  const dispatch = useAppDispatch()
  const { orgId } = useGlobal()

  const users = useAppSelector(
    ({ entities }) => entities.organisation_user.user_search
  )

  const [searchBy, setSearchBy] = useState('')
  const delayedSetSearch = useRef(debounce(setSearchBy, 400)).current

  useEffect(() => {
    if (!searchBy) return
    dispatch(getUserSearch({ search: searchBy, orgId }))
  }, [searchBy, dispatch, orgId])

  return (
    <FieldArray
      name={formName}
      render={(arrayHelpers) => (
        <AutoComplete
          closeOnSelect
          creatable
          multiple
          onChange={(v) => {
            console.log({ v })
          }}
          onSelectOption={(select) => {
            if (select.isNewInput) {
              const isValid = ValidateEmail(select.item.value)
              if (!isValid) {
                toast.warning(`Email ${select.item.value} format is invalid`)
                return
              }
              autoCompleteRef.current.resetItems()
              arrayHelpers.push({
                email: select.item.value,
                name: '',
              })
              if (onContactAdd) {
                onContactAdd({
                  email: select.item.value,
                  name: '',
                  id: null,
                  type: 'from',
                })
              }

              return
            }
            if (onContactAdd) {
              onContactAdd(select.item.originalValue)
            }

            autoCompleteRef.current.resetItems()
            arrayHelpers.push(select.item.originalValue)
          }}
          onTagRemoved={(toRemove) => {
            console.log({ toRemove })
          }}
          openOnFocus
          ref={autoCompleteRef}
          rollNavigation
        >
          <AutoCompleteInput
            flex="1"
            h="24px"
            minH="0"
            onChange={(val) => {
              delayedSetSearch(val.target.value)
            }}
            py={0}
            variant="unstyled"
          >
            {contacts.map((tag, tid) => (
              <AutoCompleteTag
                borderRadius="full"
                key={tid}
                label={tag.email}
                onRemove={() => {
                  if (onContactRemove) {
                    onContactRemove(tag)
                  }
                  arrayHelpers.remove(tid)
                }}
              />
            ))}
          </AutoCompleteInput>

          <AutoCompleteList minW="380px" className="!rounded-xl" py={2}>
            {users.data.map((option, oid) => (
              <AutoCompleteItem
                align="center"
                key={`option-${oid}`}
                className="!rounded-xl"
                value={{
                  email: option.email,
                  name: option.first ? `${option.first} ${option.last}` : '',
                }}
              >
                <Avatar
                  name={`${option.first} ${option.last}`}
                  size="sm"
                  src={gravatarImage(option.email, 'tiny')}
                />
                <Text ml={4}>
                  {option.first} {option.last} {`<${option.email}>`}
                </Text>
              </AutoCompleteItem>
            ))}
            <AutoCompleteCreatable>
              {({ value }) => <span>Add: {value}</span>}
            </AutoCompleteCreatable>
          </AutoCompleteList>
        </AutoComplete>
      )}
    />
  )
}

export default EmailContacts
