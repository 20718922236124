import { Reducer } from '@reduxjs/toolkit'
import { ActionType, getType } from 'typesafe-actions'
import { produce } from 'immer'

import { User } from '@/connect-types/user/user.type'

import * as actions from './auth.actions'

export type AuthAction = ActionType<typeof actions>

export interface StateType {
  readonly currentUser: User | null
  readonly usingUid: string
  readonly isRefreshingToken: boolean
  userLoading: boolean
}

const initialState: StateType = {
  currentUser: null,
  usingUid: 'me',
  isRefreshingToken: false,
  userLoading: false,
}

const authReducer: Reducer<StateType, AuthAction> = (
  state = initialState,
  action
) =>
  produce<StateType>(state, (draft) => {
    switch (action.type) {
      case getType(actions.setUserRole): {
        if (draft.currentUser) {
          draft.currentUser.role = action.payload.role
        }

        break
      }

      case getType(actions.fetchOauthTestRequest): {
        draft.userLoading = true
        break
      }
      /** USER_FETCH_CURRENT_SUCCESS */
      case getType(actions.fetchOauthTestRequestSuccess): {
        if (!Object.is(draft.currentUser, action.payload.currentUser)) {
          draft.currentUser = action.payload.currentUser
        }

        draft.userLoading = false
        break
      }
      /** USER_FETCH_CURRENT_ERROR */
      case getType(actions.fetchOauthTestRequestFailure): {
        draft.currentUser = null
        draft.userLoading = false
        break
      }
    }
  })

export default authReducer
