import { createApi, retry } from '@reduxjs/toolkit/query/react'
import config from '@/config'
import { axiosBaseQuery } from '../helpers'

const service = 'integration'

export interface ResDairyIntegrationResponse {
  pk: string
  username: string
  password: string
  venues: { res_diary_id: string; serial: string }[]
}
interface ResDairyIntegrationPayload {
  username: string
  password: string
  venues: { res_diary_id: string; serial: string }[]
}

const integrationsApi = createApi({
  reducerPath: service,
  tagTypes: ['booking-config'],
  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: config.url.integrations,
      service,
    }),
    {
      maxRetries: 5,
    }
  ),
  endpoints: (build) => ({
    getResDairyConfig: build.query<
      ResDairyIntegrationResponse,
      { org_id: string }
    >({
      query: ({ org_id }) => ({
        url: `/res-diary/${org_id}`,
        method: 'get',
      }),
    }),
    updateResDairyConfig: build.mutation<
      ResDairyIntegrationResponse,
      {
        org_id: string
        data: ResDairyIntegrationPayload
      }
    >({
      query: ({ org_id, data }) => ({
        url: `/res-diary/${org_id}`,
        method: 'put',
        data,
      }),
      invalidatesTags: (res) => {
        return [{ id: res?.pk, type: 'resdiary-config' }]
      },
    }),
  }),
})

export default integrationsApi
