import { GridItem, SimpleGrid } from '@chakra-ui/react'
import useGlobal from '@/hooks/useGlobal'
import { FunctionComponent, useMemo, useState } from 'react'
import {
  InboxContextTypes,
  initThread,
} from '@/state/entities/inbox/inbox.types'
import { useAppDispatch, useAppSelector } from '@/state/helpers/useApp'
import ThreadSendMessageBox from '../send/send-box'
import ThreadItemBox from '../thread'
import inboxApi from '@/state/inbox/inbox.slice'
export interface InboxContextProps {
  context_id: string
  context_type: InboxContextTypes
  serial?: string
  content?: string
}

const InboxContext: FunctionComponent<InboxContextProps> = ({
  context_id,
  context_type,
  serial,
  content,
}) => {
  const { orgId } = useGlobal()
  const [sendType, setSendType] = useState<'messages' | 'notes'>('messages')

  const { data: contextThreads } = inboxApi.useGetInboxThreadsQuery({
    orgId,
    query: {
      context_id,
      context_type,
      serial,
    },
  })

  const selectedThread = useMemo(() => {
    if (!contextThreads)
      return {
        ...initThread,
        fake_thread: true,
      }
    return (
      contextThreads.data.find((i) => i.context_id === context_id) || {
        ...initThread,
        fake_thread: true,
      }
    )
  }, [contextThreads])

  if (!selectedThread.id) {
    return <></>
  }

  return (
    <GridItem h="full" position="relative" overflow="hidden">
      <SimpleGrid
        h="full"
        columns={1}
        gridTemplateRows="1fr auto"
        bg="bg-default"
        className="overflow-hidden"
      >
        <GridItem
          overflowY="auto"
          p={4}
          overscrollBehaviorY="contain"
          scrollSnapType="y mandatory"
        >
          {!!selectedThread.id && (
            <ThreadItemBox threadId={selectedThread.id} />
          )}
        </GridItem>
        <GridItem zIndex={1}>
          {!!selectedThread && (
            <ThreadSendMessageBox
              onChangeSendType={setSendType}
              sendType={sendType}
              threadId={selectedThread.id}
              onComplete={() => {}}
              content={content}
            />
          )}
        </GridItem>
      </SimpleGrid>
    </GridItem>
  )
}

export default InboxContext
