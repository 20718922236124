import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/16/solid'
import { useCallback, useMemo } from 'react'
import Select from 'react-tailwindcss-select'
import { type SelectProps } from 'react-tailwindcss-select/dist/components/type'
import { SIconButton, cn } from 'tailwind-ui'

function BookingSelect({
  isMobile = false,
  isNextPrev = false,
  classNames,
  ...props
}: SelectProps & { isNextPrev?: boolean; isMobile?: boolean }) {
  const currentValueIndex = useMemo(() => {
    if (!props.value?.value) {
      return -1
    }
    const currentValue = props.value?.value

    if (props.options.length === 0) {
      return -1
    }

    const position = props.options.findIndex(
      (item) => item.value === currentValue
    )

    return position
  }, [props.value])

  const onPrev = useCallback(() => {
    if (currentValueIndex === -1) {
      return false
    }
    if (currentValueIndex !== 0) {
      props.onChange(props.options[currentValueIndex - 1])
    }
  }, [props.options, currentValueIndex])

  const onNext = useCallback(() => {
    if (currentValueIndex === -1) {
      return false
    }
    if (currentValueIndex !== props.options.length - 1) {
      props.onChange(props.options[currentValueIndex + 1])
    }
  }, [props.options, currentValueIndex])

  const canPrev = useMemo(() => {
    if (currentValueIndex === -1) {
      return false
    }
    return currentValueIndex !== 0
  }, [currentValueIndex])

  const canNext = useMemo(() => {
    if (currentValueIndex === -1) {
      return false
    }

    return currentValueIndex !== props.options.length - 1
  }, [currentValueIndex])

  return (
    <div className="flex space-x-1 w-full">
      {isNextPrev ? (
        <div>
          <SIconButton
            variant="ghost_default"
            disabled={!canPrev || props.loading}
            icon={ChevronLeftIcon}
            onClick={onPrev}
            className=" hover:bg-neutral-100/75 dark:hover:bg-neutral-800/75 dark:text-neutral-200 fill-brand"
            isRound
          />
        </div>
      ) : null}
      <Select
        classNames={{
          //  tagItem: () => cx('bg-pink-400'),

          ChevronIcon: (v) =>
            cn(
              'transition duration-300 w-6 h-6 p-0.5 text-stampede-300 rotate-90',
              {
                'fill-pink-500 text-pink-500': v?.open,
              }
            ),
          menuButton: ({ isDisabled }) =>
            cn(
              'text-sm ring-0 focus:border-interface-500 focus:ring-0 font-medium bg-neutral-50 dark:bg-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800 transition-all duration-300 focus:outline-none',
              'flex pl-2 pr-0 capitalize appearance-none rounded-lg min-h-10 py-0 form-input w-full border-transparent',
              'disabled:cursor-not-allowed disabled:opacity-50',
              {
                'focus:ring-brand focus:border-brand': !isDisabled,
                'focus:ring-gray-500 focus:border-gray-500': isDisabled,
              }
            ),
          list: 'max-h-72 overflow-y-auto p-1',
          tagItem: () =>
            cn(
              'bg-neutral-50 dark:bg-neutral-900 px-2 pr-0.5 rounded-button flex items-center'
            ),
          //   closeIcon: 'w-3 h-3 mt-0.5 rounded-full',
          listItem: (v) =>
            cn(
              'block  transition  duration-200 p-2  font-semibold cursor-pointer select-none truncate rounded-lg  hover:bg-brand hover:text-brand-invert',
              {
                'text-brand-invert bg-brand border': v?.isSelected,
                'border-transparent': !v?.isSelected,
              }
            ),
          closeIcon: 'w-3 h-3  rounded-full',
          listGroupLabel:
            'px-4 pb-1 pt-3 cursor-default select-none truncate font-bold text-gray-700 dark:text-gray-200',
          tagItemIcon: 'w-3 h-3',
          tagItemText: 'text-black dark:text-white',
          tagItemIconContainer:
            'flex items-center  ml-1 size-4 cursor-pointer justify-center rounded-full hover:bg-red-200 hover:text-red-600',
          searchContainer:
            'absolute flex items-center bottom-0 w-full lg:relative pt-4 lg:pt-0 flex-wrap',
          searchBox: `pl-12 rounded-none py-4 flex px-3 appearance-none form-input w-full border-0 border-t border-b text-black bg-transparent  dark:text-white 
          disabled:cursor-not-allowed disabled:opacity-50
          focus:ring-transparent focus:border-gray-300 dark:focus:border-gray-800`,

          searchIcon: 'absolute w-4 h-4  ml-4 text-gray-500',
          menu: cn(
            `overflow-visible mt-1 w-full fixed lg:absolute max-lg:bottom-0 max-lg:right-0 z-50 w-full bg-white dark:bg-black shadow-lg border rounded-t-xl rounded-xl  mt-4 mt-2 text-sm text-gray-700 dark:text-gray-400`,
            {
              'pb-14 lg:pb-0': props.isSearchable,
            }
          ),

          ...classNames,
        }}
        formatOptionLabel={(data) => (
          <div className="flex w-full items-center gap-2 rounded-lg py-1.5 px-3 hover:bg-black/10 dark:hover:bg-white/10 hover:cursor-pointer">
            <span>{data.label}</span>
          </div>
        )}
        {...props}
      />
      {isNextPrev ? (
        <div>
          <SIconButton
            disabled={!canNext || props.loading}
            icon={ChevronRightIcon}
            onClick={onNext}
            variant="ghost_default"
            className=" hover:bg-neutral-100/75 dark:hover:bg-neutral-800/75 dark:text-neutral-200 fill-brand"
            isRound
          />
        </div>
      ) : null}
    </div>
  )
}

export default BookingSelect
