import { useMemo, ReactNode } from 'react'

import ChartComponent from '@/components/AnalyticsPage/ChartComponent'
import { TimeGroupTypes } from '@/utils/TimeGenerator'
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Skeleton,
} from '@chakra-ui/react'
interface Props {
  children?: ReactNode
  title: string
  stat: number | string | any
  icon?: any
  subtext?: string
  substat?: number | string | any
  color?: string
  darkText?: boolean
  chart?: any
  shadow?: boolean
  chartGroup?: TimeGroupTypes
  showChart?: boolean
  isLoading?: boolean
}

const StandardAnalyticsCard = ({
  title,
  stat,
  icon,
  subtext,
  substat,
  color,
  darkText,
  chart,
  shadow,
  chartGroup,
  showChart,
  isLoading,
}: Props) => {
  const subStat = useMemo(() => `${subtext} ${substat}`, [substat, subtext])
  return (
    <>
      {!isLoading && (
        <Stat
          boxShadow={shadow ? 'md' : undefined}
          flexGrow={1}
          flexBasis="200px"
          flex="1"
          w="100%"
          minW="150px"
          bg={color ?? 'blue.500'}
          px={3}
          py={1}
          color={darkText ? 'black' : 'white'}
          borderRadius={8}
          mb={3}
        >
          <StatLabel
            textTransform="uppercase"
            fontSize="11px"
            fontWeight="700"
            opacity={0.6}
          >
            {icon} {title}
          </StatLabel>
          <StatNumber fontSize="30px" fontWeight="900">
            {!stat || (typeof stat === 'string' && stat.includes('undefined'))
              ? 0
              : stat}
          </StatNumber>
          {subtext && subStat && (
            <StatHelpText fontSize="10px" fontWeight="600" mb={0}>
              {subStat}
            </StatHelpText>
          )}
          {chart && showChart === true && (
            <ChartComponent
              yaxisName={title}
              groupBy={chartGroup}
              height={50}
              data={chart()}
            />
          )}
        </Stat>
      )}
      {isLoading && (
        <Skeleton
          borderRadius="md"
          flexGrow={1}
          flexBasis="200px"
          flex="1"
          w="100%"
          minW="150px"
          h="84px"
          mb={3}
        />
      )}
    </>
  )
}

export { StandardAnalyticsCard }
