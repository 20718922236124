import { IntegrationEnabled } from '@/connect-types/integrations/integrationEnabled.type'
import { ListSync } from '@/connect-types/integrations/listSync.type'
import { ListUnifyRequestBody } from '@/connect-types/integrations/listUnifyRequestBody'
import { UnifyUserCredentialCreateRequestBody } from '@/connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import { createAction } from 'typesafe-actions'

/**
 * GET Get the user's current details
 *
 * Path: /integration/{uid}/mailchimp
 */

export const integrationMailchimpRequest = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

export const integrationMailchimpRequestSuccess = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST_SUCCESS',
  (resolve) => (mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailchimpApiKey })
)

export const integrationMailchimpRequestFailure = createAction(
  'INTEGRATION_MAILCHIMP_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration mailchimp request failed' })
)
/**
 * PUT Create/update user credentials
 *
 * Path: /integration/{uid}/mailchimp
 */
export const updateIntegrationMailchimpRequest = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST',
  (resolve) =>
    (uid: string, mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
      resolve({ uid, mailchimpApiKey })
)

export const updateIntegrationMailchimpRequestSuccess = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST_SUCCESS',
  (resolve) => (mailchimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailchimpApiKey })
)

export const updateIntegrationMailchimpRequestFailure = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) =>
    resolve({
      error: errorResponse,
    })
)

/**
 *
 *
 *
 */

export const updateIntegrationMailchimpInStore = createAction(
  'UPDATE_INTEGRATION_MAILCHIMP_IN_STORE',
  (resolve) => (mailChimpApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ mailChimpApiKey })
)

/**
 * GET Get the user's current details
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const integrationCampaignMonitorRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const integrationCampaignMonitorRequestSuccess = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

export const integrationCampaignMonitorRequestFailure = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * PUT Create/update user credentials
 *
 * Path: /integration/{uid}/campaign-monitor
 */

export const updateIntegrationCampaignMonitorRequest = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST',
  (resolve) =>
    (
      uid: string,
      campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody
    ) =>
      resolve({ uid, campaignMonitorApiKey })
)

export const updateIntegrationCampaignMonitorRequestSuccess = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

export const updateIntegrationCampaignMonitorRequestFailure = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 *
 *
 *
 */

export const updateIntegrationCampaignMonitorInStore = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_IN_STORE',
  (resolve) => (campaignMonitorApiKey: UnifyUserCredentialCreateRequestBody) =>
    resolve({ campaignMonitorApiKey })
)

/**
 *
 *
 *
 */

export const integrationCampaignMonitorListRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST',
  (resolve) => (uid: string, serial: string) => resolve({ uid, serial })
)

export const integrationCampaignMonitorListRequestSuccess = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST_SUCCESS',
  (resolve) => (campaignMonitorLists: ListUnifyRequestBody[]) =>
    resolve({ campaignMonitorLists })
)

export const integrationCampaignMonitorListRequestFailure = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_LIST_REQUEST_FAILURE',
  (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
)

/**
 * GET Get a specific list (in-depth)
 *
 * Path: /integration/{uid}/mailchimp/{serial}/{id}
 */

export const integrationCampaignMonitorSpecificListRequest = createAction(
  'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST',
  (resolve) => (uid: string, serial: string, id: string) =>
    resolve({ uid, serial, id })
)

export const integrationCampaignMonitorSpecificListRequestSuccess =
  createAction(
    'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_SUCCESS',
    (resolve) => (campaignMonitorList: ListSync) =>
      resolve({ campaignMonitorList })
  )

export const integrationCampaignMonitorSpecificListRequestFailure =
  createAction(
    'INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_FAILURE',
    (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
  )

/**
 *
 *
 *
 */

export const updateIntegrationCampaignMonitorSpecificListRequest = createAction(
  'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST',
  (resolve) =>
    (uid: string, serial: string, campaignMonitorListDetails: ListSync) =>
      resolve({ uid, serial, campaignMonitorListDetails })
)

export const updateIntegrationCampaignMonitorSpecificListRequestSuccess =
  createAction(
    'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_SUCCESS',
    (resolve) => (campaignMonitorListDetails: ListSync) =>
      resolve({ campaignMonitorListDetails })
  )

export const updateIntegrationCampaignMonitorSpecificListRequestFailure =
  createAction(
    'UPDATE_INTEGRATION_CAMPAIGN_MONITOR_SPECIFIC_LIST_REQUEST_FAILURE',
    (resolve) => (errorResponse: any) => resolve({ error: errorResponse })
  )

/**
 * GET Retrieve PayPal Account associated with the user
 *
 * Path: /integration/{uid}/paypal
 */

// FIXME: add in request and query params
export const integrationPaypalRequest = createAction(
  'INTEGRATION_PAYPAL_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

// FIXME: add in response object from saga
export const integrationPaypalRequestSuccess = createAction(
  'INTEGRATION_PAYPAL_REQUEST_SUCCESS',
  (resolve) => () => resolve()
)

export const integrationPaypalRequestFailure = createAction(
  'INTEGRATION_PAYPAL_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration paypal request failed' })
)

/**
 * GET Associate an SSID with a Location
 *
 * Path: /integration/{uid}/connected
 */
export const integrationEnabledRequest = createAction(
  'INTEGRATION_ENABLED_REQUEST',
  (resolve) => (uid: string) => resolve({ uid })
)

export const integrationEnabledRequestSuccess = createAction(
  'INTEGRATION_ENABLED_REQUEST_SUCCESS',
  (resolve) => (integrationEnabled: Partial<IntegrationEnabled>) =>
    resolve({ integrationEnabled })
)

export const integrationEnabledRequestFailure = createAction(
  'INTEGRATION_ENABLED_REQUEST_FAILURE',
  (resolve) => () => resolve({ error: 'integration enabled request failed' })
)
