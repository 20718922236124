'use client'

import { Transition } from '@headlessui/react'
import { cn } from 'tailwind-ui'

type TransProps = React.ComponentPropsWithoutRef<typeof Transition>
type Direction = 'left' | 'right' | 'up' | 'down'

interface TransitionConfig {
  enter: Direction
  leave: Direction
}


const directionToTransform = (direction: Direction) => {
  switch (direction) {
    case 'left': return '-translate-x-full'
    case 'right': return 'translate-x-full'
    case 'up': return '-translate-y-full'
    case 'down': return 'translate-y-full'
  } 
}

function BaseTransition({
  children,
  show,
  className,
  config,
  ...props
}: TransProps & { config: TransitionConfig }) {
  const enterTransform = directionToTransform(config.enter)
  const leaveTransform = directionToTransform(config.leave)

  return (
    <Transition show={show} {...props}>
      <div className={cn(
        // Base styles
        'transition ease-in-out',
        // Shared closed styles
        'data-[closed]:opacity-0',
        // Entering styles
        'data-[enter]:duration-300',
        `data-[enter]:data-[closed]:${enterTransform}`,
        // Leaving styles
        'data-[leave]:duration-200',
        `data-[leave]:data-[closed]:${leaveTransform}`,
        className
      )}>
        {children}
      </div>
    </Transition>
  )
}

// Simple fade without movement
export function FadeInOut({
  children,
  show,
  className,
  ...props
}: TransProps) {
  return (
    <Transition show={show} {...props}>
      <div className={cn(
        // Base styles
        'transition',
        // Entering styles
        'data-[enter]:duration-300 data-[enter]:ease-in',
        // Closed state
        'data-[closed]:opacity-0',
        // Leaving styles
        'data-[leave]:duration-300 data-[leave]:ease-in',
        className
      )}>
        {children}
      </div>
    </Transition>
  )
}

// Horizontal transitions
export function FadeInRightOutRight(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'right', leave: 'right' }} />
}

export function FadeInRightOutLeft(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'right', leave: 'left' }} />
}

export function FadeInLeftOutLeft(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'left', leave: 'left' }} />
}

export function FadeInLeftOutRight(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'left', leave: 'right' }} />
}

// Vertical transitions
export function FadeInAboveOutBelow(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'up', leave: 'down' }} />
}

export function FadeInAboveOutAbove(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'up', leave: 'up' }} />
}

export function FadeInBelowOutBelow(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'down', leave: 'down' }} />
}

export function FadeInBelowOutAbove(props: TransProps) {
  return <BaseTransition {...props} config={{ enter: 'down', leave: 'up' }} />
}
