import type { IntegrationEnabled } from '@/connect-types/integrations/integrationEnabled.type'
import type { UnifyUserCredentialCreateRequestBody } from '@/connect-types/integrations/unifyUserCredentialCreateRequestBody.type'
import * as loadedActions from '@/state/entities/loaded/loaded.actions'
import type { RootState } from '@/state/reducers'
import { get, put as putRequest } from '@/utils/api'
import { call, put, select, takeLeading } from 'redux-saga/effects'
import type { ActionType } from 'typesafe-actions'
import { getType } from 'typesafe-actions'
import * as actions from './integration.actions'

/* Get enabled
 * ========================================================================== */
function* integrationEnabledRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: IntegrationEnabled
    } = yield call(async () => get(`/integration/${orgId}/connected`))

    const integrationsEnabledDetails = response.message

    yield put(loadedActions.setLoaded('enabled_integrations', true))
    yield put(
      actions.integrationEnabledRequestSuccess(integrationsEnabledDetails)
    )
  } catch (error) {
    yield put(actions.integrationEnabledRequestFailure())
  }
}

export function* watchIntegrationEnabledRequest() {
  yield takeLeading(
    getType(actions.integrationEnabledRequest),
    integrationEnabledRequest
  )
}

/* Get MailChimp API Key Belonging To User
 * ========================================================================== */
function* integrationMailchimpRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () => get(`/integration/${orgId}/mailchimp`))

    const apikey = response.message

    yield put(actions.integrationMailchimpRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.integrationMailchimpRequestFailure())
  }
}

export function* watchIntegrationMailchimpRequest() {
  yield takeLeading(
    getType(actions.integrationMailchimpRequest),
    integrationMailchimpRequest
  )
}

/* Update MailChimp API Key Belonging To User
 * ========================================================================== */
function* integrationMailchimpPUTRequest(
  action: ActionType<typeof actions.updateIntegrationMailchimpRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { mailchimpApiKey } = action.payload

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/mailchimp`, mailchimpApiKey)
    )

    const apikey = response.message

    yield put(actions.updateIntegrationMailchimpRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.updateIntegrationMailchimpRequestFailure(error.response))
    yield put(actions.updateIntegrationMailchimpRequestFailure(error.response))
  }
}

export function* watchIntegrationMailchimpPUTRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationMailchimpRequest),
    integrationMailchimpPUTRequest
  )
}

/* Get Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationCampaignMonitorRequest() {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () => get(`/integration/${orgId}/campaignMonitor`))

    const apikey = response.message

    yield put(actions.integrationCampaignMonitorRequestSuccess(apikey))
  } catch (error) {
    yield put(actions.integrationCampaignMonitorRequestFailure(error.response))
  }
}

export function* watchIntegrationCampaignMonitorRequest() {
  yield takeLeading(
    getType(actions.integrationCampaignMonitorRequest),
    integrationCampaignMonitorRequest
  )
}

/* Update Campaign Monitor API Key Belonging To User
 * ========================================================================== */
function* integrationCampaignMonitorPUTRequest(
  action: ActionType<typeof actions.updateIntegrationCampaignMonitorRequest>
) {
  try {
    const { global }: RootState = yield select()
    const { orgId } = global
    const { campaignMonitorApiKey } = action.payload

    const response: {
      status: number
      message: UnifyUserCredentialCreateRequestBody
    } = yield call(async () =>
      putRequest(`/integration/${orgId}/campaignMonitor`, campaignMonitorApiKey)
    )

    const apikey = response.message

    yield put(actions.updateIntegrationCampaignMonitorRequestSuccess(apikey))
  } catch (error) {
    yield put(
      actions.updateIntegrationCampaignMonitorRequestFailure(error.response)
    )
  }
}

export function* watchIntegrationCampaignMonitorPUTRequest() {
  yield takeLeading(
    getType(actions.updateIntegrationCampaignMonitorRequest),
    integrationCampaignMonitorPUTRequest
  )
}

export default [
  watchIntegrationEnabledRequest,
  watchIntegrationMailchimpRequest,
  watchIntegrationMailchimpPUTRequest,
  watchIntegrationCampaignMonitorRequest,
  watchIntegrationCampaignMonitorPUTRequest,
]
