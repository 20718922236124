import { call, put, takeLatest, takeLeading } from 'redux-saga/effects'
import { ConnectionAnalyticsResponseType } from '@/connect-types/analytics/connectionAnalytics.type'
import { CustomerAnalyticsResponse } from '@/connect-types/analytics/customerAnalytics.type'
import {
  DeviceAnalyticsResponseType,
  DeviceBrowserAnalyticsResponseType,
  DeviceOsAnalyticsResponseType,
} from '@/connect-types/analytics/deviceAnalytics.type'
import { ImpressionAnalyticsResponseType } from '@/connect-types/analytics/impressionAnalytics.type'
import { MarketingAnalytics } from '@/connect-types/analytics/marketingAnalytics.type'
import { MarketingDeliverableAnalyticsResponseType } from '@/connect-types/analytics/marketingDeliverableAnalytics.type'
import { MultisiteAnalytics } from '@/connect-types/analytics/multisiteAnalytics.type'
import {
  OptInMarketingResponse,
  OptInResponse,
} from '@/connect-types/analytics/optInAnalytics.type'
import { PaymentAnalyticsResponseType } from '@/connect-types/analytics/paymentAnalytics.type'
import { RegistrationAnalyticsResponseType } from '@/connect-types/analytics/registrationAnalytics.type'
import { ActionType, getType } from 'typesafe-actions'
import { get } from '@/utils/api'
import { buildUrl } from '@/utils/common'
import * as actions from './analytics.actions'

function* getMarketingAnalytics(
  action: ActionType<typeof actions.getMarketingAnalytics>
) {
  try {
    const { id, serial, search, offset, orgId } = action.payload.request

    const params: any = {}

    if (id) {
      Object.assign(params, { campaignId: id })
    }

    if (serial) {
      Object.assign(params, { serial })
    }

    if (search) {
      Object.assign(params, { search })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    const response: {
      status: number
      message: MarketingAnalytics
    } = yield call(async () =>
      get(buildUrl(`/marketing/${orgId}/overview`, params))
    )

    yield put(actions.getMarketingAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getMarketingAnalyticsFailure(error))
  }
}

function* getCustomerAnalytics(
  action: ActionType<typeof actions.getCustomerAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      type,
    } = action.payload.request
    const url = `/locations/${serial}/insight/customer/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (type) {
      Object.assign(params, { type })
    }

    const response: {
      status: number
      message: CustomerAnalyticsResponse
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getCustomerAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getCustomerAnalyticsFailure(error))
  }
}

function* getRegistrationAnalytics(
  action: ActionType<typeof actions.getRegistrationAnalytics>
) {
  try {
    const { serial, start, end, line, totals, table, offset, exportData } =
      action.payload.request
    const url = `/locations/${serial}/insight/registrations/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    const response: {
      status: number
      message: RegistrationAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getRegistrationAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getRegistrationAnalyticsFailure(error))
  }
}

function* getConnectionAnalytics(
  action: ActionType<typeof actions.getConnectionAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      sort,
      order,
    } = action.payload.request
    const url = `/locations/${serial}/insight/connections/${start}/${end}`

    if (!serial) return

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (sort) {
      Object.assign(params, { sort })
    }

    if (order) {
      Object.assign(params, { order })
    }

    const response: {
      status: number
      message: ConnectionAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getConnectionAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getConnectionAnalyticsFailure(error))
  }
}

function* getMultisiteAnalytics(
  action: ActionType<typeof actions.getMultisiteAnalytics>
) {
  try {
    const { start, end, ...rest } = action.payload.request

    const params: any = {
      startDate: start,
      endDate: end,
      ...rest,
    }

    const response: MultisiteAnalytics = yield call(async () =>
      get(
        buildUrl(
          `/organisations/${action.payload.orgId}/locations/insights/overview`,
          params
        )
      )
    )

    yield put(actions.getMultisiteAnalyticsSuccess(response))
  } catch (error) {
    yield put(actions.getMultisiteAnalyticsFailure(error))
  }
}

function* getDeviceAnalytics(
  action: ActionType<typeof actions.getDeviceAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      type,
      order,
    } = action.payload.request
    const url = `/locations/${serial}/insight/devices/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (type) {
      Object.assign(params, { type })
    }

    if (order) {
      Object.assign(params, { order })
    }

    const response: {
      status: number
      message: DeviceAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getDeviceAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getDeviceAnalyticsFailure(error))
  }
}

function* getOsDeviceAnalytics(
  action: ActionType<typeof actions.getOsDeviceAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      type,
      order,
    } = action.payload.request
    const url = `/locations/${serial}/insight/os/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (type) {
      Object.assign(params, { type })
    }

    if (order) {
      Object.assign(params, { order })
    }

    const response: {
      status: number
      message: DeviceOsAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getOsDeviceAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getOsDeviceAnalyticsFailure(error))
  }
}

function* getBrowserDeviceAnalytics(
  action: ActionType<typeof actions.getBrowserDeviceAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      type,
      order,
    } = action.payload.request
    const url = `/locations/${serial}/insight/browser/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (type) {
      Object.assign(params, { type })
    }

    if (order) {
      Object.assign(params, { order })
    }

    const response: {
      status: number
      message: DeviceBrowserAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getBrowserDeviceAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getBrowserDeviceAnalyticsFailure(error))
  }
}

function* getImpressionAnalytics(
  action: ActionType<typeof actions.getImpressionAnalytics>
) {
  try {
    const { serial, start, end, line, totals, table, offset, exportData } =
      action.payload.request
    const url = `/locations/${serial}/insight/splashimpressions/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    const response: {
      status: number
      message: ImpressionAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getImpressionAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getImpressionAnalyticsFailure(error))
  }
}

function* getOptinAnalytics(
  action: ActionType<typeof actions.getOptinAnalytics>
) {
  try {
    const { serial, start, end, line, totals, table, offset, exportData } =
      action.payload.request
    const url = `/locations/${serial}/insight/gdpr/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    const response: {
      status: number
      message: OptInResponse
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getOptinAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getOptinAnalyticsFailure(error))
  }
}

function* getOptinMarketingAnalytics(
  action: ActionType<typeof actions.getOptinMarketingAnalytics>
) {
  try {
    const { serial, start, end, line, totals, table, offset, exportData } =
      action.payload.request
    const url = `/locations/${serial}/insight/marketingOptOut/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    const response: {
      status: number
      message: OptInMarketingResponse
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getOptinMarketingAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getOptinMarketingAnalyticsFailure(error))
  }
}

function* getPaymentAnalytics(
  action: ActionType<typeof actions.getPaymentAnalytics>
) {
  try {
    const { serial, start, end, line, totals, table, offset, exportData } =
      action.payload.request
    const url = `/locations/${serial}/insight/payments/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    const response: {
      status: number
      message: PaymentAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getPaymentAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getPaymentAnalyticsFailure(error))
  }
}

function* getMarketingDeliverablesAnalytics(
  action: ActionType<typeof actions.getMarketingDeliverableAnalytics>
) {
  try {
    const {
      serial,
      start,
      end,
      line,
      totals,
      table,
      offset,
      exportData,
      type,
      campaignId,
      eventType,
    } = action.payload.request
    const url = `/locations/${serial}/insight/marketingdeliverable/${start}/${end}`

    const params: any = {}

    if (line) {
      Object.assign(params, { line })
    }

    if (totals) {
      Object.assign(params, { totals })
    }

    if (table) {
      Object.assign(params, { table })
    }

    if (offset) {
      Object.assign(params, { offset })
    }

    if (exportData) {
      Object.assign(params, { export: exportData })
    }

    if (type) {
      Object.assign(params, { type })
    }

    if (campaignId) {
      Object.assign(params, { campaignId })
    }

    if (eventType) {
      Object.assign(params, { eventType })
    }

    const response: {
      status: number
      message: MarketingDeliverableAnalyticsResponseType
    } = yield call(async () => get(buildUrl(url, params)))

    yield put(actions.getMarketingDeliverableAnalyticsSuccess(response.message))
  } catch (error) {
    yield put(actions.getMarketingDeliverableAnalyticsFailure(error))
  }
}

export function* watchAnalytics() {
  yield takeLatest(
    getType(actions.getMarketingAnalytics),
    getMarketingAnalytics
  )
  yield takeLatest(getType(actions.getCustomerAnalytics), getCustomerAnalytics)
  yield takeLatest(
    getType(actions.getRegistrationAnalytics),
    getRegistrationAnalytics
  )
  yield takeLatest(
    getType(actions.getConnectionAnalytics),
    getConnectionAnalytics
  )
  yield takeLatest(getType(actions.getDeviceAnalytics), getDeviceAnalytics)
  yield takeLatest(
    getType(actions.getImpressionAnalytics),
    getImpressionAnalytics
  )
  yield takeLatest(getType(actions.getOsDeviceAnalytics), getOsDeviceAnalytics)
  yield takeLatest(
    getType(actions.getBrowserDeviceAnalytics),
    getBrowserDeviceAnalytics
  )
  yield takeLatest(getType(actions.getOptinAnalytics), getOptinAnalytics)
  yield takeLatest(
    getType(actions.getOptinMarketingAnalytics),
    getOptinMarketingAnalytics
  )

  yield takeLatest(getType(actions.getPaymentAnalytics), getPaymentAnalytics)
  yield takeLatest(
    getType(actions.getMarketingDeliverableAnalytics),
    getMarketingDeliverablesAnalytics
  )
  yield takeLeading(
    getType(actions.getMultisiteAnalytics),
    getMultisiteAnalytics
  )
}

export default [watchAnalytics]
