import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { cn } from '../..'

interface PassedProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size'
    >,
    VariantProps<typeof radioVariants> {
  error?: string
  label: string
  isError?: boolean
  isSuccess?: boolean
}

const radioVariants = cva(
  ['w-4 h-4 form-radio  bg-gray-100 border-gray-300 focus:ring-2'],
  {
    variants: {
      size: {
        sm: 'h-9 text-sm',
        base: 'h-4 w-4',
        lg: 'h-11',
        xl: 'h-13  text-lg',
      },
      brandKit: {
        true: 'text-brand focus:ring-brand',
        false: 'text-stampede-500 focus:ring-stampede-500',
      },
    },
    defaultVariants: {
      brandKit: false,
      size: 'base',
    },
  }
)

export const Radio = forwardRef<HTMLInputElement, PassedProps>(
  (
    {
      error,
      label = '',
      className,
      brandKit,
      isError,
      isSuccess,
      size,
      ...rest
    },
    ref
  ) => {
    return (
      <div className="flex items-center gap-4">
        <input
          className={cn(
            radioVariants({ brandKit, size, className }),
            isError && 'focus:border-red-500 focus:ring-red-500 border-red-500',
            isSuccess
              ? brandKit
                ? 'focus:ring-green-500 bg-green-50 border border-green-500 text-green-900 focus:border-green-500'
                : 'focus:ring-stampede-500 border border-stampede-500 focus:border-stampede-500'
              : null
          )}
          type="radio"
          ref={ref}
          {...rest}
          id={`${rest.id}_${rest.value}`}
        />
        <label className="w-full  flex-1" htmlFor={`${rest.id}_${rest.value}`}>
          {label}
        </label>
      </div>
    )
  }
)

Radio.displayName = 'Radio'
