import { type BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react'
import { toast } from 'sonner'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import { isEqual } from 'lodash'
import { parseISO } from 'date-fns'
import {
  type FlatServiceCursorPaginatedResponseType,
  type ServiceCursorPaginatedResponseType,
  ServiceErrorHandler,
  defaultServicePagination,
} from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import config from '@/config'
import { buildUrl } from '@/utils/common'
import { post } from '@/utils/api'
import {
  type InboxContextTypes,
  type InboxSettingsDataType,
  type InboxTagType,
  type InboxThreadAssigneeType,
  type InboxThreadContactsType,
  type InboxThreadEventType,
  type InboxThreadType,
  type VirtualNumberType,
} from '../entities/inbox/inbox.types'

/*
  php artisan list

  GET|HEAD  / App\Packages\Healthcheck\Controllers\HealthcheckController@index
  POST      _ignition/execute-solution ignition.executeSolution › Spatie\Lara…
  GET|HEAD  _ignition/health-check ignition.healthCheck › Spatie\LaravelIgnit…
  POST      _ignition/update-config ignition.updateConfig › Spatie\LaravelIgn…
  GET|HEAD  health-check App\Packages\Healthcheck\Controllers\HealthcheckCont…
  GET|HEAD  health-check/cache App\Packages\Healthcheck\Controllers\Healthche…
  GET|HEAD  health-check/error App\Packages\Healthcheck\Controllers\Healthche…
  GET|HEAD  health-check/log App\Packages\Healthcheck\Controllers\Healthcheck…
  GET|HEAD  health-check/migrate App\Packages\Healthcheck\Controllers\Healthc…
  GET|HEAD  health-check/queue-error App\Packages\Healthcheck\Controllers\Hea…
  GET|HEAD  public/{orgId}/emails/{emailId}/attachments/{attachmentId} public…
  GET|HEAD  {orgId}/settings App\Packages\Settings\Controllers\SettingsContro…
  PUT       {orgId}/settings App\Packages\Settings\Controllers\SettingsContro…
  GET|HEAD  {orgId}/tags .. App\Packages\Tags\Controllers\TagsController@index
  DELETE    {orgId}/tags/{id} App\Packages\Tags\Controllers\TagsController@de…
  GET|HEAD  {orgId}/threads App\Packages\Threads\Controllers\ThreadsControlle…
  POST      {orgId}/threads App\Packages\Threads\Controllers\ThreadsControlle…
  GET|HEAD  {orgId}/threads/unread-counts App\Packages\Threads\Controllers\Th…
  GET|HEAD  {orgId}/threads/{threadId} App\Packages\Threads\Controllers\Threa…
  PUT       {orgId}/threads/{threadId} App\Packages\Threads\Controllers\Threa…
  PUT       {orgId}/threads/{threadId}/archive-thread App\Packages\Threads\Co…
  GET|HEAD  {orgId}/threads/{threadId}/contacts App\Packages\Threads\Controll…
  POST      {orgId}/threads/{threadId}/contacts App\Packages\Threads\Controll…
  DELETE    {orgId}/threads/{threadId}/contacts/{threadContactId} App\Package…
  GET|HEAD  {orgId}/threads/{threadId}/events App\Packages\Threads\Controller…
  GET|HEAD  {orgId}/threads/{threadId}/events/{threadEventId} App\Packages\Th…
  POST      {orgId}/threads/{threadId}/messages App\Packages\Threads\Controll…
  POST      {orgId}/threads/{threadId}/notes App\Packages\Threads\Controllers…
  PUT       {orgId}/threads/{threadId}/read-status App\Packages\Threads\Contr…
  POST      {orgId}/threads/{threadId}/tags App\Packages\Threads\Controllers\…
  PUT       {orgId}/threads/{threadId}/unarchive-thread App\Packages\Threads\…
  GET|HEAD  {orgId}/threads/{threadId}/viewers App\Packages\Threads\Controlle…
  GET|HEAD  {orgId}/virtual-mobile-numbers App\Packages\VirtualMobileNumbers\…

*/

export const dateParse = (date: Date | string = '') => {
  if (typeof date === 'string') return parseISO(date)
  return date
}

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      if (method !== 'get' && err.response.status !== 401) {
        ServiceErrorHandler(axiosError)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

const inboxApi = createApi({
  reducerPath: 'inbox',
  refetchOnFocus: true,
  baseQuery: axiosBaseQuery({ baseUrl: config.url.inbox }),
  tagTypes: [
    'counts',
    'settings',
    'threads',
    'selected_thread',
    'thread_events',
  ],
  endpoints: (build) => ({
    getUnreadCount: build.query<
      { closed: number; open: number },
      { orgId: string; serial?: string }
    >({
      query: ({ orgId, serial }) => ({
        method: 'GET',
        url: buildUrl(`/${orgId}/threads/unread-counts`, {
          serial,
        }),
        providesTags: (_1, _2, meta) => [
          { type: 'counts', id: `${meta.orgId}_${meta.serial}` },
        ],
      }),
    }),

    createNewEmailThread: build.mutation<
      {
        thread: InboxThreadType
      },
      {
        orgId: string
        data: {
          contacts: { email: string; name: string }[]
          serial?: string
          subject: string
          content: string

          context_type?: InboxContextTypes
          context_id?: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/${orgId}/threads`,
        method: 'POST',
        data: {
          subject: data.subject,
          serial: data.serial,
          context_id: data.context_id,
          context_type: data.context_type,
        },
      }),
      async onQueryStarted(
        { orgId, data: argData },
        { dispatch, queryFulfilled }
      ) {
        const { data } = await queryFulfilled
        const newThread = {
          ...data.thread,
          created_at: dateParse(data.thread.created_at),
          updated_at: dateParse(data.thread.updated_at),
          last_thread_event_at: dateParse(data.thread.last_thread_event_at),
          contacts: [],
          events:
            defaultServicePagination as unknown as ServiceCursorPaginatedResponseType<InboxThreadEventType>,
        } as InboxThreadType

        for await (const contact of argData.contacts) {
          const createContact = await post<{
            thread_contact: InboxThreadContactsType
          }>(
            `/${orgId}/threads/${newThread.id}/contacts`,
            {
              // method: 'email',
              ...contact,
            },
            null,
            'inbox'
          )

          newThread.contacts.push(createContact.thread_contact)
        }

        const threadEvent = await post<{
          message: string
          thread_event: InboxThreadEventType
        }>(
          `/${orgId}/threads/${newThread.id}/messages`,
          { description: argData.content, format: 'html' },
          null,
          'inbox'
        )

        // newThread.events.data.push(threadEvent.thread_event)
      },
      invalidatesTags: [{ type: 'threads', id: 'LIST' }],
    }),

    getInboxThreads: build.query<
      ServiceCursorPaginatedResponseType<InboxThreadType>,
      {
        orgId: string
        query?: {
          archived?: boolean
          serial?: string
          context_type?: InboxContextTypes
          context_id?: string
          cursor?: string
          inbox_thread_tag?: string
          assignee?: string
        }
      }
    >({
      query: ({ orgId, query }) => ({
        url: buildUrl(`/${orgId}/threads`, {
          ...query,
          tag_name: query?.inbox_thread_tag,
          archived: query?.archived ? 1 : 0,
          limit: 25,
        }),
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          orgId: queryArgs.orgId,
          params: {
            ...queryArgs.query,
            cursor: '',
          },
        })
      },
      transformResponse: (
        response: ServiceCursorPaginatedResponseType<InboxThreadType>
      ) => {
        const data = [
          ...new Map(
            [...response.data].map((item) => [item.id, item])
          ).values(),
        ]
          .map((item) => ({
            ...item,
            created_at: dateParse(item.created_at),
            archived_at: item.archived_at ? dateParse(item.archived_at) : null,
            read_at: item.read_at ? dateParse(item.read_at) : null,
            updated_at: dateParse(item.updated_at),
            last_thread_event_at: dateParse(item.last_thread_event_at),
            events: item.events || defaultServicePagination,
          }))
          .sort((a, b) => b.created_at.getTime() - a.created_at.getTime())

        return { ...response, data }
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })

        currentCache.data = currentCache.data.sort(
          (a, b) => b.created_at.getTime() - a.created_at.getTime()
        )
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg?.query?.cursor, previousArg?.query?.cursor)
      },

      providesTags: (items) => [
        { type: 'threads', id: 'LIST' },
        ...(items?.data ?? []).map((item) => ({
          type: 'threads' as const,
          id: item.id,
        })),
      ],
    }),

    getInboxThreadEvents: build.query<
      ServiceCursorPaginatedResponseType<InboxThreadEventType> & {
        threadId: string
      },
      { orgId: string; threadId: string; cursor: string }
    >({
      query: ({ orgId, threadId, cursor }) => ({
        url: `/${orgId}/threads/${threadId}/events?cursor=${cursor}`,
        method: 'GET',
      }),
      providesTags: (res, _err, meta) => [
        ...(res?.data ?? []).map((item) => ({
          id: item.id,
          type: 'thread_events' as const,
        })),
        { id: meta.threadId, type: 'thread_events' },
      ],
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          orgId: queryArgs.orgId,
          threadId: queryArgs.threadId,
          params: {
            cursor: '',
          },
        })
      },
      transformResponse: (
        response: ServiceCursorPaginatedResponseType<InboxThreadEventType>,
        _meta,
        arg
      ) => {
        return {
          threadId: arg.threadId,
          ...response,
          data: response.data
            .map((item) => ({
              ...item,
              created_at: dateParse(item.created_at),
              eventable: {
                ...item.eventable,
                created_at: dateParse(item.eventable.created_at),
              },
            }))
            .sort((a, b) => a.created_at.getTime() - b.created_at.getTime()),
        }
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })

        currentCache.data = currentCache.data.sort(
          (a, b) => a.created_at.getTime() - b.created_at.getTime()
        )
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg?.cursor, previousArg?.cursor)
      },
    }),

    getInboxThread: build.query<
      InboxThreadType,
      { orgId: string; threadId: string }
    >({
      query: ({ orgId, threadId }) => {
        return {
          url: `/${orgId}/threads/${threadId}`,
          method: 'GET',
        }
      },
      transformResponse: (response: { data: InboxThreadType }) => {
        return {
          ...response.data,
          created_at: dateParse(response.data.created_at),
          archived_at: response.data.archived_at
            ? dateParse(response.data.archived_at)
            : null,
          read_at: response.data.read_at
            ? dateParse(response.data.read_at)
            : null,
          updated_at: dateParse(response.data.updated_at),
          last_thread_event_at: dateParse(response.data.last_thread_event_at),
        }
      },
      providesTags: (_1, _2, meta) => [
        { type: 'selected_thread', id: meta.threadId },
      ],
    }),

    sendInboxThreadEvents: build.mutation<
      InboxThreadEventType,
      {
        orgId: string
        threadId: string
        type: 'messages' | 'notes'
        description: string
        format: 'html' | 'text'
      }
    >({
      query: ({ orgId, threadId, type, description, format }) => ({
        url: `/${orgId}/threads/${threadId}/${type}`,
        method: 'POST',
        data: { description, format },
      }),

      transformResponse: (response: {
        message: string
        thread_event: InboxThreadEventType
      }) => response.thread_event,
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        const formattedData = {
          ...data,
          created_at: dateParse(data.created_at),
          eventable: {
            ...data.eventable,
            created_at: dateParse(data.eventable.created_at),
          },
        } as InboxThreadEventType

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThreadEvents',
            { orgId, threadId, cursor: '' },
            (draft) => {
              const findIndex = draft.data.findIndex((i) => i.id === data.id)

              if (findIndex >= 0) {
                draft.data[findIndex] = formattedData
              } else {
                draft.data.push(formattedData)
              }
            }
          )
        )

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.last_thread_event_at = new Date()
              draft.last_public_outgoing_event_at = new Date().toISOString()
            }
          )
        )
      },
    }),

    createEmailContactsOnThread: build.mutation<
      {
        thread_contact: InboxThreadContactsType
        message: string
      },
      {
        orgId: string
        threadId: string
        data: {
          method: 'email'
          email: string
          name: string
        }
      }
    >({
      query: ({ orgId, threadId, data }) => ({
        url: `/${orgId}/threads/${threadId}/contacts`,
        method: 'POST',
        data,
      }),
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.contacts.push(data.thread_contact)
            }
          )
        )
      },
    }),

    removeEmailContactsOnThread: build.mutation<
      unknown,
      { orgId: string; threadId: string; contactId: string }
    >({
      query: ({ orgId, threadId, contactId }) => ({
        url: `/${orgId}/threads/${threadId}/contacts/${contactId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { orgId, threadId, contactId },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.contacts.filter((i) => i.id !== contactId)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),

    assignInboxThread: build.mutation<
      { message: string; assignee: InboxThreadAssigneeType },
      {
        orgId: string
        threadId: string
        data: { oauth_user_id: string; label: 'owner' | 'subscriber' }
      }
    >({
      query: ({ orgId, threadId, data }) => ({
        url: `/${orgId}/threads/${threadId}/assignees`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_1, _2, meta) => [
        { type: 'selected_thread', id: meta.threadId },
      ],
    }),

    unassignInboxThread: build.mutation<
      { data: InboxThreadEventType },
      {
        orgId: string
        threadId: string
        threadAssigneeId: string
      }
    >({
      query: ({ orgId, threadId, threadAssigneeId }) => ({
        url: `/${orgId}/threads/${threadId}/assignees/${threadAssigneeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_1, _2, meta) => [
        { type: 'selected_thread', id: meta.threadId },
      ],
    }),

    archiveInboxThread: build.mutation<
      { message: string; thread: InboxThreadType },
      {
        orgId: string
        threadId: string
        archive: boolean
        query: {
          archived?: boolean
          serial?: string
          context_type?: InboxContextTypes
          context_id?: string
          cursor?: string
          inbox_thread_tag?: string
          assignee?: string
        }
      }
    >({
      query: ({ orgId, threadId, archive }) => ({
        url: `/${orgId}/threads/${threadId}/${
          archive ? 'archive-thread' : 'unarchive-thread'
        }`,
        method: 'PUT',
      }),
      async onQueryStarted(
        { orgId, threadId, archive, query },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThreads',
            {
              orgId,
              query: { ...query, archived: false },
            },
            (draft) => {
              const thread = draft.data.find((item) => item.id === threadId)
              if (thread) {
                thread.archived_at = archive ? new Date() : null
              }

              if (archive) {
                draft.data = draft.data.filter((item) => item.id !== threadId)
              }
            }
          )
        )

        try {
          const { data } = await queryFulfilled

          if (!archive) {
            dispatch(
              inboxApi.util.updateQueryData(
                'getInboxThreads',
                {
                  orgId,
                  query: { ...query, archived: false },
                },
                (draft) => {
                  draft.data.unshift(data.thread)
                }
              )
            )
          }

          toast.success(`Thread ${archive ? 'closed' : 'opened'}`)
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (_1, _2, meta) => [
        { type: 'threads', id: 'LIST' },
        { type: 'selected_thread', id: meta.threadId },
        'counts',
      ],
    }),

    markInboxThreadAsRead: build.mutation<
      {
        thread: Omit<InboxThreadType, 'events'>
      },
      {
        orgId: string
        threadId: string
        read: boolean
      }
    >({
      query: ({ orgId, threadId, read }) => ({
        url: `/${orgId}/threads/${threadId}/read-status`,
        method: 'PUT',
        data: { read },
      }),
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.read_at = data.thread.read_at
                ? dateParse(data.thread.read_at)
                : null
            }
          )
        )
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: 'threads', id: arg.threadId },
        'counts',
      ],
    }),

    /**
     *  SETTINGS ETC
     */

    getInboxSettings: build.query<InboxSettingsDataType, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `/${orgId}/settings`,
        method: 'GET',
      }),
      providesTags: ['settings'],
      transformResponse: (response: { settings: InboxSettingsDataType }) => {
        return response.settings
      },
    }),

    updateInboxSettings: build.mutation<
      InboxSettingsDataType,
      { orgId: string; settings: InboxSettingsDataType }
    >({
      query: ({ orgId, settings }) => ({
        url: `/${orgId}/settings`,
        method: 'PUT',
        data: settings,
      }),
      async onQueryStarted({ orgId, settings }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxSettings',
            { orgId },
            (draft) => {
              draft = settings
              return draft
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      transformResponse: (response: {
        message: string
        settings: InboxSettingsDataType
      }) => {
        toast.success(response.message)
        return response.settings
      },
    }),

    getVirtualNumbers: build.query<
      ServiceCursorPaginatedResponseType<VirtualNumberType>,
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/${orgId}/virtual-mobile-numbers`,
        method: 'GET',
      }),
    }),

    /**
     *  SETTINGS END
     */

    /**
     *  TAGS
     */

    createTagOnThread: build.mutation<
      InboxTagType,
      { orgId: string; threadId: string; data: { name: string } }
    >({
      query: ({ orgId, threadId, data }) => ({
        url: `/${orgId}/threads/${threadId}/tags`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: { tag: InboxTagType }) => {
        return response.tag
      },
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.tags.push(data)
            }
          )
        )
      },
    }),

    deleteThreadTag: build.mutation<
      { message: string },
      { orgId: string; tagId: string; threadId: string }
    >({
      query: ({ orgId, tagId }) => ({
        url: `/${orgId}/tags/${tagId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { orgId, tagId, threadId },
        { dispatch, queryFulfilled }
      ) {
        const patchResponse = dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThread',
            { orgId, threadId },
            (draft) => {
              draft.tags = draft.tags.filter((t) => t.id !== tagId)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResponse.undo()
        }
      },
    }),

    getThreadTags: build.query<
      FlatServiceCursorPaginatedResponseType<InboxTagType>,
      { orgId: string; query?: { tags_query?: string; cursor?: string } }
    >({
      query: ({ orgId, query }) => ({
        url: buildUrl(`/${orgId}/tags`, {
          name_starts_with: query?.tags_query,
          cursor: query?.cursor,
        }),
        method: 'GET',
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          orgId: queryArgs.orgId,
          params: {
            ...queryArgs.query,
            cursor: '',
          },
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.next_cursor = newItems.next_cursor
        currentCache.prev_cursor = newItems.prev_cursor
        currentCache.next_page_url = newItems.next_page_url
        currentCache.prev_page_url = newItems.prev_page_url
        currentCache.path = newItems.path
        currentCache.per_page = newItems.per_page

        newItems.data.forEach((item) => {
          currentCache.data.push(item)
        })
      },
      forceRefetch({ currentArg, previousArg }) {
        return !isEqual(currentArg?.query?.cursor, previousArg?.query?.cursor)
      },
    }),
    /**
     *  TAGS END
     */

    /**
     *  UNUSED
     */

    getInboxThreadEvent: build.query<
      InboxThreadEventType,
      { orgId: string; threadId: string; eventId: string }
    >({
      query: ({ orgId, threadId, eventId }) => ({
        url: `/${orgId}/threads/${threadId}/events/${eventId}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: InboxThreadEventType }) => {
        return response.data
      },
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        const payload = {
          ...data,
          created_at: dateParse(data.created_at),
          eventable: {
            ...data.eventable,
            created_at: dateParse(data.eventable.created_at),
          },
        } as InboxThreadEventType

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThreadEvents',
            { orgId, threadId, cursor: '' },
            (draft) => {
              const findIndex = draft.data.findIndex((i) => i.id === data.id)

              if (findIndex >= 0) {
                draft.data[findIndex] = payload
              } else {
                draft.data.push(payload)
              }
            }
          )
        )
      },
    }),

    createThread: build.mutation<
      Omit<InboxThreadType, 'contacts' | 'events'>,
      {
        orgId: string
        data: {
          serial?: string
          subject: string
          context_type?: 'booking' | 'review'
          context_id?: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/${orgId}/threads`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: {
        thread: Omit<InboxThreadType, 'contacts' | 'events'>
      }) => response.thread,
      async onQueryStarted({ orgId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        /**
         * @todo would need query
         */

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThreads',
            { orgId },
            (draft) => {
              draft.data.unshift({
                ...data,
                contacts: [],
                // @todo
                events:
                  defaultServicePagination as unknown as ServiceCursorPaginatedResponseType<InboxThreadEventType>,
              })
            }
          )
        )
      },
    }),

    updateInboxThread: build.mutation<
      InboxThreadType,
      {
        orgId: string
        threadId: string
        thread: Pick<
          InboxThreadType,
          'serial' | 'context_type' | 'context_id' | 'assignees' | 'subject'
        >
      }
    >({
      query: ({ orgId, threadId, thread }) => ({
        url: `/${orgId}/threads/${threadId}`,
        method: 'PUT',
        data: { ...thread },
      }),
      transformResponse: (response: {
        thread: InboxThreadType
        message: string
      }) => response.thread,
      async onQueryStarted({ orgId, threadId }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled

        /**
         * @todo query
         */

        dispatch(
          inboxApi.util.updateQueryData(
            'getInboxThreads',
            { orgId },
            (draft) => {
              const idx = draft.data.findIndex(
                (thread) => thread.id === data.id
              )
              if (idx !== -1) {
                draft.data[idx] = {
                  ...data,
                  created_at: dateParse(data.created_at),
                  archived_at: data.archived_at
                    ? dateParse(data.archived_at)
                    : null,
                  read_at: data.read_at ? dateParse(data.read_at) : null,
                  updated_at: dateParse(data.updated_at),
                  last_thread_event_at: dateParse(data.last_thread_event_at),
                  events: draft.data[idx]?.events || defaultServicePagination,
                  isLoading: false,
                }
              }
            }
          )
        )
      },
    }),

    /**
     *  UNUSED END
     */
  }),
})

export default inboxApi
