import { format, intervalToDuration, isValid, parseISO } from 'date-fns'

export enum TimeFormats {
  Year = 'yyyy',
  Month = 'MMM',
  MonthYear = 'MMM, yy',
  Day = 'dd',
  DayOfMonth = 'do',
  DateYear = 'MMM yyyy',
  DayMonth = 'd MMM',
  StandardDateMonth = 'd MMM, yyyy',
  StandardDateMonthYearSmall = 'd MMM, yy',
  StandardDateMonthYearSmallHHmm = 'd MMM, yy - h:mm',
  StandardDateMonthYear = 'd MMM, yyyy',
  StandardDateAndDayMonthYear = 'EEE d MMM, yyyy',
  StandardWithTime = `do MMM-yy, h:mm aaa`,
  StandardWithTimeWithSeconds = 'd MMM @ h:mm:ss aaa',
  BackendYearMonthDay = 'yyyy-MM-dd',
  DotYearMonthDay = 'yyyy.MM.dd',
  BenassiBookingFormat = 'yyyy-MM-dd',
  DayOfWeek = 'EEEE',
  ShortDayOfWeek = 'E',
  Hour = 'h:mm',
  TwentyFourHour = 'HH:mm',
  FourHour = 'HH',
  FourHourP = 'h a',
  FourHourApple = 'h:mm a',
  MinsHour = 'mm',
  TwentyFourHourSeconds = 'HH:mm:ss',
  SimpleMonthAndYear = 'MMM. yyyy',
  FullCal = "yyyy-MM-dd'T'HH:mm:ss",
  BackendTimestamp = 'yyyy-MM-dd HH:mm:ss',
  BackendTimestampZero = 'yyyy-MM-dd 00:00:00',
  iso = 'yyyy-MM-dd HH:mm:ssxxx',
  /**
   * casing!!
   */
  ordinalDayAndAbbrevMonth = 'do LLL',
  WeekYear = 'ww-yyyy',
  WeekYearAlt = 'ww, yy',
}

export enum ChargeTypes {
  flat_rate = 'flat_rate',
  per_cover = 'per_cover',
}

export const localise = (date: Date = new Date()) => {
  const dateCopy = new Date(date.getTime())
  const offset = (new Date().getTimezoneOffset() / 60) * -1
  dateCopy.setHours(dateCopy.getHours() + offset)
  return dateCopy
}

export const dateParse = (date: Date | string = '') => {
  if (typeof date === 'string') return parseISO(date)
  return date
}

export const backendDateFormatter = (date = '', formatter: TimeFormats) => {
  if (!isValid(new Date(date.split(' ').join('T')))) return ''
  return format(new Date(date.split(' ').join('T')), formatter)
}

export const niceFromNowFormatter = (date: Date) => {
  const values = intervalToDuration({ start: date, end: new Date() })

  if (values.days >= 1) {
    return format(date, TimeFormats.DayMonth)
  }
  if (values.hours >= 1) {
    return `${values.hours}h`
  }
  if (values.minutes >= 1) {
    return `${values.minutes}m`
  }
  return `${values.seconds}s`
}
