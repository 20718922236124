import { cva, VariantProps } from 'class-variance-authority'
import { forwardRef } from 'react'
import { cn } from 'tailwind-ui'

const stackVariants = cva(`flex gap-2`, {
  variants: {
    horizontal: {
      true: 'flex-row items-center',
      false: 'flex-col items-start',
    },
    // spacing
  },
  defaultVariants: {
    horizontal: false,
  },
})

export interface StackProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof stackVariants> {}

export const TStack = forwardRef<HTMLDivElement, StackProps>(
  ({ children, horizontal, className, ...rest }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(stackVariants({ horizontal, className }))}
        {...rest}
      >
        {children}
      </div>
    )
  }
)

TStack.displayName = 'TStack'
