export interface PaginationRepsonseType {
  body: any
  has_more: boolean
  total: number
  next_offset: number
  isLoading?: boolean
}

export interface PaginationRepsonse<T> {
  body: T[]
  has_more: boolean
  total: number
  next_offset: number
  isLoading?: boolean
}

export const initPage: PaginationRepsonseType = {
  body: [],
  has_more: false,
  next_offset: 0,
  total: 0,
  isLoading: false,
}

export interface PagenationRequestType {
  limit: number
  offset?: number
  sort?: 'desc' | 'asc'
  query_type?: 'page' | 'cursor'
  cursor?: string
}
