import {
  Button, IconButton, LightMode,
  Icon,
  Box,
  HStack,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react'
import {
  ArrowDownTrayIcon,
  InformationCircleIcon,
} from '@heroicons/react/16/solid'
import { format, roundToNearestMinutes } from 'date-fns'
import { type FunctionComponent, useMemo } from 'react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { type InboxThreadEventEventableEmailType } from '@/state/entities/inbox/inbox.types'
import { getFirst } from '@/utils/common'
import AttachmentIcon from '../context/attachment/icon'
import { ChatAvatar, ThreadPopover } from './common'

const ThreadEmailBox: FunctionComponent<{
  event: InboxThreadEventEventableEmailType
  threadId: string
}> = ({ event }) => {
  const [showMore, setShowMore] = useBoolean()
  const from = useMemo(() => {
    return event.contacts.find((item) => item.type === 'from')
  }, [event.contacts])

  const cc = useMemo(() => {
    return event.contacts.filter((item) => item.type === 'cc')
  }, [event.contacts])

  return (
    <HStack align="end" justify="start">
      <ChatAvatar
        id="avatar"
        name={
          getFirst(event.contacts.filter((item) => item.type === 'from'))?.name
        }
        email={
          getFirst(event.contacts.filter((item) => item.type === 'from'))?.email
        }
      />
      <Box w="full">
        <Box className="rounded-xl w-[80%] border border-black/10 dark:border-white/10 bg-neutral-50/75 dark:bg-neutral-900/75">
          <HStack
            fontSize="sm"
            px={5}
            py={3}
            spacing={3}
            justify="space-between"
            align="start"
            borderBottomWidth={1}
          >
            <Stack spacing={0}>
              <Text fontWeight="bold">
                {from?.name} {`<${from?.email}>`}
              </Text>
              <Text>{event.subject}</Text>

              {cc.length > 0 && (
                <Text fontWeight="bold" fontSize="sm">
                  Cc:{' '}
                  {cc
                    .map((item) => `${item.name} <${item?.email}>`)
                    .join(',')}
                </Text>
              )}
            </Stack>

            <ThreadPopover event={event}>
              <IconButton
                variant="unstyled"
                size="sm"
                aria-label="detail"
                icon={<Icon as={InformationCircleIcon} />}
              />
            </ThreadPopover>
          </HStack>
          {event.html_body_trimmed ? <LightMode>
            <Box
              px={3}
              pt={3}
              bg="white"
              color="black"
              borderBottomRadius="xl"
            >
              <Box
                boxSize="full"
                dangerouslySetInnerHTML={{
                  __html: showMore
                    ? event.html_body
                    : event.html_body_trimmed,
                }}
                overflow="auto"
              />
              <Button
                borderWidth={1}
                borderBottomWidth={0}
                borderBottomRadius="none"
                size="sm"
                variant="ghost"
                onClick={setShowMore.toggle}
              >
                {!showMore ? 'Show full email' : 'Show less'}
              </Button>
            </Box>
          </LightMode> : null}
          {!event.html_body_trimmed && (
            <Box py={1} px={5}>
              <Text whiteSpace="pre-line">{event.text_body}</Text>
            </Box>
          )}
        </Box>
        {event.attachments.length > 0 && (
          <SimpleGrid columns={4} gap={4} pt={3} w="fit-content">
            {event.attachments.map((item) => (
              <Box
                key={item.url}
                role="group"
                position="relative"
                overflow="hidden"
              >
                <IconButton
                  isRound
                  position="absolute"
                  aria-label="download"
                  as={Link}
                  href={item.url.replace('http://', 'https://')}
                  icon={<Icon as={ArrowDownTrayIcon} />}
                  bottom={-8}
                  size="sm"
                  right={1}
                  zIndex={1}
                  transition="bottom ease-in 250ms"
                  download
                  _groupHover={{
                    bottom: 1,
                  }}
                />
                <AttachmentIcon
                  _groupHover={{
                    filter: 'brightness(50%)',
                  }}
                  attachment={item}
                  transition="filter ease-in 250ms"
                  boxSize="64px"
                />
              </Box>
            ))}
          </SimpleGrid>
        )}

        <Text variant="desc" textAlign="left" id="time">
          {format(
            roundToNearestMinutes(event.created_at, {
              nearestTo: 10,
            }),
            TimeFormats.FourHourApple
          )}
        </Text>
      </Box>
    </HStack>
  )
}

export default ThreadEmailBox
